import PropTypes from "prop-types";
import React from "react";
import { Typography } from "@mui/material";
import DialogUhda from "components/DialogUhda";
import { CommonTexts, LabelsForm, LinksInfo, TableTexts } from "locale/en";
import { AppColors } from "resources/AppColors";

export function IncludeQuestionsModal({
  setShow,
  show,
  handleIncludeQuestions,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.PRIMARY}
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShow}
      handleConfirm={handleIncludeQuestions}
      title={"Include in summary"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.INCLUDE_SUMMARY_1}
          </Typography>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.INCLUDE_SUMMARY_2}
          </Typography>
          <a style={{ fontSize: "12px", color: AppColors.ACCENT }}
            href={LinksInfo.SUMMARIES}
            target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a>
        </>

      }
      show={show}
    />
  );
}

IncludeQuestionsModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};