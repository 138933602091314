import { FormControl } from "@mui/material"
import { Box } from "@mui/material"
import SingleChoiceDropdown from "components/Dropdowns/SingleChoiceDropdown";

const dropdownItems = [
  {
    name: "AND",
    value: 1
  },
  {
    name: "OR",
    value: 0
  }
]

function BooleanOperator({ index, conditionalOperator, handleChangeConditionalOperator, className }) {
  return <>
    {index >= 1 &&
      <Box display="flex" justifyContent="center">
        <FormControl
          margin="normal"
          sx={{ minWidth: "20%" }}
          className={className}
        >
          <SingleChoiceDropdown
            label={"Conditional"}
            value={conditionalOperator}
            handleValue={handleChangeConditionalOperator}
            items={dropdownItems}
          />
        </FormControl>
      </Box>
    }
  </>;
}

export default BooleanOperator