import { Box, Button, Card, Divider, Typography } from "@mui/material";
import { CommonTexts } from "locale/en";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AppColors } from "resources/AppColors";
import { findTranslation } from "utils/HelperFunctions";
import { useDispatch } from "react-redux";
import { toast } from "reducers/notificationsReducer";
import { updateQuestionAction } from "reducers/Form/formActions";
import { FirstPageTranslateQuestionForm } from "./FirstPageTranslateQuestionForm";
import { SecondPageTranslateQuestionForm } from "./SecondPageTranslateQuestionForm";
import { QUESTION_MULTIPLE_CHOICE, QUESTION_SINGLE_CHOICE, questionTypes } from "resources/QuestionConstants";

export function TranslateQuestionForm({ selectedQuestion, handleShow }) {
  const form = useSelector(({ form }) => form.data[0])

  const [currentFormPage, setCurrentFormPage] = useState(1)
  // * The form has a second page if the question is single or multiple choice
  const [hasSecondPage] = useState(
    selectedQuestion.type === questionTypes[QUESTION_SINGLE_CHOICE].name
      || selectedQuestion.type === questionTypes[QUESTION_MULTIPLE_CHOICE].name
      ? true
      : false
  )

  const [questionTitleHTML, setQuestionTitleHTML] = useState(findTranslation(selectedQuestion.questionTranslations, form.defaultLocale.value)?.question || "")
  const [questionTitlePlain, setQuestionTitlePlain] = useState("")

  const [helperTextHTML, setHelperTextHTML] = useState("")
  const [helperTextPlain, setHelperTextPlain] = useState("")

  const [resetEditorState, setResetEditorState] = useState(false)

  const [selectedLocale, setSelectedLocale] = useState(form?.defaultLocale.value)
  const [choiceQuestions, setChoiceQuestions] = useState(selectedQuestion.choiceQuestions)
  const [choiceQuestionsTranslations, setChoiceQuestionsTranslations] = useState(selectedQuestion.choiceQuestions.map(answer => ({
    text: findTranslation(answer?.translations, selectedLocale)?.text || "",
    locale: selectedLocale
  })))

  const dispatch = useDispatch()

  function handleSubmit(event) {
    event.preventDefault()

    if (!questionTitlePlain.trim().length) {
      dispatch(toast("Fill the question title before proceeding.", "error"))
      return
    }

    if (choiceQuestionsTranslations.some(answer => !answer.text)) {
      dispatch(toast("Fill all the answer's translations before proceeding", "error"))
      return
    }

    const newTranslation = {
      locale: selectedLocale,
      question: questionTitleHTML,
      info: helperTextPlain.trim().length > 0 ? helperTextHTML : "",
      subtitle: "",
    }

    const appendNewTranslation = selectedQuestion.questionTranslations.concat(newTranslation)

    const singleOrMultipleChoiceAnswers = !!selectedQuestion.choiceQuestions?.length > 0
      ? choiceQuestions.map(answer => {
        return {
          answerTitle: findTranslation(answer?.translations, form.defaultLocale.value)?.text, // * Use default answer translation
          answerValue: answer.value,
          answerTranslations: answer.translations.map(translation => { // * Send all answer translations
            return {
              locale: translation.locale,
              text: translation.text
            }
          })
        }
      })
      : []

    const questionBody = {
      order: selectedQuestion.order,
      formId: form.id,
      sectionId: null,
      summary: null,
      variableName: selectedQuestion.variableName,
      standarName: "",
      conditional: selectedQuestion.conditional,
      mandatory: selectedQuestion.mandatory,
      type: selectedQuestion.type,
      longText: selectedQuestion.longText,
      minValue: selectedQuestion.minValue,
      maxValue: selectedQuestion.maxValue,
      units: selectedQuestion.units,
      interval: selectedQuestion.interval,
      answerOptions: singleOrMultipleChoiceAnswers,
      questionTranslation: appendNewTranslation
    };

    dispatch(updateQuestionAction(selectedQuestion.id, questionBody))
    handleShow()
  }

  function handleChangeLocale(event) {
    const {
      target: { value },
    } = event;

    setSelectedLocale(value);

    const selectedLocale = selectedQuestion.questionTranslations.find(translation => translation.locale === value)

    if (!selectedLocale) {
      setResetEditorState(true)
      setQuestionTitleHTML("")
      setHelperTextHTML("")

      setChoiceQuestionsTranslations(selectedQuestion.choiceQuestions.map(answer => ({
        text: findTranslation(answer?.translations, value)?.text || "",
        locale: value
      })))

      return
    }

    setResetEditorState(true)
    setQuestionTitleHTML(selectedLocale.question)
    setHelperTextHTML(selectedLocale.info)

    setChoiceQuestionsTranslations(selectedQuestion.choiceQuestions.map(answer => ({
      text: findTranslation(answer?.translations, value)?.text || "",
      locale: value
    })))
  }

  function handleTranslateAnswers(event, index) {
    const { value } = event.target

    const updatedChoiceAnswer = { ...choiceQuestionsTranslations[index], text: value }

    setChoiceQuestionsTranslations(choiceQuestionsTranslations.map((answer, i) => index !== i ? answer : updatedChoiceAnswer))

    // * If there is no answer with the selected locale we are adding a new translation, otherwise we are editing an existing translation
    const updatedAnswers = !!choiceQuestions[index].translations.every(translation => translation.locale !== selectedLocale)
      ? [...choiceQuestions[index].translations, updatedChoiceAnswer]
      : choiceQuestions[index].translations.map(translation => translation.locale !== selectedLocale ? translation : updatedChoiceAnswer)

    const updatedChoiceQuestion = { ...choiceQuestions[index], translations: updatedAnswers };

    setChoiceQuestions(choiceQuestions.map(answer =>
      answer.id !== updatedChoiceQuestion.id ? answer : updatedChoiceQuestion))
  }

  function handlePreviousPage() {
    setCurrentFormPage(1);
  };

  function handleNextPage() {
    if (!questionTitlePlain.trim().length) {
      dispatch(toast("Fill the question title before proceeding.", "error"))
      return
    }

    setCurrentFormPage(2);
  };

  return (
    <Card
      className="mb-1"
      sx={{
        backgroundColor: AppColors.WHITE,
        color: AppColors.PRIMARY,
      }}
    >
      <Box
        component='form'
        id="form-wrapper"
        onSubmit={handleSubmit}
        justifyContent="space-between"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {currentFormPage === 2 && hasSecondPage
          ? (
            <SecondPageTranslateQuestionForm
              selectedQuestion={selectedQuestion}
              selectedLocale={selectedLocale}
              choiceQuestionsTranslations={choiceQuestionsTranslations}
              handleTranslateAnswers={handleTranslateAnswers}
            />
          )
          : (
            <FirstPageTranslateQuestionForm
              selectedQuestion={selectedQuestion}
              selectedLocale={selectedLocale}
              handleChangeLocale={handleChangeLocale}
              questionTitleHTML={questionTitleHTML}
              setQuestionTitleHTML={setQuestionTitleHTML}
              setQuestionTitlePlain={setQuestionTitlePlain}
              helperTextHTML={helperTextHTML}
              setHelperTextHTML={setHelperTextHTML}
              setHelperTextPlain={setHelperTextPlain}
              resetEditorState={resetEditorState}
              setResetEditorState={setResetEditorState}
            />
          )
        }
        <Divider />
        <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, mr: 2 }} id="form-buttons-container" >
          {hasSecondPage &&
            <Button
              onClick={currentFormPage === 1 && hasSecondPage ? handleNextPage : handlePreviousPage}
              variant='outlined'
              sx={{ width: { xs: 100, sm: 125 }, }}
              style={{
                marginRight: "16px",
                backgroundColor: currentFormPage === 1 && hasSecondPage ? AppColors.PRIMARY : AppColors.WHITE,
                color: currentFormPage === 1 && hasSecondPage ? "white" : "black",
                border: "1px solid black"
              }}
            >
              <Typography variant='button_text'>
                {currentFormPage === 1 && hasSecondPage ? "Next" : "Back"}
              </Typography>
            </Button>
          }
          {((currentFormPage === 1 && !hasSecondPage) || (currentFormPage === 2 && hasSecondPage)) &&
            <Button
              variant='contained'
              type='submit'
              sx={{ width: { xs: "100px", sm: "125px" } }}
              style={{ backgroundColor: AppColors.PRIMARY }}
              data-testId="accept"
            >
              <Typography variant='button_text' sx={{ color: "white" }}>
                {CommonTexts.SAVE}
              </Typography>
            </Button>
          }
        </Box>
      </Box>
    </Card>
  )
}