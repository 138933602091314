import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'
import StudiesService from '../services/StudiesService'
import FormsService from '../services/FormsService'
import { toast } from './notificationsReducer'
import { dashboardTexts } from '../locale/en'

const questionTypes = {
  0: "INFO",
  1: "FREE TEXT",
  2: "RANGE",
  3: "DATE TIME",
  4: "SINGLE SELECT",
  5: "NUMERIC",
  6: "MULTIPLE SELECT",
  7: "CALCULATION"
}

const commonHeaders = [
  {
    id: "id",
    label: "ID",
    isSortable: false
  },
  {
    id: "participant",
    label: "Participant",
    isSortable: false
  },
  {
    id: "submitted_at",
    label: "Data",
    isSortable: true
  },
  {
    id: "user",
    label: "Usuari",
    isSortable: false
  }
]

const initialState = {
  data: {
    questions: commonHeaders,
    answers: [],
  },
  totalCount: null,
  loading: false,
  error: null,
}

function mappedAnswers(data, uniqueQuestions) {
  return data.reduce((allData, currentDataPoint) => {
    // * If the user didn't replied to all answers, add them to the data point to prevent columns order bugs and to display - in the cell
    const missingResponses = uniqueQuestions.filter(question => {
      const { name } = question
      const { answers } = currentDataPoint

      const answer = answers.find(answer => {
        if (!answer.question) return true

        return answer.question?.variable_name === name
      })
      return !answer
    })

    if (missingResponses.length > 0) {
      const missingResponsesWithEmptyValues = missingResponses.map(missingResponse => {
        return {
          question: {
            variable_name: missingResponse.name,
            section_id: missingResponse.sectionId,
            order: missingResponse.order,
            type: null
          },
          answer: "-",
          label: "-",
          value: "-"
        }
      })
      currentDataPoint = { ...currentDataPoint, answers: [...currentDataPoint.answers, ...missingResponsesWithEmptyValues] }
    }

    const formattedAnswers = currentDataPoint.answers.reduce((allAnswers, answer) => {
      const { question, data, data_label } = answer
      let answerData

      if (!question) return allAnswers

      if (data === data_label) {
        answerData = data
      }

      if (questionTypes[question.type]?.includes("SELECT")) {
        answerData = `[${data}] ${data_label}`
      }

      return [
        ...allAnswers,
        {
          answer: answerData,
          label: data_label,
          value: data,
          variable_name: question.variable_name,
          sectionId: question.section_id,
          order: question.order
        }
      ]
    }, [])

    const sortedAnswers = [...formattedAnswers]
      .sort((a, b) => {
        if (a.sectionId !== b.sectionId) {
          return a.sectionId - b.sectionId;
        }
        return a.order - b.order;
      })
      .reduce((result, question) => {
        return {
          ...result, [question.variable_name]: {
            answer: question.answer,
            label: question.label,
            value: question.value,
          },
        }
      }, {});

    const extraAnswerFields1 = {
      id: {
        answer: currentDataPoint.id,
      },
      participant: {
        answer: !!currentDataPoint.participant ? currentDataPoint.participant.uuid : "Anònim"
      },
    }

    const extraAnswerFields2 = {
      submitted_at: {
        answer: format(new Date(currentDataPoint.updated_at), "dd-MM-yyyy HH:mm")
      },
      user: {
        answer: !!currentDataPoint.user ? currentDataPoint.user.username : "Anònim"
      },
    }

    const finalAnswers = { ...extraAnswerFields1, ...sortedAnswers, ...extraAnswerFields2 }

    return [...allData, finalAnswers]
  }, [])
}

const studyFormAnswersSlice = createSlice({
  name: 'studyFormAnswers',
  initialState,
  reducers: {
    setAnswers(state, action) {
      const { data, totalCount } = action.payload

      if (!data) { // ! Temporary fix until we are able to reset state in store.js after creating studies reducer
        return initialState
      }

      const uniqueQuestions = data.flatMap(item => item.answers).reduce((questions, currentAnswer) => {
        if (currentAnswer.question && !questions.some(question => question.name === currentAnswer.question.variable_name)) {
          questions.push({
            name: currentAnswer.question.variable_name,
            sectionId: currentAnswer.question.section_id,
            order: currentAnswer.question.order
          })
        }
        return questions
      }, [])

      const sortedQuestions = [...uniqueQuestions].sort((a, b) => a.sectionId - b.sectionId || a.order - b.order)
        .map(question => {
          return {
            id: question.name,
            label: question.name,
            isSortable: false,
            sectionId: question.sectionId,
            order: question.order
          }
        })

      const insertIndex = 2; // Insert the new headers after the 'participant' header

      const tableHeaders = [
        ...commonHeaders.slice(0, insertIndex),
        ...sortedQuestions,
        ...commonHeaders.slice(insertIndex)
      ];

      return { ...state, data: { questions: tableHeaders, answers: mappedAnswers(data, uniqueQuestions) }, totalCount, loading: false, error: null }
    },
    deleteAnswer(state, action) {
      const newDataState = state.data.answers.filter((answer) => !action.payload.some(answerToDeleteId => answerToDeleteId === answer.id))

      const questions = newDataState.length > 0 ? state.data.questions : commonHeaders

      return { ...state, data: { answers: newDataState, questions }, loading: false, error: null }
    },
    appendAnswers(state, action) {
      const { data } = action.payload

      const filteredAnswers = data.filter(incomingAnswer =>
        state.data.answers.every(answer => incomingAnswer.id !== answer.id.answer)
      )

      console.log("filteredAnswers", filteredAnswers);

      const uniqueQuestions = filteredAnswers.flatMap(item => item.answers).reduce((questions, currentAnswer) => {
        if (currentAnswer.question &&
          (!state.data.questions.some(question => question.id === currentAnswer.question.variable_name) &&
            !questions.some(question => question.name === currentAnswer.question.variable_name)
          )) {
          questions.push({
            id: currentAnswer.question.variable_name,
            sectionId: currentAnswer.question.section_id,
            order: currentAnswer.question.order
          })
        }
        return questions
      }, [])

      const filterCommonHeaders = state.data.questions.filter(question => !commonHeaders.some(defaultQuestion =>
        defaultQuestion.id === question.id))

      let sortedQuestions;

      if (uniqueQuestions.length === 0) {
        sortedQuestions = filterCommonHeaders
      }
      else {
        sortedQuestions = [...filterCommonHeaders, ...uniqueQuestions].sort((a, b) => a.sectionId - b.sectionId || a.order - b.order)
          .map(question => {
            return {
              id: question.id,
              label: question.id,
              isSortable: false,
              sectionId: question.sectionId,
              order: question.order
            }
          })
      }

      const insertIndex = 2; // Insert the new headers after the 'participant' header

      const tableHeaders = [
        ...commonHeaders.slice(0, insertIndex),
        ...sortedQuestions,
        ...commonHeaders.slice(insertIndex)
      ];

      return { ...state, data: { questions: tableHeaders, answers: [...state.data.answers, ...mappedAnswers(data, sortedQuestions)] }, loading: false, error: null }
    },
    setAnswersLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setAnswersError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const { setAnswers, deleteAnswer, appendAnswers, setAnswersLoading, setAnswersError } =
  studyFormAnswersSlice.actions

export const initializeFormAnswers = (formId, page, size) => {
  return async (dispatch) => {
    try {
      dispatch(setAnswersLoading())

      const { data } = await FormsService.getFormAnswers(formId, page, size)

      dispatch(setAnswers({ data: data.data.data, totalCount: parseInt(data.data.total) }))
    }
    catch (error) {
      console.error(error)
      dispatch(setAnswersError({ message: error.message, status: error.response?.status }));
      dispatch(toast(dashboardTexts.ANSWERS_ERROR, 'error'))
    }
  }
}

export const deleteAnswers = (answersId) => {
  return async (dispatch) => {
    try {
      dispatch(setAnswersLoading())

      await StudiesService.deleteAnswer({ ids: answersId })

      dispatch(deleteAnswer(answersId))
    }
    catch (error) {
      console.error(error)
      dispatch(setAnswersError({ message: error.message, status: error.response?.status }));
      dispatch(toast(dashboardTexts.DELETE_ERROR, 'error'))
    }
  }
}

export const fetchMoreAnswersAction = (formId, page, size) => {
  return async (dispatch) => {
    try {
      dispatch(setAnswersLoading());

      const { data } = await FormsService.getFormAnswers(formId, page, size)

      dispatch(appendAnswers({ data: data.data.data }))
    }
    catch (error) {
      console.error(error)
      dispatch(setAnswersError({ message: error.message, status: error.response?.status }));
      dispatch(toast(dashboardTexts.LOADING_ERROR, 'error'))
    }
  }
}

export default studyFormAnswersSlice.reducer