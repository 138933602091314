/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { CardActions, CardHeader, Divider, Grid, InputAdornment, TextField, Container } from "@mui/material"
import { AppColors } from "../resources/AppColors"
import TextUhda from "../components/TextUdha";
import { textType, useTextStyles } from "../resources/AppTexts";
import CardUhda from "../components/CardUhda";
import makeStyles from '@mui/styles/makeStyles';
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import { AccountTexts, CommonTexts, SnackBarResponses, TableTexts } from "../locale/en";
import { StorageManager } from "../utils";
import { BASE_PATH } from "../resources/ApiUrls";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import ErrorIcon from "@mui/icons-material/Error";
import "../styles/account.scss";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "1000px",
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },

  textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,

      },
    }, "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,

    },

    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      borderRadius: 10,
      backgroundColor: AppColors.WHITE,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    borderRadius: 10,
  },

  cardHeaderRoot: {
    overflow: "hidden"
  },
  cardHeaderContent: {
    overflow: "hidden"
  }
}))
/**
 * @page
 * Page for the account of the user
 */
const AccountPage = () => {
  const texts = useTextStyles();
  const classes = useStyles();

  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const storageManager = new StorageManager();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();

  const [nameStorage, setNameStorage] = useState()
  const [email, setEmail] = useState()
  const [username, setUsername] = useState();
  const [surnameStorage, setSurnameStorage] = useState()
  const [password, setPassword] = useState(storageManager.getPwd());
  const [showNewPwdError, setShowNewPwdError] = React.useState(false);
  const [showRepeatPwdError, setShowRepeatPwdError] = React.useState(false);
  const { trackPageView } = useMatomo()

  const dispatch = useDispatch()

  useEffect(() => {
    trackPageView()
  }, [])

  /**
   * It gets the user's data from the database and sets the state with the data
   */
  const getUser = async () => {
    try {
      var data = []
      const response = await MyAxiosInstance.get(`${BASE_PATH}/user/${storageManager.getId()}`);
      setUsername(response.data.data.item.username)
      setNameStorage(response.data.data.item.name)
      setEmail(response.data.data.item.email)
      setSurnameStorage(response.data.data.item.surname)

      // eslint-disable-next-line no-empty
    } catch (err) {

    }
  }

  useEffect(() => {
    getUser()
  }, [])

  /**
   * If the new password is less than 8 characters, show an error. If the repeat password is less than
   * 8 characters, show an error. If the old password is correct, and the new password and repeat
   * password match, then change the password
   */
  const changePassword = async () => {
    if (newPassword.length < 8) {
      setShowNewPwdError(true)
    }
    if (repeatPassword.length < 8) {
      setShowRepeatPwdError(true)
    } else {
      setShowNewPwdError(false)
      setShowRepeatPwdError(false)
      if (password === oldPassword) {
        if (newPassword === repeatPassword) {
          var body = {
            "current_password": oldPassword,
            "password": newPassword,
            "password_confirmation": repeatPassword
          }
          try {
            await MyAxiosInstance.put(`${BASE_PATH}/auth/password`, body);
            dispatch(toast(SnackBarResponses.PASSWORD_SAVED, "success"))
            // eslint-disable-next-line no-empty
          } catch (error) {

          }
        } else {
          dispatch(toast(SnackBarResponses.NEW_PASSWORD_MATCH, "error"))
        }
      } else {
        dispatch(toast(SnackBarResponses.OLD_PASSWORD_ERROR, "error"))
      }
    }
  }

  /**
   * It changes the name of the user
   */
  const changeName = async () => {
    if (name === undefined) name = storageManager.getName()
    if (surname === undefined) surname = storageManager.getSurname()
    else {
      var body = { "name": name, "surname": surname }
      try {
        await MyAxiosInstance.put(`${BASE_PATH}/auth/user`, body);

        dispatch(toast(SnackBarResponses.CHANGES_SAVED, "success"))
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
    }
  }

  return (
    <Grid container className="accountPage"
      direction={"column"}>
      <Container fluid>
        <div className={classes.root}>
          <Grid container justifyContent={"center"} alignItems={"flex-start"}
            direction={"column"}
            xs={8}
            sm={5}
            md={8}
            lg={8}
            xl={4.5}
          >
            <Grid item style={{ width: 564 }}>
              <TextUhda color={AppColors.PRIMARY} type={textType.TITLE_BOLD}
                text={AccountTexts.MY_ACCOUNT} />
            </Grid>
            <Grid container direction={"row"} style={{ flexWrap: "nowrap" }} spacing={5}>
              <Grid item style={{ marginBottom: "1em" }}>
                <CardUhda width={"25em"} bg={AppColors.WHITE} children={
                  <Grid container
                    style={{ position: "relative", margin: "1em", display: "grid" }}
                  >
                    <Grid item xs zeroMinWidth>
                      <CardHeader
                        classes={{
                          title: texts.title_bold,
                          root: classes.cardHeaderRoot,
                          content: classes.cardHeaderContent
                        }}
                        style={{
                          width: "232px",
                          color: AppColors.PRIMARY,
                          paddingBottom: "8px",
                          paddingLeft: 0,
                          paddingTop: 0
                        }}
                        title={
                          <TextUhda margin={"0px"} style={"break-word"}
                            type={textType.BODY_BOLD}
                            text={AccountTexts.EMAIL} />}
                      />
                    </Grid>
                    <Divider style={{ marginLeft: "-16px", marginBottom: "20px" }} />
                    <Grid container spacing={3} direction={"row"}>
                      <Grid item style={{ paddingRight: 0 }}>
                        {email && <TextField
                          style={{ margin: 0, width: 300 }}
                          type="text"
                          label={AccountTexts.EMAIL}
                          defaultValue={email}
                          disabled
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldLabelDisabled}
                        />}
                      </Grid>
                    </Grid>
                  </Grid>} />
              </Grid>

              <Grid item style={{ marginBottom: "1em" }}>
                <CardUhda width={"25em"} bg={AppColors.WHITE} children={
                  <Grid container
                    style={{ position: "relative", margin: "1em", display: "grid" }}
                  >
                    <Grid item xs zeroMinWidth>
                      <CardHeader
                        classes={{
                          title: texts.title_bold,
                          root: classes.cardHeaderRoot,
                          content: classes.cardHeaderContent
                        }}
                        style={{
                          width: "232px",
                          color: AppColors.PRIMARY,
                          paddingBottom: "8px",
                          paddingLeft: 0,
                          paddingTop: 0
                        }}
                        title={
                          <TextUhda margin={"0px"} style={"break-word"}
                            type={textType.BODY_BOLD}
                            text={AccountTexts.USERNAME} />}
                      />
                    </Grid>
                    <Divider style={{ marginLeft: "-16px", marginBottom: "20px" }} />
                    <Grid container spacing={3} direction={"row"}>
                      <Grid item style={{ paddingRight: 0 }}>
                        {username && <TextField
                          style={{ margin: 0, width: 300 }}
                          type="text"
                          label={AccountTexts.USERNAME}
                          defaultValue={username}
                          disabled
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldLabelDisabled}
                        />}
                      </Grid>
                    </Grid>
                  </Grid>} />
              </Grid>

            </Grid>
            <Grid item style={{ marginBottom: "1em" }}>
              <CardUhda width={"40em"} bg={AppColors.WHITE} children={
                <Grid container
                  style={{ position: "relative", margin: "1em", display: "grid" }}
                >
                  <Grid item xs zeroMinWidth>
                    <CardHeader
                      classes={{
                        title: texts.title_bold,
                        root: classes.cardHeaderRoot,
                        content: classes.cardHeaderContent
                      }}
                      style={{
                        width: "232px",
                        color: AppColors.PRIMARY,
                        paddingLeft: 0,
                        paddingBottom: "8px",
                        paddingTop: 0
                      }}
                      title={
                        <TextUhda margin={"0px"} style={"break-word"} type={textType.BODY_BOLD}
                          text={AccountTexts.PERSONAL_DETAILS} />}
                    />
                  </Grid>
                  <Divider style={{ marginLeft: "-16px", marginBottom: "20px" }} />
                  <Grid container spacing={3} direction={"row"}>
                    <Grid item style={{ paddingRight: 0 }}>
                      {nameStorage && <TextField
                        style={{ margin: 0, width: 300 }}
                        type="text"
                        label={AccountTexts.NAME}
                        defaultValue={nameStorage}
                        onChange={(e) => setName(e.target.value)}

                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldLabel}
                      />}
                    </Grid>
                    <Grid item style={{ paddingRight: 0 }}>
                      {surnameStorage && <TextField
                        style={{ margin: 0, width: 300 }}
                        type="text"
                        label="Surname"
                        defaultValue={surnameStorage}
                        onChange={(e) => setSurname(e.target.value)}

                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldLabel}
                      />}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <CardActions>
                      <Grid style={{ paddingRight: "20px" }} container spacing-xs-1 wrap="nowrap"
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="flex-end">
                        <ButtonFilledUhda color={AppColors.WHITE} onClick={changeName}
                          type={textType.BODY_BOLD} margin={"0.7em"}
                          text={AccountTexts.SAVE_CHANGES}
                        />
                      </Grid>
                    </CardActions>
                  </Grid>
                </Grid>} />
            </Grid>
            <Grid item>
              <CardUhda width={"61em"} bg={AppColors.WHITE} children={
                <Grid container
                  style={{ position: "relative", margin: "1em", display: "grid" }}
                >
                  <Grid item xs zeroMinWidth>
                    <CardHeader
                      classes={{
                        title: texts.title_bold,
                        root: classes.cardHeaderRoot,
                        content: classes.cardHeaderContent
                      }}
                      style={{
                        width: "232px",
                        color: AppColors.PRIMARY,
                        paddingLeft: 0,
                        paddingBottom: "8px",
                        paddingTop: 0
                      }}
                      title={
                        <TextUhda margin={"0px"} style={"break-word"} type={textType.BODY_BOLD}
                          text={AccountTexts.PASSWORD} />}
                    />
                  </Grid>
                  <Divider style={{ marginLeft: "-16px", marginBottom: "20px" }} />
                  <Grid container spacing={3} direction={"row"}>
                    <Grid item>
                      <TextField

                        style={{ margin: 0, width: 300 }}
                        type="password"
                        label={AccountTexts.OLD_PASSWORD}
                        onChange={(e) => setOldPassword(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldLabel}
                      />
                    </Grid>
                    <Grid item>
                      <TextField

                        style={{ margin: 0, width: 300 }}
                        type="password"
                        label={AccountTexts.NEW_PASSWORD}
                        onChange={(e) => setNewPassword(e.target.value)}

                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldLabel}
                        helperText={showNewPwdError && TableTexts.PASSWORD_ERROR}
                        inputProps={{
                          endAdornment: showNewPwdError &&
                            <InputAdornment position="end"><ErrorIcon
                              style={{ color: AppColors.RED }} /></InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        InputProps={{ inputProps: { min: 8 } }}

                        style={{ margin: 0, width: 300 }}
                        type="password"
                        label={AccountTexts.REPEAT_NEW_PASSWORD}
                        onChange={(e) => setRepeatPassword(e.target.value)}

                        margin="normal"
                        variant="outlined"
                        className={classes.textFieldLabel}
                        helperText={showRepeatPwdError && TableTexts.PASSWORD_ERROR}
                        inputProps={{
                          endAdornment: showRepeatPwdError &&
                            <InputAdornment position="end"><ErrorIcon
                              style={{ color: AppColors.RED }} /></InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <CardActions>
                      <Grid style={{ paddingRight: "20px" }} container spacing-xs-1 wrap="nowrap"
                        direction="row"
                        alignItems="flex-end"
                        justifyContent="flex-end">
                        <ButtonFilledUhda color={AppColors.WHITE} onClick={changePassword}
                          margin={"0.7em"}
                          text={CommonTexts.SAVE_CHANGES}
                        />
                      </Grid>
                    </CardActions>
                  </Grid>
                </Grid>} />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Grid>

  )
}

export default AccountPage;
