export const CommonTexts = {
  LOGIN: "Log in ",
  LOGOUT: "LOGOUT",
  WEB_NAME: "UhDa Studies",
  NEW_STUDY: "NEW SURVEY FOLDER",
  NEW_FORM: "New survey",
  NEW_QUESTION: "New question",
  NEW_SECTION: "New section",
  EDIT_SECTION: "Edit section",
  ADD_SUMMARY: "Add summary",
  GO_SUMMARY: "Go to summary",
  SELECT: "Select",
  SELECT_QUESTIONS: "Select questions",
  FORGOT_PASS: "Forgot your password?",
  RESET_PASS: "Reset Password",
  CHANGE_PASS: "Change Password",
  PLEASE_CHANGE_PASS: "Please change your password",
  WELCOME_BACK_UPPER: "WELCOME BACK",
  WELCOME_BACK: "Welcome Back!",
  WELCOME: "Welcome",
  INFO_LOGIN: "By logging in you agree to the terms of use and privacy policy",
  EMAIL: "E-mail",
  PASSWORD: "Password",
  ABOUT: "About",
  USER_GUIDE: "User Guide",
  TERMS: "Terms of Use",
  PRIVACY: "Privacy Policy",
  PRIVACY_SHARE: "How do you want to share your study?",
  SAVE: "Save",
  SAVE_CHANGES: "Save Changes",
  CREATE: "Create",
  CANCEL: "Cancel",
  NEW: "New",
  REMEMBER_ME: "Remember me",
  INFO_PRIVACY:
    "If you make your study public, anyone with the URLs will be able to answer the forms. If you make it private, only people with a user and password will have acces to the forms and be able to answer them",
  DISSEMINATION:
    "If you want to disseminate your study, we can help you by creating a post on the UhDa Studies blog. You can click on the button below and you'll be redirected to a form to send us the information of your study.",
  DISSEMINATION_2:
    "Once the post is ready, we'll publish it and let you know so you can share it however you like.",
  SEND_INFO: "Send info",
  HAPPY_DISSEMINATION: "Happy dissemination!",
  PUBLIC: "Public",
  PRIVATE: "Private",
  NEXT: "Next",
  EXPORT: "EXPORT",
  DOWNLOAD_FORM: "Download survey data",
  DOWNLOAD: "Download",
  LABELS: "Labels",
  VALUES: "Values",
  MOVE: "Move",
  DELETE: "Delete",
  INCLUDE_SUMMARY: "Include in summary",
  MOVE_QUESTIONS: "Select where you wish to move these questions.",
  LEARN_MORE: "Learn more about question types ",
  CREATE_FORM_INFO: "No survey have been created yet for this survey folder. \nTo create a survey, click the New Survey button in the top right",
  FIELD_MANDATORY: "This field is mandatory",
};

export const SelectorIsPublic = {
  PRIVACY: "Privacy",
  PUBLIC: "Public",
  PRIVATE: "Private",
};

export const ErrorTexts = {
  LOGIN: "Incorrect username or password",
  SETTINGS: "Title or description empty",
  FORM: "Some of the fields are empty",
};

export const AccountTexts = {
  USERNAME: "Username",
  MY_ACCOUNT: "My account",
  EMAIL: "Email",
  PERSONAL_DETAILS: "Personal details",
  NAME: "Name",
  SURNAME: "Surname",
  SAVE_CHANGES: "Save Changes",
  PASSWORD: "Password",
  OLD_PASSWORD: "Old password",
  NEW_PASSWORD: "New password",
  REPEAT_NEW_PASSWORD: "Repeat new password",
};

export const LabelsStudy = {
  DASHBOARD: "Dashboard",
  FORMS: "Surveys",
  DISSEMINATION: "Dissemination",
  TEAM_MEMBERS: "Team Members",
  PARTICIPANTS: "Participants",
  SETTINGS: "Settings",
  SUMMARY: "Summary",
  TITLE_MODAL_DUPLICATE: "Duplicate a survey folder",
  TEXT_MODAL_DUPLICATE1: "You’re about to duplicate this survey folder:",
  TEXT_MODAL_DUPLICATE2:
    "The title, description, surveys and questions included in them will be copied. The team members, however, will be blank and you’ll have to add them again. Do you wish to continue?",
  CONFIRM_MODAL_DUPLICATE: "Continue",
  CANCEL_MODAL: "Cancel",

  TITLE_MODAL_DELETE: "You're about to delete this survey:",
  TITLE_MODALS_DELETE: "You're about to delete these surveys:",
  TEXT_MODAL_DELETE1: "You're about to delete this survey folder:",
  TEXT_MODALS_DELETE1: "You're about to delete these survey folder:",
  TEXT_MODAL_DELETE2:
    "Deleting it will also erase all data associated with it. Are you sure you want to delete it? ",
  TEXT_MODALS_DELETE2:
    "Deleting them will also erase all data associated with them. Are you sure you want to delete them? ",
  CONFIRM_MODAL_DELETE: "I'm sure",
  TEXT_TITLE_DELETE: "Delete survey folder(s)"
};

export const LabelsForms = {
  TITLE_MODAL_DUPLICATE: "Duplicate survey",
  TEXT_MODAL_DUPLICATE1: "You’re about to duplicate this survey:",
  TEXT_MODAL_DUPLICATE2:
    "A copy will be created inside the current survey folder, including the title, description and questions. Do you wish to continue?",

  CONFIRM_MODAL_DUPLICATE: "Continue",
  CANCEL_MODAL: "Cancel",

  TITLE_MODAL_EXPORT: "Export form",
  TEXT_MODAL_EXPORT1: "You’re about to export this form:",
  CONFIRM_MODAL_EXPORT: "Export",
  IMPORT_FORM: "Import form",
  TITLE_MODAL_DELETE: "Delete a survey",
  TEXT_MODAL_DELETE1: "You're about to delete this survey:",
  TEXT_MODALS_DELETE1: "You're about to delete these surveys:",
  TEXT_MODAL_DELETE2:
    "Deleting it will also erase all data associated with it. Are you sure you want to delete it? ",
  TEXT_MODALS_DELETE2:
    "Deleting them will also erase all data associated with them. Are you sure you want to delete them? ",

  CONFIRM_MODAL_DELETE: "I'm sure",
  CONDITIONAL_POPOVER:
    "You can make this question conditional to more than one other question by using the “AND” and “OR” logical operators. If you use “AND”, all the conditions indicated have to take place for the question to appear. If you user “OR”, fulfilling one of the conditions is enough for the conditional question to appear. These operators cannot be mixed, you have to use either all “AND” or all “OR” operators.",
  CALCULATION_INFO:
    "Write in the field above the formula that you wish to calculate. Reference the questions by the variable name and use the following accepted symbols: +, -, * and /. The formula will take into consideration the value of the answer provided for each question.",
};

export const LabelsForm = {
  FORM_DETAIL: "Survey detail",
  TITLE: "Title",
  DESCRIPTION: "Description",
  ORIGINAL_LOCALE: "Original locale",
  DESCRIPTION_MOCK:
    "SF-36 is a set of generic,coherent, and easily administered quality-of-life measures. These measures rely upon patient self-reporting and are now diely utilized by managed care organizations and by",
  VISIBILITY: "Visibility",
  LINK: "Link",
  LINK_MOCK: "forms.uhda/g6tw76",
  ARCHIVE: "Archive form",
  UNARCHIVE: "Unarchive form",
  PUBLISH: "Publish form",
  DELETE_SUMMARY: "Delete Summary",
  DELETE_INFO_SUMMARY:
    "Are you sure you want to delete this question from the summary? If the question also exists in a section, it will note be deleted from it, only from the summary. To completely delete the question, please delete it from the section as well.",
  DELETE_INFO_SUMMARY_BULK:
    "Are you sure you want to delete the selected question(s) from the summary? If the question also exists in a section, it will not be deleted from it, only from the summary. To completely delete the question, please delete it from the section as well.",
  LEARN_MORE_SUMMARIES: "Learn more about summaries",
  INCLUDE_SUMMARY_1:
    "Are you sure you would like to include the selected question(s) in the Summary of this survey? ",
  INCLUDE_SUMMARY_2:
    "The questions will not be copied, but mirrored. Any changes made to questions in the section will affect the questions mirrored in the summary and vice versa.",
  DELETE_INFO_SECTIONS:
    "Are you sure you want to delete this question?",
  DELETE_INFO_QUESTIONS_SECTIONS:
    "Are you sure you want to delete the selected question(s) from this survey?",
  DUPLICATE__INFO_QUESTION:
    "Are you sure you want to duplicate this question? If so, a copy will be created inside the current survey. Remember to update the variable name (once copied, it will have “_copy” added to the variable name). Do you wish to continue?",
  INFO_SUMMARY:
    "The summary is an optional section of the survey where you can add existing questions or new ones to create a one-pager of the most important results and answers of your survey. This one-pager appears in the survey as a tab, just like sections do, but it’s a special one because it can be downloaded as a PDF and printed. It’s useful for studies where you want to give participants a sheet of information to take home, or where you want to keep a copy of the information in a clear and easy-to-understand way.",
  ERROR_QUESTION_TITLE: "Make sure to fill the question title",
  ERROR_QUESTION_TITLE_TRANSLATED: "Make sure to fill the question title translated"
};

export const LabelsSettings = {
  STUDY_DETAILS: "Survey folder details",
  STUDY_TITLE: "Survey folder title",
  STUDY_DESCRIPTION: "Survey folder description",
  SAVE_CHANGES: "Save changes",
};

export const LabelsDrawer = {
  STUDIES: "Survey Folders",
  TAGS: "Tags",
  TRANSLATIONS: "Locales",
  USERS: "Users",
  PARTICIPANTS: "Participants",
  COLLABORATORS: "Collaborators",
  USAGE: "Usage",
};

export const LabelsLogOut = {
  LOG_OUT: "Log out",
  TEXT: "Are you sure you want to log out?",
  CANCEL: "Cancel",
  SURE: "I'm sure",
};

export const TableTexts = {
  SAVE_AND_ADD: "Save and add new",
  TAG_NAME: "Tag name",
  TAG_DESCRIPTION: "Tag description",
  LOCALE_CODE: "Locale code",
  LOCALE_NAME: "Locale name",
  NAME: "NAME",
  SURNAME: "SURNAME",
  PASSWORD: "PASSWORD",
  REPEAT_PASSWORD: "REPEAT PASSWORD",
  USERNAME: "USERNAME",
  BIRTHDATE: "BIRTH DATE",
  EMAIL: "EMAIL",
  STUDIES: "STUDIES",
  ROLES: "ROLE(S)",
  EMAIL_ERROR:
    "The provided email is using an incorrect format, please review this field and try to save again",
  PASSWORD_ERROR: "The password must have a minimum of 8 characters",
  ADD_TAG_TITLE: "New tag",
  ADD_LOCALE_TITLE: "New locale",
  ADD_USER_TITLE: "Add user",
  ADD_PARTICIPANT_TITLE: "Add a participant",
  ADD_STUDY_TITLE: "Add a study",
  ADD_TEAM_MEMBER_TITLE: "Add a team member",

  DELETE_TAG: "Delete tag",
  DELETE_LOCALE: "Delete locale ",
  DELETE_USER: "Delete user",
  DELETE_PARTICIPANT: "Delete a participant: ",
  DELETE_USERS: "Delete users: ",
  DELETE_TAGS: "Delete tags",
  DELETE_LOCALES: "Delete locales ",
  DELETE_PARTICIPANTS: "Delete participants: ",

  EDIT_TITLE_USER: "Edit user",
  EDIT_TAG: "Edit tag",
  EDIT_LOCALE: "Edit locale",
  DELETE_TAG_TEXT: "You're about to delete this tag:",
  DELETE_TAGS_TEXTS: "You're about to delete these tags:",
  DELETE_TAGS_TEXT: "You're about to delete this tag:",
  DELETE_LOCALE_TEXT: "You're about to delete this locale:",
  DELETE_LOCALE_TEXTS: "You're about to delete this locale:",
  DELETE_USER_TEXT: "You're about to delete this user:",
  DELETE_USER_TEXTS: "You're about to delete this user:",
  DELETE_TEXT: "You're about to delete this participant:",
  DELETE_TAG_TEXT_MULTIPLE: "You're about to delete these tags:",
  DELETE_LOCALE_TEXT_MULTIPLE: "You're about to delete these locales:",
  DELETE_USERS_TEXT_MULTIPLE: "You're about to delete these users:",
  DELETE_TEXT_MULTIPLE: "You're about to delete these participants:",
  DELETE_TAGS_ID: "Delete tag(s) with name(s):",
  DELETE_LOCALE_ID: "Delete locale(s) with name(s): ",
  DELETE_USER_ID: "Delete user(s) with name(s): ",
  DELETE_TEAM_MEMBER_ID: "Delete team member with name: ",
  DELETE_USER_IDS: "Delete users with ids: ",
  DELETE_TEAM_MEMBERS_ID: "Delete team member",
  DELETE_TEAM_MEMBER_IDS: "Delete team members",
  EDIT_TEAM_MEMBER_TITLE: "Edit team member with id: ",
  DELETE_TEAM_MEMBER_TEXT: "Are you sure you want to delete this team member?",
  DELETE_TEAM_MEMBER_TEXT_MULTIPLE:
    "Are you sure you want to delete these team members?",
  DELETE_CONFIRM: "I'm Sure",
  ACTIONS: "ACTIONS",
  SELECTED: "selected",
  TAGS_TITLE: "Tags",
  TRANSLATIONS_TITLE: "Translations",
  PARTICIPANTS_TITLE: "Participants",
  USERS_TITLE: "Users",
  COLLABORATORS_TITLE: "Collaborators",
  ID: "ID",
  PARTICIPANT: "PARTICIPANT",
  ORDER: "Order",
  CODE: "Variable name",
  QUESTION_TYPE: "Question type",
  QUESTION_TITLE: "Question title",
  MANDATORY: "Mandatory",
  CONDITIONAL: "Conditional",
  ORIGINAL_LOCALE: "Original locale",
  TRANSLATIONS: "Translations",
  SUMMARY: "In summary",
  USER_ROLE: "User Role",
  TEXT_MODAL_DELETE_TAG:
    "This action cannot be undone. Are you sure?",

  TEXT_MODAL_DELETE_LOCALE:
    "All data associated with this locale will be deleted. This action cannot be reversed. Are you sure?",
  TEXT_MODAL_DELETE_LOCALES:
    "All data associated with these locales will be deleted. This action cannot be reversed. Are you sure?",
  TEXT_MODAL_DELETE_USER:
    "All data associated with this user will be deleted. This action cannot be reversed. Are you sure?",
  TEXT_MODAL_DELETE_USERS:
    "All data associated with these users will be deleted. This action cannot be reversed. Are you sure?",

  TEXT_MODAL_DELETE:
    "All data associated with these participants, including their reports in studies in which they have participated will be deleted. This action cannot be reversed. Are you sure?",
  QUESTION: "QUESTION",
  SUBMITTED: "SUBMITTED",
  USER: "USER",
  ANSWERS: "Answers",
  FORM_RESPONSES: "Survey responses",
  SELECT_FORM: "Select a survey from the dropdown above to view data",
  DELETE_ANSWERS_TITLE: "Delete survey data",
  DELETE_ANSWERS: "You are about to delete data entries with the following ID:",
  DELETE_ANSWERS_MULTIPLE: "You're about to delete an entries with this IDs",
  DELETE_ANSWERS_ID:
    "All the answers associated with this ID will be deleted. This action cannot be reversed. Are you sure?",
  DELETE_ANSWERS_IDS:
    "All the answers associated with these IDs will be deleted. This action cannot be reversed. Are you sure?",
  DOWNLOAD_ANSWER: "Download answer with id: ",
  DELETE_TEXT_MODAL: "Texto modal para el delete",
  NO_ELEMENTS: "No elements to display yet",
  STUDY_TITLE: "Title",
  STUDY_DESCRIPTION: "Description",
  STUDY_CREATED_BY: "Created by",
  TAGS: "Tags",
  STUDY_CREATED: "Created on",
  FORM_TITLE: "Survey title",
  FORM_DESCRIPTION: "Survey description",

};

export const StudyMembersTeam = {};

export const textPageDissemination = {
  TEAM_MEMBERS_TITLE: "Team Members",
  DISSEMINATION: "https://forms.epidemixs.org/form/study/epidemixsstudies-es",
};

export const menuOptions = {
  EDIT: "Edit",
  TRANSLATE: "Translate",
  ACTIVATE: "Activate",
  INACTIVATE: "Inactivate",
  DUPLICATE: "Duplicate",
  DOWNLOAD: "Download",
  DELETE: "Delete",
  EXPORT: "Export",
  SUMMARY_PDF: "Summary PDF",
};

export const SnackBarResponses = {
  COMMON_ERROR: "Something went wrong, try it again later",
  EMPTY_FIELDS: "Make sure to fill all the fields",
  PASSWORD_NOT_MATCH: "The passwords do not match",
  LINK_COPIED: "Link copied to clipboard!",
  CHANGES_SAVED: "Changes saved successfully!",
  FORM_ARCHIVED: "Form archived successfully!",
  FORM_PUBLISHED: "Form published successfully!",
  TEAM_MEMBERS_ADDED: "Team member added successfully!",
  STUDY_DUPLICATE: "Survey folder duplicated successfully!",
  STUDY_DELETED: "Survey folder(s) deleted successfully!",
  PERMISSIONS_STUDY: "You don't have any permission to access this Survey Folder.",
  FORM_DELETED: "Survey deleted successfully!",
  FORM_DUPLICATED: "Survey duplicated successfully!",
  QUESTION_SAVED: "Question saved successfully!",
  QUESTION_UPDATED: "Question edited successfully!",
  QUESTION_DELETED: "Question deleted successfully!",
  QUESTION_DUPLICATED: "Question duplicated successfully!",
  QUESTION_CHANGE_MANDATORY: "Mandatory value succesfully updated",
  QUESTION_ERROR: "Error while creating the question. Please try again.",
  LOCALE_REQUIRED: "The locale field cannot be empty!",
  NAME_REQUIRED: "The name field cannot be empty!",
  DESCRIPTION_REQUIRED: "The description field cannot be empty!",
  QUESTION_DELETED: "Question(s) deleted successfully!",
  QUESTION_DUPLICATED: "Question duplicated successfully!",
  CHANGE_ORDER: "Unable to change the questions order. Please try again.",
  PASSWORD_SAVED: "Password changed successfully!",
  LOGIN_ERROR:
    "These credentials do not match our records. Please review them and try again",
  OLD_PASSWORD_ERROR: "The old password is incorrect.",
  NEW_PASSWORD_MATCH: "Please make sure your passwords match",
  OLD_NEW_PASSWORD_SAME: "The new password is the same as the old one. Please type in a different password",
  NOT_PERMISSION: "You don't have the permission to perform this action",
  EMAIL_SEND: "We’ve sent you an email to reset your password.",
  ANSWER_OPTION: "This question must have at least one answer option",
  SECTION_SAVED: "Section saved successfully",
  QUESTION_MOVED: "The question was updated successfully!",
  SELECTED_QUESTION: "You don't have selected questions",
  NO_QUESTIONS: "You have to create at least one question first",
  SECTION_DELETED: "Section deleted successfully!",
  SECTION_ERROR:
    "You cannot delete sections with questions inside. Please, move or delete the questions before deleting the section.",
  SECTION_DEFAULT:
    "It's not possible to delete the default section. You may change its name if necessary.",
  SUMMARY_ERROR:
    "You cannot delete summary with questions inside. Please, move or delete the questions before deleting the summary.",
  REPEATED_VARNAME:
    "This variable name is already being used in this survey. Please, choose a different one",
  SPACE_VARNAME:
    "Variable names do not support spaces. Please, write it again with no spaces. You may use symbols like - and _ to make the variable name more legible",
  SUMMARY_CREATED: "Summary created successfully!",
  SUMMARY_INCLUDED: "Question(s) included in the summary successfully!",
  TRANSLATIONS_ERROR: "The translations could not be saved correctly. Translate the answers to all the languages of the form.",
  NETWORK_ERROR: "Please ensure that you have a stable internet connection to proceed with this action",
};

export const DashboardTexts = {
  STUDY_PARTICIPANTS: "Study Participants",
  STUDY_TEAM_MEMBERS: "Team Members",
  DATA_POINTS_COLLECTED: "Data Points Collected",
  CHOOSE_DATA: "Choose a survey to view data",
};

export const LinksInfo = {
  SUMMARIES:
    "https://right-chocolate-d0c.notion.site/Summaries-1908ef399d8545bbbc05174bfd3dd2b5",
  QUESTION_TYPE:
    "https://docs.google.com/document/u/1/d/e/2PACX-1vQkQFg4O7erLNHlJTlXpthfriz1iWRWOQjWAgxGgux2grq1MNOw0zp1dGlZRUvUyy6C2t8-m4sgYtrW/pub#h.hgwagjn8xn30",
};

export const dashboardTexts = {
  LOADING_FORMS: "Loading forms...",
  LOADING_ANSWERS: "Loading answers...",
  SELECT_FORM_DROPDOWN: "Select a form",
  TABLE_TITLE: "Answers table",
  SELECT_FORM: "Select a form from the menu to see the answers",
  NO_ANSWERS: "This form has no answers",
  DELETE_TITLE: "Delete answers",
  DELETE_CONFIRM: "Are you sure that you want to delete the following answers?",
  DOWNLOAD_TITLE: "Download answers",
  STUDY_LOADING_ERROR: "Can't load this study, please try again",
  ANSWERS_ERROR: "Can't load the answers table, please try again",
  DELETE_ERROR: "An error happened while deleting the answer, please try again",
  EXPORT_NO_DATA: "No data to export for the selected date range",
}