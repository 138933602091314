import { Box } from "@mui/material";
import LeftSection from "./LeftSection/LeftSection";
import RightSection from "./RightSection/RightSection";

function FirstPageAddQuestionForm({ children }) {
  return (
    <Box
      id="first-page-container"
      display="flex"
    >
      {children}
    </Box>
  )
}

export default FirstPageAddQuestionForm