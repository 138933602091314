export function mapQuestions(questions, stateQuestions) {
  return questions.map(question => {
    const {
      id,
      section_id,
      question_translations,
      type,
      mandatory,
      order,
      created_at,
      updated_at,
      variable_name,
      conditional,
      summary,
      answer_values,
      url_img,
      widget,
      long_text,
      min_value,
      max_value,
      units,
      interval,
      calculation,
    } = question

    const { conditionalQuestions, operator } = mapConditionalQuestions(conditional, questions, stateQuestions);

    return {
      id: id,
      questionTranslations: question_translations,
      sectionId: section_id,
      type,
      order,
      mandatory: Boolean(mandatory),
      created_at,
      updated_at,
      variableName: variable_name,
      conditional: Boolean(conditional),
      conditionalQuestions: conditionalQuestions.length > 0 ? conditionalQuestions : null,
      operator,
      summary: Boolean(summary),
      imageURL: url_img,
      presentationType: widget,
      longText: Boolean(long_text),
      minValue: min_value,
      maxValue: max_value,
      units,
      interval,
      calculation,
      choiceQuestions: answer_values
    }
  })
}

// * conditionalQuestions comes as a string. Here it is parsed and transformed into an object
// * It returns the conditional questions and their operator(AND || OR)
function mapConditionalQuestions(conditionalQuestions, questions) {
  if (!conditionalQuestions) {
    return { conditionalQuestions: [], operator: "" }
  }

  const regex = /(\w+)=([\d.]+)(AND|OR)?/g;

  const mappedConditionalQuestions = [];
  let match;
  let operator = null;
  while ((match = regex.exec(conditionalQuestions)) !== null) {
    const variable = match[1];
    const answer = match[2];
    const tempOperator = match[3];

    if (!operator) {
      operator = tempOperator === "AND" ? 1 : 0
    }

    const variableNameKey = !!questions[0]?.variableName ? "variableName" : "variable_name"

    const findOriginalConditionalQuestion = questions.find(question => question[variableNameKey] === variable)

    const newConditionalQuestion = { variable, answer, type: findOriginalConditionalQuestion?.type };
    mappedConditionalQuestions.push(newConditionalQuestion);
  }

  return { conditionalQuestions: mappedConditionalQuestions, operator }
}

export function findSectionToUpdateIndex(state, sectionId, isSummaryPage) {
  return isSummaryPage ? 0 : state.data.findIndex(section => section.id === sectionId)
}

// * Merges sections into a new array after changes to the updated section are done
export function mergeSections(state, updatedSection, sectionToUpdateIndex) {
  return [
    ...state.data.slice(0, sectionToUpdateIndex),
    updatedSection,
    ...state.data.slice(sectionToUpdateIndex + 1),
  ];
}