import BootstrapDialog from "./BootstrapDialog"
import BootstrapDialogTitle from "./BootstrapDialogTitle"

const CustomDialog = ({ children, title, show, handleShow, maxWidth, ...other }) => {
  function handleClose(event, reason) {
    if (reason && reason === "backdropClick") {
      return
    } else {
      handleShow(false);
    }
  };

  return <BootstrapDialog
    open={show}
    onClose={handleClose}
    scroll={"body"}
    aria-labelledby="customized-dialog"
    disableEnforceFocus
    fullWidth
    maxWidth={maxWidth || 'md'}
    {...other}
  >
    <BootstrapDialogTitle
      onClose={handleClose}
      id="customized-dialog-title"
    >
      {title}
    </BootstrapDialogTitle>
    {children}
  </BootstrapDialog>
}

export default CustomDialog