import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material"
import TableHeaderRowMonitoring from "./TableHeaderRowMonitoring";
import CreateButton from "../CreateButton";
import { makeStyles } from "@mui/styles";
import { AppColors } from "resources/AppColors";

const useStyles = makeStyles(() => ({
  tableHead: {
    backgroundColor: AppColors.CBM_SAND,
  },
  tableCell: {
    height: "auto",
    paddingLeft: 0,
  },
}));

function TableHeaderStatic({
  tableHeaders,
  entity,
  canCreate,
  numSelected,
  rowCount,
  onSelectAllClick,
  dndDisabled,
  isSummaryPage
}) {
  const classes = useStyles()

  return (
    <TableHead
      className={classes.tableHead}
    >
      <TableRow>
        {onSelectAllClick && rowCount > 0 &&
          <TableCell padding="checkbox" className={classes.tableCell} style={{ paddingLeft: "16px" }}>
            <Checkbox
              color={numSelected === rowCount ? "error" : "default"}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
            />
          </TableCell>
        }
        {!dndDisabled && !onSelectAllClick &&
          <TableCell />
        }
        {tableHeaders.map(header => {
          if (isSummaryPage && (header === "In Summary")) return

          return <TableHeaderRowMonitoring
            key={header}
            title={header}
            className={classes.tableCell}
          />;
        }
        )}
        <TableCell align="center">
          {canCreate && <CreateButton entity={entity} />}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TableHeaderStatic