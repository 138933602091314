import { Checkbox, TableCell, TableRow } from "@mui/material"
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { AppColors } from "resources/AppColors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tableCellInit: {
    padding: 0,
    paddingLeft: "16px",
    borderTopLeftRadius: "5px!important",
    borderBottomLeftRadius: "5px!important",
    borderLeft: `2px solid ${AppColors.CBM_SAND}`,
    borderTop: `2px solid ${AppColors.CBM_SAND}`,
    borderBottom: `2px solid ${AppColors.CBM_SAND}`,
    // color: AppColors.PRIMARY,
    backgroundColor: AppColors.WHITE,
  },
  tableCellFinish: {
    padding: 0,
    borderTopRightRadius: "5px!important",
    borderBottomRightRadius: "5px!important",
    borderRight: `2px solid ${AppColors.CBM_SAND}`,
    borderTop: `2px solid ${AppColors.CBM_SAND}`,
    borderBottom: `2px solid ${AppColors.CBM_SAND}`,
    // color: AppColors.PRIMARY,
    backgroundColor: AppColors.WHITE,
  },
}));

function TableRowStatic({
  children,
  entity,
  actions,
  loaded,
  onSelectOneClick,
  isItemSelected,
  droppable,
  dndDisabled
}) {
  const classes = useStyles()
  const labelId = `enhanced-table-checkbox-${entity.id.answer}`;

  return (
    <TableRow
      data-cy="table-row-static"
      role={onSelectOneClick ? "checkbox" : "row"}
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={entity.id}
      selected={isItemSelected}
      {...droppable.draggableProps}
      {...droppable.dragHandleProps}
      ref={droppable.innerRef}
    >
      {onSelectOneClick &&
        <TableCell padding="checkbox" className={classes.tableCellInit} >
          <Checkbox
            onClick={onSelectOneClick ? ((event) => onSelectOneClick(event, entity.id)) : null}
            color="error"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCell>
      }
      {!dndDisabled && !onSelectOneClick &&
        <TableCell
          className={classes.tableCellInit}
        >
          <DragHandleIcon />
        </TableCell>
      }
      {children}
      {loaded &&
        <TableCell
          className={classes.tableCellFinish}
        >
          {actions}
        </TableCell>
      }
    </TableRow>
  );
}

export default TableRowStatic