import React from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material"
import { questionTypes, QUESTION_MULTIPLE_CHOICE, QUESTION_SINGLE_CHOICE } from "resources/QuestionConstants"
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { toast } from "../../reducers/notificationsReducer";
import { useSelector } from "react-redux";
import { findTranslation } from "utils/HelperFunctions";
import { AppColors } from "resources/AppColors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
}));

function ChoiceQuestion({ choiceQuestions, setChoiceQuestions, questionType }) {
  const classes = useStyles();

  const form = useSelector(({ form }) => form.data[0])

  const dispatch = useDispatch()

  /**
   * It takes in the index of the answer option and the event object, and then it updates the answer
   * option in the choiceQuestions array with the new value
   * @param e - the event object
 * @param index - the index of the answer option in the choiceQuestions array
 */
  function handleChangeAnswerOptions(e, index) {
    const { name, value } = e.target;

    if (name === "answer") {
      // * If it is editing a question it finds the default translation, if it is creating a question it sets the default locale in the object
      const choiceAnswerDefaultLocale = findTranslation(choiceQuestions[index]?.translations, form.defaultLocale.value) || { locale: form.defaultLocale.value }

      const updatedChoiceAnswer = { ...choiceAnswerDefaultLocale, text: value }

      // * if translations have length, we are editing, if it doesn't we are creating a new select question
      const updatedAnswer = {
        ...choiceQuestions[index],
        translations: choiceQuestions[index].translations.length > 0
          ? choiceQuestions[index].translations.map(translation => translation?.id !== updatedChoiceAnswer?.id ? translation : updatedChoiceAnswer)
          : [updatedChoiceAnswer]
      }

      const replaceAnswerToUpdate = [
        ...choiceQuestions.slice(0, index),
        updatedAnswer,
        ...choiceQuestions.slice(index + 1)
      ]

      setChoiceQuestions(replaceAnswerToUpdate)
    }

    if (name === "answerValue") {
      const updatedAnswer = { ...choiceQuestions[index], value }

      const replaceAnswerToUpdate = [
        ...choiceQuestions.slice(0, index),
        updatedAnswer,
        ...choiceQuestions.slice(index + 1)
      ]

      setChoiceQuestions(replaceAnswerToUpdate)
    }
  };

  /**
 // * If the last answer and value fields are filled, add a new answer and value field
 */
  function handleAddAnswer() {
    const answerAndValueFilled = choiceQuestions.every(answer => !!answer.translations.every(translation => !!translation.text) && !!answer.value)

    if (!answerAndValueFilled) {
      dispatch(toast("Make sure you fill all the answers before proceeding.", "error"))
      return
    }

    setChoiceQuestions([...choiceQuestions, { translations: [{ locale: form.defaultLocale.value, text: "" }], value: "" }])
  }

  function handleDeleteAnswer(index) {
    const updatedAnswers = choiceQuestions.filter((_, i) => i !== index);

    setChoiceQuestions(updatedAnswers);
  }

  return <>
    {(questionType ===
      questionTypes[QUESTION_SINGLE_CHOICE].name
      ||
      questionType ===
      questionTypes[QUESTION_MULTIPLE_CHOICE].name)
      && (
        <Box id="choice-question" width="100%">
          {choiceQuestions.map((answer, index) => (
            <Box key={index} display="flex" justifyContent="space-around" id="answers">
              <TextField
                name="answer"
                type="text"
                required
                onChange={(e) => handleChangeAnswerOptions(e, index)}
                label={`Answer ${index + 1}`}
                value={findTranslation(answer?.translations, form.defaultLocale.value)?.text || ""}
                variant='outlined'
                margin="normal"
                sx={{ width: "45%" }}
                className={classes.textField}
              />
              <TextField
                name="answerValue"
                type="number"
                required
                onChange={(e) => handleChangeAnswerOptions(e, index)}
                label={`Value ${index + 1}`}
                value={answer.value}
                variant='outlined'
                margin="normal"
                sx={{ width: "45%" }}
                className={classes.textField}
              />
              {choiceQuestions.length > 1 &&
                <IconButton onClick={() => handleDeleteAnswer(index)} size="large">
                  <DeleteIcon />
                </IconButton>}
            </Box>
          ))}
          <Box mt={1}>
            <Typography
              paragraph
              onClick={handleAddAnswer}
              variant="subtitle_bold"
              sx={{ cursor: "pointer" }}
            >
              Answer options <Typography variant="h6" sx={{ display: "inline", cursor: "pointer" }}>+</Typography>
            </Typography>
          </Box>
        </Box>
      )}
  </>;
}

export default ChoiceQuestion