import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Typography } from '@mui/material';
import { menuOptions } from "locale/en";
// import { useNavigate } from 'react-router-dom';
import { AppColors } from 'resources/AppColors';

function DeleteMenuOption({ questionId, showText, handleModalOpen, setQuestionToDeleteId }) {
  // const navigate = useNavigate()

  function handleDeleteModal() {
    // navigate(`${questionId}/delete`, { state: { questionId } })
    handleModalOpen()
    setQuestionToDeleteId(questionId)
  }

  return (
    <Box onClick={handleDeleteModal} sx={{ width: "100%" }} data-testId='deleteOption' >
      <IconButton data-cy="table-delete-button" size="large">
        <DeleteIcon sx={{ fill: AppColors.PRIMARY }} />
        {showText &&
          <Typography variant="menu_list_text" >
            {menuOptions.DELETE}
          </Typography>
        }
      </IconButton>
    </Box>
  );
}

export default DeleteMenuOption