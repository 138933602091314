import { Box, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { AppColors } from "resources/AppColors";
import { questionTypes, QUESTION_RANGE } from "resources/QuestionConstants"

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
}));

function RangeQuestion({ numericalQuestionsValues, handleNumericalQuestionsValues, questionType }) {
  const classes = useStyles();

  return (
    <>
      {questionType === questionTypes[QUESTION_RANGE].name
        && (
          <Box
            display="flex"
            justifyContent="space-between"
            id="range-question"
          >
            <TextField
              name="minValue"
              type="number"
              required
              onChange={handleNumericalQuestionsValues}
              label={"Min. Value"}
              value={numericalQuestionsValues.minValue}
              variant='outlined'
              margin="normal"
              className={classes.textField}
            />
            <TextField
              name="maxValue"
              type="number"
              required
              onChange={handleNumericalQuestionsValues}
              label={"Max. Value"}
              value={numericalQuestionsValues.maxValue}
              variant='outlined'
              margin="normal"
              className={classes.textField}
            />
            <TextField
              name="interval"
              type="number"
              required
              onChange={handleNumericalQuestionsValues}
              label={"Interval"}
              value={numericalQuestionsValues.interval}
              variant='outlined'
              margin="normal"
              className={classes.textField}
            />
          </Box>
        )}
    </>
  )
}

export default RangeQuestion