/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { AppColors } from "../resources/AppColors";
import { CommonTexts, LabelsDrawer, LabelsStudy, TableTexts, } from "../locale/en";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { validateEmail } from "../utils/HelperFunctions";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import DialogUhda from "../components/DialogUhda";
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { useLocation } from "react-router-dom";
import ParticipantsService from "../services/ParticipantsService";
import DrawerUhda from "../components/DrawerUhda";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const useStyles = makeStyles((theme) => ({
  option: {
    backgroundColor: "#0000000a"
  }
}));
function AddModal({
  handleAddParticipant,
  initialValues,
  values,
  setValues,
  showAddModal,
  setShowAddModal,
}) {
  const [showEmailError, setShowEmailError] = useState(false);
  const [selectedRole, setSelectedRole] = useState(0);
  const [dateValue, setDateValue] = useState(null);
  const [hasEmail, setHasEmail] = useState(false);
  //dropdown on textfield
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailPaste, setEmailPaste] = useState("");

  const [emailsAvailable, setEmailsAvailable] = useState(["invite this email"]);
  const [isNewEmail, setIsNewEmail] = useState(true);
  const [hasEmailData, setHasEmailData] = useState(false);
  const [emailData, setEmailData] = useState();
  const [patientId, setPatientId] = useState();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  //let studyId = location.state.detail;

  const studyId = useRef();
  const [showError, setShowError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [flag, setFlag] = useState(false)
  const style = useStyles()

  const handleChangeRole = (e) => {
    setSelectedRole(e.target.value);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  //This function retrives the stored permissions and id from local storage
  const retriveStorageData = () => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    studyId.current = newItem.id;
  }
  useEffect(() => {
    retriveStorageData();

  }, [])

  useEffect(() => {
    setValues(initialValues);
  }, [showAddModal]);

  const handleClickSubmit = async () => {
    if (emailData && !isNewEmail) {
      if (!checked) {
        return setShowError(true);
      }
    } else {
      if (
        values.birthdate === "" ||
        values.name === "" ||
        values.surname === "" ||
        !checked
      ) {
        return setShowError(true);
      }
    }
    if (showEmailError) {
      return;
    }
    var newEmail = [];
    newEmail = email.split(": ");
    if (validateEmail(newEmail[1])) {
      setShowEmailError(false);
      const birthDateList = values.birthdate.split("/");
      const postBody = {
        patient_id: isNewEmail ? "" : patientId,
        birth_date: isNewEmail
          ? `${birthDateList[2]}-${birthDateList[0].length === 1
            ? "0" + birthDateList[0]
            : birthDateList[0]
          }-${birthDateList[1].length === 1
            ? "0" + birthDateList[1]
            : birthDateList[1]
          }`
          : emailData.birthdate,
        email: newEmail[1],
        name: isNewEmail ? values.name : emailData.name,
        surname: isNewEmail ? values.surname : emailData.surname,
        consent_form: true,
      };

      try {
        const responsePost = await ParticipantsService.postStudyParticipant(
          studyId.current,
          postBody
        );
      } catch (e) {

        setShowError(true);
      }
      //TODO:: create a participant
      handleAddParticipant();
    } else {
      setShowEmailError(true);
    }
  };

  const handleChangeConsent = (event) => {
    setChecked(event.target.checked);
  };

  const getParticipant = async (emailValue) => {
    setFlag(false)
    var emailFieldList = emailValue.split(": ");
    var finalEmailValue = "";
    if (emailFieldList.length === 1) {
      finalEmailValue = emailFieldList[0];
    } else {
      finalEmailValue = emailFieldList[1];
    }
    try {
      const response = await MyAxiosInstance.get(
        `${BASE_PATH}/patient-by-email/${studyId.current}/${finalEmailValue}`
      );
      if (response.status === 200) {
        var newList = [];
        if (response.data.data.participant_id) {
          setShowEmailError(true);
          newList.push(finalEmailValue);
          setEmailsAvailable(newList);
        } else {
          setShowEmailError(false);
          if (response.data.data.patient_id !== null) {
            setPatientId(response.data.data.patient_id);

            setDateValue(new Date(response.data.data.birth_date));
            newList = ["Add existing: " + response.data.data.email.toString()];
            setEmailsAvailable(newList);
            setEmailData({
              name: response.data.data.name,
              surname: response.data.data.surname,
              birthdate: response.data.data.birth_date,
            });
            setIsNewEmail(false);
          } else {
            setEmailData(null);
            var newEmail = ["invite this email: " + finalEmailValue];
            setEmailsAvailable(newEmail);
            setIsNewEmail(true);
          }
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  };

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      // textConfirmButton={hasEmail ? CommonTexts.SAVE : CommonTexts.NEXT}
      textConfirmButton={CommonTexts.SAVE}
      textConfirmAndContinue={hasEmail ? TableTexts.SAVE_AND_ADD : null}
      handleShow={setShowAddModal}
      handleConfirm={handleClickSubmit}
      values={values}
      setValues={setValues}
      title={TableTexts.ADD_PARTICIPANT_TITLE}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin="normal" style={{ width: "100%" }}>
            <Autocomplete
              data-testId={"email"}
              classes={{ option: style.option }}
              freeSolo
              open={open}
              onOpen={() => {
                if (validateEmail(email)) {
                  setOpen(true);
                }
              }}
              //onClose={() => setOpen(false)}
              inputValue={email}
              onInputChange={(e, value, reason) => {
                if (flag) {
                  value = email
                } else {
                  setEmail(value);
                }
                setFlag(false)
                if (!validateEmail(email)) {
                  setOpen(false);
                } else {
                  getParticipant(value);
                }
              }}

              onPaste={(e, value, reason) => {
                var text = (e.clipboardData.getData("Text"))
                text = text.trim()
                text = text.replace(/\s+/g, " ").trim();
                setEmail(text)
                //setEmail(e.clipboardData.getData('Text'));
                setFlag(true)
              }}
              options={validateEmail(email) ? emailsAvailable : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={showEmailError}
                  helperText={
                    showEmailError &&
                    "This email is already a participant of this study"
                  }
                  label={TableTexts.EMAIL}
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          {emailData && (
            <>
              <FormControl

                margin="normal"
                data-testId={"participantName"}
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="name"
                  handleChange={handleInputChange}
                  isDisabled={true}
                  label={TableTexts.NAME}
                  value={emailData.name}
                />
              </FormControl>
              <FormControl

                margin="normal"
                data-testId={"participantSurname"}
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="surname"
                  handleChange={handleInputChange}
                  isDisabled={true}
                  label={TableTexts.SURNAME}
                  value={emailData.surname}
                />
              </FormControl>
              <FormControl

                data-testId={"participantDate"}
                margin="normal"
                style={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled
                    inputFormat="dd/MM/yyyy"
                    label={TableTexts.BIRTHDATE}
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                      setValues({
                        ...values,
                        ["birthdate"]: newValue.toLocaleDateString("en-US"),
                      });
                    }}
                    renderInput={(params) => <TextField  {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl margin="normal" style={{ width: "100%" }}>
                <FormControlLabel
                  data-testId={"consent"}
                  style={{ paddingTop: "20px" }}
                  control={
                    <Checkbox
                      defaultChecked
                      color="primary"
                      checked={checked}
                      onChange={handleChangeConsent}
                    />
                  }
                  label="I confirm the participant has signed the consent form to participate in this study and agreed to store his/her data in UhDa Studies"
                />
              </FormControl>
            </>
          )}
          {!emailData && (
            <>
              <FormControl

                margin="normal"
                data-testId={"participantName"}
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="name"
                  handleChange={handleInputChange}
                  label={TableTexts.NAME}
                  value={values.name}
                />
              </FormControl>
              <FormControl

                margin="normal"
                data-testId={"participantSurname"}
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="surname"
                  handleChange={handleInputChange}
                  label={TableTexts.SURNAME}
                  value={values.surname}
                />
              </FormControl>
              <FormControl

                margin="normal"
                data-testId={"participantDate"}
                style={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label={TableTexts.BIRTHDATE}
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                      setValues({
                        ...values,
                        ["birthdate"]: newValue.toLocaleDateString("en-US"),
                      });
                    }}
                    renderInput={(params) => <TextField  {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl margin="normal" style={{ width: "100%" }}>
                <FormControlLabel
                  data-testId={"consent"}
                  style={{ paddingTop: "20px" }}
                  control={
                    <Checkbox
                      defaultChecked
                      color="primary"
                      checked={checked}
                      onChange={handleChangeConsent}
                    />
                  }
                  label="I confirm the participant has signed the consent form to participate in this study and agreed to store his/her data in UhDa Studies"
                />
              </FormControl>
            </>
          )}
          {showError && (
            <FormControl margin="normal" style={{ width: "100%" }}>
              <FormHelperText
                style={{ paddingLeft: "15px", color: "red" }}
                id="my-helper-text"
              >
                {showEmailError
                  ? "The email is already a participant of this study"
                  : "Make sure to fill all the fields"}
              </FormHelperText>
            </FormControl>
          )}
        </Grid>
      }
      show={showAddModal}
    />
  );
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func,
  handleAddParticipant: PropTypes.func,
  setValues: PropTypes.func,
  setShowAddModal: PropTypes.func,
};

function DeleteModal({
  setShowDeleteModal,
  showDeleteModal,
  selected,
  handleDeleteParticipant,
}) {
  useEffect(() => {
  }, [showDeleteModal]);
  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModal}
      handleConfirm={() => {
        handleDeleteParticipant();
      }}
      title={
        showDeleteModal === true
          ? selected.length > 1
            ? TableTexts.DELETE_TEAM_MEMBER_IDS + selected.toString()
            : TableTexts.DELETE_TEAM_MEMBER_ID + selected.toString()
          : TableTexts.DELETE_TEAM_MEMBER_ID + showDeleteModal
      }
      body={
        <Typography variant="subtitle1" gutterBottom>
          {showDeleteModal === true
            ? selected.length > 1
              ? TableTexts.DELETE_TEAM_MEMBER_TEXT_MULTIPLE
              : TableTexts.DELETE_TEAM_MEMBER_TEXT
            : TableTexts.DELETE_TEAM_MEMBER_TEXT}
        </Typography>
      }
      show={showDeleteModal}
    />
  );
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
  handleDeleteParticipant: PropTypes.func.isRequired,
};

function EditModal({
  handleEditParticipant,
  setShowEditModal,
  showEditModal,
  editValues,
  setEditValues,
}) {
  const [showEmailError, setShowEmailError] = useState(false);
  const [date, setDate] = useState();
  useEffect(() => {
    if (editValues.birthdate) {
      var parts = editValues.birthdate.split("-");
      setDate(new Date(`${parts[0]}-${parts[1]}-${parts[2]}`));
    }
  }, [showEditModal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(editValues["email"]);
    if (isEmail) {
      setShowEmailError(false);
      handleEditParticipant();
    } else {
      setShowEmailError(true);
    }
  };

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      handleShow={setShowEditModal}
      handleConfirm={handleClickSubmit}
      values={editValues}
      setValues={setEditValues}
      title={TableTexts.EDIT_TEAM_MEMBER_TITLE + showEditModal}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin="normal" style={{ width: "100%" }}>
            <TextfieldUhda
              isDisabled={true}
              name="email"
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={editValues.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && (
                  <InputAdornment position="end">
                    <ErrorIcon style={{ color: AppColors.RED }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl

            data-testId={"participantName"}
            margin="normal"
            style={{ width: "100%" }}>
            <TextfieldUhda
              name="name"
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={editValues.name}
            />
          </FormControl>
          <FormControl

            data-testId={"participantSurname"}
            margin="normal"
            style={{ width: "100%" }}>
            <TextfieldUhda
              name="surname"
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={editValues.surname}
            />
          </FormControl>
          <FormControl

            data-testId={"participantDate"}
            margin="normal"
            style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                label={TableTexts.BIRTHDATE}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                  setEditValues({
                    ...editValues,
                    ["birthdate"]: newValue.toLocaleDateString("en-US"),
                  });
                }}
                renderInput={(params) => <TextField  {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          {/* <FormControl margin='normal' style={{ width: '100%' }}>
                        <TextfieldUhda
                            name='name'
                            handleChange={handleInputChange}
                            label={TableTexts.NAME}
                            value={editValues.name}
                        />
                    </FormControl>
                    <FormControl margin='normal' style={{ width: '100%' }}>
                        <TextfieldUhda
                            name='surname'
                            handleChange={handleInputChange}
                            label={TableTexts.SURNAME}
                            value={editValues.surname}
                        />
                    </FormControl>
                    <FormControl margin='normal' style={{ width: '100%' }}>
                        <TextfieldUhda
                            name='email'
                            handleChange={handleInputChange}
                            label={TableTexts.EMAIL}
                            value={editValues.email}
                            error={showEmailError}
                            helperText={showEmailError && TableTexts.EMAIL_ERROR}
                            inputProps={{
                                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon style={{ color: AppColors.RED }} /></InputAdornment>,
                            }}
                        />
                    </FormControl> */}
        </Grid>
      }
      show={showEditModal}
    />
  );
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired,
};

/**
 * @Page
 * Page that shows the table of participants of a specific study
 */
const StudyParticipantsPage = ({ match, drawer }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const initialValues = { name: "", surname: "", birthdate: "", email: "" };
  const [values, setValues] = useState(initialValues);
  const [editValues, setEditValues] = useState(initialValues);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showAddModal, setShowAddModal] = useState(-999);
  const [showEditModal, setShowEditModal] = useState(-999);
  const [showDeleteModal, setShowDeleteModal] = useState(-999);
  const [response, setResponse] = useState([]);
  const [study, setStudy] = useState();
  // const id = location.state.detail;
  // const permissions = location.state.permissions
  const permissions = useRef();
  const id = useRef();
  const [open, setOpen] = useState(drawer); //false
  const [devicesSize, setDevicesSize] = useState("200px")
  const [cardWidth, setCardWidth] = useState("100%")
  const [windowSize, setWindowSize] = useState()
  const [breadcrumbMargin, setBreadcrumbMargin] = useState("275px");

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: TableTexts.NAME,
      isSortable: true,
    },
    {
      id: "surname",
      numeric: false,
      disablePadding: false,
      label: TableTexts.SURNAME,
      isSortable: false,
    },
    {
      id: "birthdate",
      numeric: false,
      disablePadding: false,
      label: TableTexts.BIRTHDATE,
      isSortable: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: TableTexts.EMAIL,
      isSortable: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: TableTexts.ACTIONS,
      isSortable: false,
    },
  ];

  const dispatch = useDispatch()

  //Function to add a new Participant
  const handleAddParticipant = async () => {
    setLoading(true);
    setValues(initialValues);
    getParticipants();
    dispatch(toast("Participant added", "success"))
    setShowAddModal(-999);
  };

  //Function to edit a participant
  const handleEditParticipant = async () => {
    setLoading(true);

    const postValues = {
      email: editValues.email,
      name: editValues.name,
      surname: editValues.surname,
      birth_date: editValues.birthdate,
    };
    try {
      const response = await MyAxiosInstance.put(
        `${BASE_PATH}/participants/${showEditModal}`,
        postValues
      );
      dispatch(toast("Participant Edited", "success"))
    } catch (e) {
      setLoading(false);

    }
    getParticipants();
    setShowEditModal(-999);
  };

  //Function to delete a participant
  const handleDeleteParticipant = async () => {
    setLoading(true);
    try {
      const response = await ParticipantsService.deleteStudy(
        id.current,
        showDeleteModal
      );
      dispatch(toast("Participant deleted", "success"))
    } catch (e) {
      setLoading(false);

    }
    getParticipants();
    setShowDeleteModal(-999);
  };

  //Function to get the data of the study from the id
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data);
    } catch (err) {
      setStudy(err.message);
    }
  };

  //Function to get all the participants of the user from the id
  const getParticipants = async () => {
    try {
      var responseAPI = await ParticipantsService.getStudyParticipants(id.current);

      responseAPI = Object.values(responseAPI.data.data.items);
      var data = [];
      responseAPI.forEach((res) => {
        var patientValue = {};
        if (res.patient.user) {
          patientValue["id"] = res["id"];
          patientValue["birthdate"] = res.patient["birth_date"];
          patientValue["email"] = res.patient.user["email"];
          patientValue["name"] = res.patient.user["name"];
          patientValue["surname"] = res.patient.user["surname"];
          data.push(patientValue);
        }
      });
      if (data.length === 0) {
        data = [
          {
            id: -999,
            name: "",
            surname: "",
            birthdate: "",
            email: "",
          },
        ];
      }
      setResponse(data);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setLoading(false);
  };
  //This function retrives the stored permissions and id from local storage
  const retriveStorageData = () => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;

  }
  useEffect(() => {
    retriveStorageData();

  }, [])
  useEffect(() => {
    getStudy().then(() => getParticipants());
  }, []);

  useEffect(() => {
    setOpen(drawer);
  }, [drawer]);

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      setWindowSize(window.innerWidth)

      if (open) {

        if (window.innerWidth >= 1000 && window.innerWidth <= 1680) {
          setDevicesSize("200px")
          setCardWidth("100%")
          setBreadcrumbMargin("200px")
        } else if (window.innerWidth >= 1920 && window.innerWidth === 2560) {
          setDevicesSize("-250px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")

        } else if (window.innerWidth >= 1920 && window.innerWidth <= 2560) {
          setDevicesSize("-5px")
          setCardWidth("100%")
          setBreadcrumbMargin("195px")

        }

        else {
          setDevicesSize("20px")
          setCardWidth("100%")
        }

      } else {
        if (window.innerWidth >= 640 && window.innerWidth <= 1600) {
          setDevicesSize("280px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")
        } else if (window.innerWidth >= 640 && window.innerWidth <= 1680) {
          setDevicesSize("170px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")
        } else if (window.innerWidth === 1920) {
          setDevicesSize("-40px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")

        }

        else if (window.innerWidth >= 1920 && window.innerWidth <= 2560) {
          setDevicesSize("-355px")
          setCardWidth("100%")

        }

        else if (window.innerWidth <= 600) {

          setDevicesSize("160px")
          setCardWidth("70%")
          setBreadcrumbMargin("150px")

        } else {
          //alert(window.innerWidth)
        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open])

  return (
    <>
      {study && <DrawerUhda id={id.current} drawer={open} select={2}
        settings={study.permissions} />}

      <Grid
        container

        direction={"column"}
        spacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item>
          {study && (
            <div style={{ marginLeft: breadcrumbMargin }}><BreadcrumbsUhda
              routes={[
                { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
                {
                  id: 1,
                  label: `${study.translations[0].study_title}`,
                  disabled: true,
                  studyId: id.current,
                  permissions: permissions.current
                },
                { id: 2, label: LabelsStudy.PARTICIPANTS },
              ]}
            /></div>
          )}
        </Grid>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {response && (
              <div style={{ marginLeft: devicesSize, maxWidth: window.innerWidth <= 1000 ? "60%" : cardWidth }}><TableBaseUhda
                tableTitle={TableTexts.PARTICIPANTS_TITLE}
                headCells={headCells}
                dataToUse={response}
                addModal={AddModal}
                deleteModal={DeleteModal}
                editModal={EditModal}
                initialValues={initialValues}
                values={values}
                setValues={setValues}
                editValues={editValues}
                setEditValues={setEditValues}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                handleAddParticipant={handleAddParticipant}
                handleEditParticipant={handleEditParticipant}
                handleDeleteParticipant={handleDeleteParticipant}
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                showEditModal={showEditModal}
                setShowEditModal={setShowEditModal}
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                interactive={true}
                canAdd={study.permissions.includes("participant-create")}
                canEdit={study.permissions.includes("participant-edit")}
                canDelete={study.permissions.includes("participant-delete")}
              /></div>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default StudyParticipantsPage;
