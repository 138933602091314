import { createSlice, current } from '@reduxjs/toolkit'
import { findSectionToUpdateIndex, mapQuestions, mergeSections } from './formUtils'

const formSlice = createSlice({
  name: 'form',
  initialState: { data: undefined, loading: false, error: null },
  reducers: {
    setForm(state, action) {
      const { data, allLocales } = action.payload

      const mappedForm = data?.sections?.map((section) => {
        const {
          id,
          order,
          questions,
        } = section

        return {
          id,
          title: data.form_translations[0]?.title,
          description: data.form_translations[0]?.description,
          view: data.visibility_label,
          archived: data.form_status === 1 ? false : true,
          url: data.public_url,
          creationDate: data.date_creation,
          order,
          questions: mapQuestions(questions),
          tags: data.tags.map(tag => {
            return {
              name: tag.name,
              value: tag.name
            }
          }),
          defaultLocale: !!data.default_locale[0]?.locale && allLocales?.length > 0 ? allLocales.find(locale => data.default_locale[0].locale === locale.value) : { value: "Could not find default locale" },
          locales: allLocales.filter(locale => data.form_translations.some(formLocale => formLocale.locale === locale.value)),
          selectedLocale: data.default_locale[0]?.locale || "",
          study: { // TODO: Remove it when study reducer is implemented
            id: data.study_id,
            title: data.study.study_translations[0]?.study_title
          },
          summary: null // ! Summary is always null in CBM
        };
      })

      return { ...state, data: mappedForm, loading: false, error: null }
    },
    createForm(state, action) {
      const { data, allLocales } = action.payload

      const mappedForm = data.sections?.map((section) => {
        const {
          id,
          order,
          questions,
        } = section

        return {
          id,
          title: data.form_translations[0]?.title,
          description: data.form_translations[0]?.description,
          view: data.visibility_label,
          archived: data.form_status === 1 ? false : true,
          url: data.public_url,
          creationDate: data.date_creation,
          order,
          questions: mapQuestions(questions),
          tags: data.tags.map(tag => {
            return {
              name: tag.name,
              value: tag.name
            }
          }),
          defaultLocale: !!data.default_locale[0]?.locale && allLocales?.length > 0 ? allLocales.find(locale => data.default_locale[0].locale === locale.value) : { value: "Could not find default locale" },
          locales: allLocales.filter(locale => data.form_translations.some(formLocale => formLocale.locale === locale.value)),
          selectedLocale: data.default_locale[0]?.locale || "",
          study: { // TODO: Remove it when study reducer is implemented
            id: data.study_id,
            title: data.study.study_translations[0]?.study_title
          },
          summary: null // ! Summary is always null in CBM
        };
      })

      return { ...state, data: mappedForm, loading: false, error: null }
    },
    updateForm(state, action) {
      const { data, allLocales } = action.payload

      const mappedForm = data.sections?.map((section) => {
        const {
          id,
          order,
        } = section

        return {
          id,
          title: data.form_translations[0]?.title,
          description: data.form_translations[0]?.description,
          view: data.visibility_label,
          archived: data.form_status === 1 ? false : true,
          url: data.public_url,
          creationDate: data.date_creation,
          order,
          questions: state.data[0].questions,
          tags: data.tags.map(tag => {
            return {
              name: tag.name,
              value: tag.name
            }
          }),
          defaultLocale: !!data.default_locale[0]?.locale && allLocales?.length > 0 ? allLocales.find(locale => data.default_locale[0].locale === locale.value) : { value: "Could not find default locale" },
          locales: allLocales.filter(locale => data.form_translations.some(formLocale => formLocale.locale === locale.value)),
          selectedLocale: data.default_locale[0]?.locale || "",
          study: { // TODO: Remove it when study reducer is implemented
            id: data.study_id,
            title: data.study.study_translations[0]?.study_title
          },
          summary: null // ! Summary is always null in CBM
        };
      })

      return { ...state, data: mappedForm, loading: false, error: null }
    },
    appendQuestion(state, action) {
      const { data, isSummaryPage } = action.payload
      const sectionToUpdateIndex = findSectionToUpdateIndex(state, data.section_id, isSummaryPage)

      if (sectionToUpdateIndex === -1) {
        return { ...state, loading: false, error: "Error: Can't find the section that contains the questions to be updated" }
      }

      const updatedSection = {
        ...state.data[sectionToUpdateIndex],
        questions: [...state.data[sectionToUpdateIndex].questions, ...mapQuestions([data], state.data[sectionToUpdateIndex].questions)]
      };

      const updatedSections = mergeSections(state, updatedSection, sectionToUpdateIndex)

      return { ...state, data: updatedSections, loading: false, error: null };
    },
    updateQuestion(state, action) {
      const { data, isSummaryPage } = action.payload

      const sectionToUpdateIndex = findSectionToUpdateIndex(state, data.section_id, isSummaryPage)

      if (sectionToUpdateIndex === -1) {
        return { ...state, loading: false, error: "Error: Can't find the section that contains the questions to be updated" }
      }

      // * Since we only edit a question per time, we access to the object with its index
      const mappedNewQuestion = mapQuestions([data], state.data[sectionToUpdateIndex].questions)[0]

      const updatedSection = {
        ...state.data[sectionToUpdateIndex],
        questions: state.data[sectionToUpdateIndex].questions.map(question =>
          question.id !== mappedNewQuestion.id ? question : mappedNewQuestion
        )
      };

      const updatedSections = mergeSections(state, updatedSection, sectionToUpdateIndex)

      return { ...state, data: updatedSections, loading: false, error: null };
    },
    deleteQuestion(state, action) {
      const { sectionId, questionId, isSummaryPage } = action.payload

      const sectionToUpdateIndex = findSectionToUpdateIndex(state, sectionId, isSummaryPage)

      if (sectionToUpdateIndex === -1) {
        return { ...state, loading: false, error: "Error: Can't find the section that contains the questions to be updated" }
      }

      const deletedQuestion = state.data[sectionToUpdateIndex].questions.find(question => question.id === questionId)
      const removedQuestions = state.data[sectionToUpdateIndex].questions.filter(question =>
        question.id !== questionId)

      const startIndex = deletedQuestion.order - 1;

      // Create a new array with the updated questions order value
      const updatedQuestionsOrder = removedQuestions.map((question, index) => {
        if (index >= startIndex) {
          return { ...question, order: question.order - 1 };
        }
        return question;
      });

      const updatedSection = {
        ...state.data[sectionToUpdateIndex],
        questions: updatedQuestionsOrder
      };

      const updatedSections = mergeSections(state, updatedSection, sectionToUpdateIndex)

      return { ...state, data: updatedSections, loading: false, error: null };
    },
    changeQuestionsOrder(state, action) {
      const { data, isSummaryPage } = action.payload

      const sectionToUpdateIndex = isSummaryPage ? 0 : state.data.findIndex(section => data.some(question => question.sectionId === section.id))

      if (sectionToUpdateIndex === -1) {
        return { ...state, loading: false, error: "Error: Can't find the section that contains the questions to be updated" }
      }

      const updatedSection = { ...state.data[sectionToUpdateIndex], questions: data };

      const updatedSections = mergeSections(state, updatedSection, sectionToUpdateIndex)

      return { ...state, data: updatedSections, loading: false, error: null };
    },
    changeSelectedLocale(state, action) {
      return { ...state, data: [{ ...state.data[0], selectedLocale: action.payload }] }
    },
    setFormLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setFormError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const {
  setForm,
  createForm,
  updateForm,
  appendQuestion,
  updateQuestion,
  deleteQuestion,
  changeQuestionsOrder,
  changeSelectedLocale,
  setFormLoading,
  setFormError
} =
  formSlice.actions

export default formSlice.reducer