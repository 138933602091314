/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogUhda from "components/DialogUhda";
import { CommonTexts } from "locale/en";
import { FormControl, Grid, Typography, } from "@mui/material";
import MyAxiosInstance from "utils/MyAxiosInstance";
import { BASE_PATH } from "resources/ApiUrls";
import { AppColors } from "resources/AppColors";
import { useDispatch } from "react-redux";
import { toast } from "reducers/notificationsReducer";
import StudiesService from "services/StudiesService";
import Loader from "components/Loader/Loader"
// import { moveQuestionsAction } from "reducers/sectionsReducer";
import SingleChoiceDropdown from "components/Dropdowns/SingleChoiceDropdown";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,

  }, textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      borderRadius: 10,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    borderRadius: 10,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    "&:after": {
      borderBottomColor: AppColors.PRIMARY,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,
  },

}));

export function MoveQuestionsModal({
  show,
  handleShow,
  initialStudyId,
  initialFormId,
  initialSections,
  originSection,
  selectedQuestions,
  setSelectedQuestions
}) {
  const [studies, setStudies] = useState(null)
  const [sections, setSections] = useState(initialSections)
  const [forms, setForms] = useState(null)
  const [selectedStudyId, setSelectedStudyId] = useState(initialStudyId)
  const [selectedFormId, setSelectedFormId] = useState(initialFormId)
  const [selectedSectionId, setSelectedSectionId] = useState(initialSections[0].id)
  const [loadingMessage, setLoadingMessage] = useState("Loading studies, forms and sections...")

  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    getStudies()
    getForms(selectedStudyId, true)
  }, [])

  function handleMoveQuestions() {
    const destinationSection = sections.find(section => section.id === selectedSectionId)

    // dispatch(moveQuestionsAction(selectedQuestions, selectedFormId, originSection, destinationSection))
    setSelectedQuestions([])
    handleShow(false)
  }

  // TODO: Get from redux after creating studies reducer
  async function getStudies() {
    try {
      const { data } = await StudiesService.getStudies()
      setStudies(data.data);
    } catch (e) {
      dispatch(toast("Can't get the studies. Please try again", "error"))
      handleShow(false)
    }
  };

  /**
   * It gets all the forms for a study and sets the first form as the default form
   * @param studyId - The id of the study you want to get the forms from.
   */
  // eslint-disable-next-line no-shadow
  async function getForms(studyId, firstRender = false) {
    try {
      setLoadingMessage("Loading forms...")
      const { data } = await MyAxiosInstance.get(`${BASE_PATH}/study/${studyId}/summary-forms/locale/en`);

      setForms(data.data.items);

      if (data.data.items.length === 0) {
        dispatch(toast("This study doesn't contain a form, please choose a different study", "error"))
      }

      if (!firstRender) {
        setSelectedFormId(data.data.items[0]?.id)
        getSections(data.data.items[0]?.id)
      }
    } catch (e) {
      dispatch(toast("Can't find the selected form. Please try again", "error"))
    }
  };

  /**
   * It gets all the sections of a form and sets the state of the sections
   * @param formId - The id of the form you want to get the sections from.
   */
  // eslint-disable-next-line no-shadow
  async function getSections(formId) {
    try {
      setLoadingMessage("Loading sections...")
      // const { data } = await MyAxiosInstance.get(GET_SECTIONS(formId));
      setSections(data.data.item);
      setSelectedSectionId(data.data.item[0]?.id)
    } catch (e) {
      dispatch(toast("Can't find the sections for the selected form.", "error"))
      setSections([])
    }
  };

  /**
   * It sets the state of the selectedStudyId to the value of the input field.
   * @param e - the event that triggered the function
   */
  function handleChangeStudyId(e) {
    setSelectedStudyId(e.target.value)
    setForms(null)
    setSections(null)
    getForms(e.target.value)
  };

  /**
   * It takes the value of the form id input field and sets it to the state variable selectedFormId
   * @param e - the event object
   */
  function handleChangeFormId(e) {
    setSelectedFormId(e.target.value)
    setSections(null)
    getSections(e.target.value)
  };

  function handleChangeSectionId(e) {
    setSelectedSectionId(e.target.value)
  };

  return (
    <DialogUhda
      show={show}
      handleShow={handleShow}
      buttonColor={AppColors.PRIMARY}
      handleModalClose={handleShow}
      size={true}
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      handleCloseButton={handleShow}
      closeButton={true}
      handleConfirm={handleMoveQuestions}
      title={"Move question"}
      body={
        <>
          {!studies || !forms || !sections ?
            <Loader message={loadingMessage} pt={1} />
            :
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <>
                <Grid
                  item
                  xs={6}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Typography variant="subtitle_medium">
                    {CommonTexts.MOVE_QUESTIONS}
                  </Typography>
                  <FormControl
                    margin="normal"
                    fullWidth
                    className={classes.select}
                    sx={{ mt: 3 }}
                  >
                    <SingleChoiceDropdown
                      label={"Study"}
                      value={selectedStudyId}
                      handleValue={handleChangeStudyId}
                      items={studies.map(study => { return { name: study.translations[0]?.study_title, value: study.id } })}
                      className={classes.select}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    className={classes.select}
                  >
                    <SingleChoiceDropdown
                      label={"Forms"}
                      value={selectedFormId}
                      handleValue={handleChangeFormId}
                      items={forms.map(form => { return { name: form.form_translations[0]?.title, value: form.id } })}
                      className={classes.select}
                    />
                  </FormControl>
                  <FormControl
                    margin="normal"
                    fullWidth
                    className={classes.select}
                  >
                    <SingleChoiceDropdown
                      label={"Section"}
                      value={selectedSectionId}
                      handleValue={handleChangeSectionId}
                      items={sections.map(section => { return { name: !!section.section_translations ? section.section_translations[0]?.title : section.title, value: section.id } })}
                      className={classes.select}
                    />
                  </FormControl>
                </Grid>
              </>
            </Grid>
          }
        </>
      }
    />
  );
}
MoveQuestionsModal.propTypes = {
  initialStudyId: PropTypes.string.isRequired,
};
