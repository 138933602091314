import {createContext} from "react"
import {StorageManager} from "../../utils";

const storageManager = new StorageManager()

const authContext = createContext(
  {
    signOut: () => {
      storageManager.storeToken(null);
    }
  }
)

export default authContext