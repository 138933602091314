import FormsService from '../../services/FormsService'
import QuestionsService from '../../services/QuestionsService'
import SummaryService from '../../services/SummaryService'
import { SnackBarResponses } from '../../locale/en'
import { toast } from '../notificationsReducer'
import { appendQuestion, deleteQuestion, setForm, updateForm, setFormError, setFormLoading, updateQuestion, changeQuestionsOrder, createForm, changeSelectedLocale } from "./formReducer";
import { findTranslation } from 'utils/HelperFunctions'

export const initializeForm = (studyId, formId, isSummaryPage) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setFormLoading());

      if (!isSummaryPage) {
        const { data } = await FormsService.getForm(studyId, formId)

        dispatch(setForm({ data: data.data.item, allLocales: getState().locales.data }))
      }
      else {
        const { data } = await SummaryService.get(formId)
        dispatch(setSummaryForm({ data: data.data.item }))
      }

    }
    catch (error) {
      console.error(error)
      dispatch(setFormError(error.message));
      // dispatch(toast(formTexts.LOADING_ERROR, 'error'))
    }
  }
}

export const createFormAction = (studyId, newForm) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await FormsService.postForm(studyId, newForm);

      dispatch(createForm({ data: data.data.item, allLocales: getState().locales.data }))
      dispatch(toast("Form created"))
    }
    catch (error) {
      console.error(error)
      dispatch(setFormError(error.message));
      dispatch(toast("Unable to create form", "error"))
    }
  }
}

export const updateFormAction = (studyId, updatedForm) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await FormsService.putForm(studyId, updatedForm);

      dispatch(updateForm({ data: data.data.item, allLocales: getState().locales.data }))
      dispatch(toast("Form updated"))
    }
    catch (error) {
      console.error(error)
      dispatch(setFormError(error.message));
      dispatch(toast("Unable to update form", "error"))
    }
  }
}

export const createQuestion = (questionObj, image, isSummaryPage) => {
  return async (dispatch) => {
    try {
      const { data } = await QuestionsService.postQuestion(questionObj)

      const questionId = data.data.item.id

      let questionToDispatch = { ...data.data.item }

      if (image) {
        const { data: imageData } = await QuestionsService.postImage(questionId, image)
        questionToDispatch = { ...questionToDispatch, url_img: imageData.data.value }
      }

      dispatch(appendQuestion({ data: questionToDispatch, isSummaryPage }))
      dispatch(toast(SnackBarResponses.QUESTION_SAVED, 'success'))
    } catch (error) {
      console.error(error)
      dispatch(setFormError(error.message))
      dispatch(toast("An error happened during the creation of the question. Please try again.", 'error'))
    }
  }
}

export const updateQuestionAction = (id, questionObj, image, isSummaryPage) => {
  return async (dispatch) => {
    try {
      const { data } = await QuestionsService.putQuestion(id, questionObj)

      let questionToDispatch = { ...data.data.item }

      // * If the image is a string, it means that the user didn't changed it
      if (image && typeof image !== 'string') {
        const { data: imageData } = await QuestionsService.postImage(id, image)
        questionToDispatch = { ...questionToDispatch, url_img: imageData.data.value }
      }

      dispatch(updateQuestion({ data: questionToDispatch, isSummaryPage }))
      dispatch(toast(SnackBarResponses.QUESTION_UPDATED, 'success'))
    } catch (error) {
      console.error(error)
      dispatch(setFormError(error.message))
      dispatch(toast("An error happened while editing the question. Please try again.", 'error'))
    }
  }
}

export const deleteQuestionAction = (sectionId, questionId, isSummaryPage) => {
  return async (dispatch, getState) => {
    try {
      if (!isSummaryPage) {
        await QuestionsService.deleteQuestionSection(sectionId, questionId);
        dispatch(deleteQuestion({ sectionId, questionId }))
      }
      else {
        await SummaryService.deleteQuestionSummary(sectionId, questionId)
        dispatch(deleteQuestion({ sectionId, questionId }))

        // ! Seems like BE is not reordering questions after deletion, so we do it here
        const summaryState = getState().form.data[0]

        const mappedQuestions = {
          questions: summaryState.questions.map(question => {
            return {
              questionId: question.id,
              order: question.order
            }
          })
        }

        if (mappedQuestions.questions.length > 0) {
          await SummaryService.changeOrder(summaryState.formId, mappedQuestions)
        }
      }

      dispatch(toast(SnackBarResponses.QUESTION_DELETED, 'success'))
    } catch (error) {
      console.error(error)
      dispatch(setFormError(error.message))
      dispatch(toast("Error while deleting the question. Please try again.", 'error'))
    }
  }
}

export const deleteQuestionsBatchAction = (sectionId, selectedQuestionsIds, isSummaryPage) => {
  return async (dispatch, getState) => {
    try {
      for (let questionId of selectedQuestionsIds) {
        if (!isSummaryPage) {
          await QuestionsService.deleteQuestionSection(sectionId, questionId);
        }
        else {
          await SummaryService.deleteQuestionSummary(sectionId, questionId)
        }
        dispatch(deleteQuestion({ sectionId, questionId, isSummaryPage }))
      }

      if (isSummaryPage) {
        // ! Seems like BE is not reordering questions after deletion, so we do it here
        const summaryState = getState().form.data[0]

        const mappedQuestions = {
          questions: summaryState.questions.map(question => {
            return {
              questionId: question.id,
              order: question.order
            }
          })
        }

        if (mappedQuestions.questions.length > 0) {
          await SummaryService.changeOrder(summaryState.formId, mappedQuestions)
        }
      }

      dispatch(toast("All the selected questions have been deleted", 'success'))
    } catch (error) {
      console.error(error)
      dispatch(setFormError(error.message))
      dispatch(toast("Error while deleting a question. Please try again.", 'error'))
    }
  }
}

export const duplicateQuestionAction = (questionId, sectionIndex) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await QuestionsService.duplicateQuestion(questionId)
      const questionToDuplicate = getState().form.data[sectionIndex].questions.find(question => question.id === questionId)

      const addedMissingValuesToRequestData = {
        ...data.data.item,
        type: questionToDuplicate.type,
        answer_values: questionToDuplicate.choiceQuestions,
        question_translations: questionToDuplicate.questionTranslations
      }

      dispatch(appendQuestion({ data: addedMissingValuesToRequestData }))
      dispatch(toast(SnackBarResponses.QUESTION_DUPLICATED, 'success'))
    } catch (error) {
      console.error(error)
      dispatch(setFormError(error.message))
      dispatch(toast("An error happened while duplicating the question. Please try again.", 'error'))
    }
  }
}

export const changeQuestionMandatory = (id, selectedSectionIndex, mandatoryValue, isSummaryPage) => {
  return async (dispatch, getState) => {
    try {
      const questionToUpdate = getState().form.data[selectedSectionIndex].questions.find(question =>
        question.id === id)

      const defaultLocale = getState().form.data[selectedSectionIndex].defaultLocale

      const singleOrMultipleChoiceAnswers = questionToUpdate.choiceQuestions?.length > 0
        ? questionToUpdate.choiceQuestions.map(answer => {
          return {
            answerTitle: findTranslation(answer?.translations, defaultLocale.value)?.text, // * Use default answer translation
            answerValue: answer.value,
            answerTranslations: answer.translations.map(translation => { // * Send all answer translations
              return {
                locale: translation.locale,
                text: translation.text
              }
            })
          }
        })
        : []

      const questionBody = {
        order: questionToUpdate.order,
        summary: questionToUpdate.summary,
        variableName: questionToUpdate.variableName,
        type: questionToUpdate.type,
        mandatory: mandatoryValue,
        answerOptions: singleOrMultipleChoiceAnswers,
        questionTranslation: questionToUpdate.questionTranslations
      };

      const { data } = await QuestionsService.putQuestion(id, questionBody)

      dispatch(updateQuestion({ data: data.data.item, isSummaryPage }))
      dispatch(toast(SnackBarResponses.QUESTION_CHANGE_MANDATORY, 'success'))
    } catch (error) {
      console.error(error)
      dispatch(setFormError(error.message))
      dispatch(toast("An error happened while editing the question. Please try again.", 'error'))
    }
  }
}

export const changeQuestionsOrderAction = (formId, questions, onDragEnd, isSummaryPage) => {
  return async (dispatch) => {
    try {
      const { source, destination } = onDragEnd;

      // Check if the destination is valid
      if (!destination) {
        return;
      }

      // Get the start and end index of the dragged question
      const startIndex = source.index;
      const endIndex = destination.index;

      if (startIndex === endIndex) return

      // Create a new array with the updated questions
      const updatedQuestions = questions.map((question, index) => {
        if (index === startIndex) {
          // Set the new order value for the dragged question
          return { ...question, order: endIndex + 1 };
        } else if (startIndex < endIndex && index > startIndex && index <= endIndex) {
          // Moving the question down the list
          return { ...question, order: question.order - 1 };
        } else if (startIndex > endIndex && index >= endIndex && index < startIndex) {
          // Moving the question up the list
          return { ...question, order: question.order + 1 };
        }
        return question;
      });

      const reorderedQuestions = [...updatedQuestions].sort((a, b) => a.order - b.order);

      const mappedQuestions = {
        questions: reorderedQuestions.map(question => {
          return {
            questionId: question.id,
            order: question.order
          }
        })
      }

      if (!isSummaryPage && mappedQuestions.questions.length > 0) {
        await QuestionsService.changeOrder(formId, mappedQuestions)
      }
      else if (isSummaryPage && mappedQuestions.questions.length > 0) {
        await SummaryService.changeOrder(formId, mappedQuestions)
      }
      else {
        throw new Error("Unable to change order in the server")
      }

      dispatch(changeQuestionsOrder({ data: reorderedQuestions, isSummaryPage }))
    } catch (error) {
      console.error(error)
      dispatch(setFormError(error.message));
      dispatch(toast(SnackBarResponses.CHANGE_ORDER, 'error'))
    }
  }
}

export const changeSelectedLocaleAction = (selectedLocale) => {
  return (dispatch) => {
    dispatch(changeSelectedLocale(selectedLocale))
  }
}