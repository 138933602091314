import { createSlice } from '@reduxjs/toolkit'
import LocalesService from '../services/LocalesService'
import { toast } from './notificationsReducer'
import { dashboardTexts } from '../locale/en'

const localesSlice = createSlice({
  name: 'locales',
  initialState: { data: null, loading: false, error: null },
  reducers: {
    setAllLocales(state, action) {
      const { data } = action.payload

      const mappedLocales = Object.values(data)?.map(locale => {
        return {
          id: locale.id,
          name: locale.name,
          value: locale.locale
        }
      }).sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

      return { ...state, data: mappedLocales, loading: false, error: null }
    },
    setLocalesLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setLocalesError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
})

export const { setAllLocales, setLocalesLoading, setLocalesError } =
  localesSlice.actions

export const initializeLocales = () => {
  return async (dispatch) => {
    try {
      dispatch(setLocalesLoading());

      const { data } = await LocalesService.getAll()

      dispatch(setAllLocales({ data: data.data.items }))
    }
    catch (error) {
      console.error(error)
      dispatch(setLocalesError({ message: error.message, status: error.response?.status }));
      dispatch(toast(dashboardTexts.STUDY_LOADING_ERROR, 'error'))
    }
  }
}

export default localesSlice.reducer