import { Switch } from "@mui/material"
import { useDispatch } from "react-redux"
import { questionTypes, QUESTION_INFO } from "resources/QuestionConstants"
import { makeStyles } from "@mui/styles";
import { AppColors } from "resources/AppColors";
import { changeQuestionMandatory } from "reducers/Form/formActions";

const useStyles = makeStyles(() => ({
  // eslint-disable-next-line camelcase
  switch_track: {
    backgroundColor: AppColors.SECONDARY,
  },
  // eslint-disable-next-line camelcase
  switch_base: {
    color: AppColors.SECONDARY,
    "&.Mui-disabled": {
      color: AppColors.PRIMARY_WITH_OPACITY
    },
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    }
  },
  // eslint-disable-next-line camelcase
  switch_primary: {
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    },
  },
}));

export function MandatorySwitch({
  isMandatory,
  questionId,
  questionType,
  selectedSectionIndex,
  isSummaryPage,
  ...props
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  function handleChangeMandatory() {
    dispatch(changeQuestionMandatory(questionId, selectedSectionIndex, !isMandatory, isSummaryPage))
  }

  return (
    <Switch
      checked={isMandatory}
      onClick={handleChangeMandatory}
      color="primary"
      disabled={questionType === questionTypes[QUESTION_INFO].name}
      classes={{
        track: classes.switch_track,
        switchBase: classes.switch_base,
        colorPrimary: classes.switch_primary
      }}
      {...props}
    />
  )
}
