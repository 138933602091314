import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ListItemIcon } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleChoiceCheckboxesDropdown({ label, value, handleValue, items, defaultLocale, ...other }) {
  const isAllSelected = items.length > 0 && value.length === items.length;

  return (
    <>
      <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox"
        multiple
        value={value}
        onChange={handleValue}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.map(item => item.name).join(', ')}
        MenuProps={MenuProps}
        {...other}
      >
        <MenuItem value='all'>
          <ListItemIcon>
            <Checkbox
              color={isAllSelected ? "error" : "default"}
              checked={isAllSelected}
              indeterminate={
                value.length > 0 &&
                value.length < items.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary="Select All"
          />        </MenuItem>
        {items.map((item) => {
          if (defaultLocale === item.value) {
            return (
              <MenuItem disabled key={item.value} value={item} data-testId={item.testId}>
                <Checkbox disabled color="error" checked={value.some(selectedValue => selectedValue.value === item.value)} />
                <ListItemText primary={item.name} />
              </MenuItem>
            )
          }

          return (
            <MenuItem key={item.value} value={item} data-testId={item.testId}>
              <Checkbox color="error" checked={value.some(selectedValue => selectedValue.value === item.value)} />
              <ListItemText primary={item.name} />
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}