import { BsPlusCircle, BsPlusCircleFill } from "react-icons/bs";
import { BiDotsHorizontalRounded, BiDotsVerticalRounded } from "react-icons/bi";
import PublicIcon from "@mui/icons-material/Public";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ListIcon from "@mui/icons-material/List";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

const Icons = {
  "ADD": BsPlusCircle,
  "ADD_FILLED": BsPlusCircleFill,
  "PUBLIC": PublicIcon,
  "PARTICIPANTS": PeopleAltOutlinedIcon,
  "MORE": MoreHorizIcon,
  "RESPONSES": IndeterminateCheckBoxRoundedIcon,
  "PRIVATE": LockOutlinedIcon,
  "OPTIONS": BiDotsVerticalRounded,
  "OPTIONS_HORIZONTAL": BiDotsHorizontalRounded,
  "VERIFIED": VerifiedUserOutlinedIcon,
  "INFO": InfoOutlinedIcon,
  "ARROW_DOWN": KeyboardArrowDownIcon,
  "ARCHIVE": ArchiveOutlinedIcon,
  "UNARCHIVE": UnarchiveOutlinedIcon,
  "DELETE": DeleteOutlineIcon,
  "MOVE": MoveToInboxOutlinedIcon,
  "ARROW_LEFT": KeyboardArrowLeftIcon,
  "LIST_ICON": ListIcon,
  "CARD_VIEW": ViewModuleIcon,

}

export default Icons
