import React from "react"
import { TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
        backgroundColor: AppColors.BACKGROUND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },

}));
/**
 * @component
 * Component to create textFields
 *
 * @param {object} value: value of the user will be entry
 * @param {string} name: identifier of the textfield
 * @param {function} handleChange: handle to change the values
 * @param {string} label: value to show what need to fill the user
 * @param {boolean} error: value that will be true when the component is empty
 * @param {string} helperText: string that help the user when the component is empty
 * @param {object} inputProps: style of the textfield
 * @param {boolean} isDisabled: value to disable the textield
 * @param {number} maxLength: number of maximum of text that the user can fill
 * @param {boolean} isNumeric: value to know if the textfield is numeric or not
 *
 * @constructor
 * <TextfieldUhda  isDisabled={true} name="form" handleChange={handleInputChange} label={"Form"} value={values.form} />
 *
 */
const TextfieldUhda = ({
  value,
  name,
  handleChange,
  label,
  error = false,
  helperText,
  inputProps,
  isDisabled,
  maxLength,
  isNumeric,
  isPassword,
  className,
  shrink
}) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      name={name}
      onChange={handleChange}
      type={isNumeric ? "number" : isPassword ? "password" : "text"}
      label={label}
      defaultValue={value}
      variant="outlined"
      error={error}
      margin="normal"
      helperText={helperText}
      InputProps={inputProps}
      disabled={isDisabled}
      InputLabelProps={{
        shrink: shrink,
      }}
      inputProps={{ className: className, maxLength: maxLength ? maxLength : null }}
    />
  )
}

TextfieldUhda.propTypes = {
  value: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number,
  isNumeric: PropTypes.bool
}

export default TextfieldUhda;
