/* eslint-disable no-empty */
/* eslint-disable no-unused-vars*/
/* eslint-disable no-shadow*/
import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, CircularProgress, Container, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import DialogUhda from "../DialogUhda";
import { CommonTexts, LabelsStudy, SnackBarResponses, TableTexts } from "../../locale/en";
import { AppColors } from "../../resources/AppColors";
import { textType } from "../../resources/AppTexts";
import TextUhda from "../TextUdha";
import ButtonFilledUhda from "../ButtonFilledUhda";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import "../../styles/studiesCard.scss";
import StudyListViewUhda from "../ListViewUhda/StudyListViewUhda";
import { StorageManager } from "../../utils";
import CheckBoxStudyUhda from "../CheckBoxUhda/CheckBoxStudyUhda";
import PropTypes from "prop-types";
import { SearchBar } from "../SearchBar/SearchBar";
import { useDispatch } from "react-redux";
import { toast } from "../../reducers/notificationsReducer";
import { GridCard } from "../GridCard/GridCard";
import { ButtonToggle } from "components/ButtonToggle/ButtonToggle";

function DeleteStudiesModal({
  setShowDeleteQuestions,
  showDeleteQuestions,
  selected,
  handleDeleteQuestions,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteQuestions}
      handleConfirm={() => {
        handleDeleteQuestions();
      }}
      title={"Delete survey folder(s)"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {selected.length > 1 ? LabelsStudy.TITLE_MODALS_DELETE : LabelsStudy.TEXT_MODAL_DELETE1}
          </Typography>
          {selected.length > 1 ?
            <List>
              {selected.map((study) => (
                <ListItem key={study.id} disablePadding>
                  <ListItemText
                    primary={<TextUhda margin={0} type={textType.BODY} text={study.translations[0]?.study_title}></TextUhda>}
                  />
                </ListItem>
              ))}
            </List> :
            <TextUhda type={textType.BODY} text={selected[0]?.translations[0]?.study_title} />}

          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {selected.length > 1 ? LabelsStudy.TEXT_MODALS_DELETE2 : LabelsStudy.TEXT_MODAL_DELETE2}
          </Typography>
        </>

      }
      show={showDeleteQuestions}
    />
  );
}

DeleteStudiesModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

/**
 * @component
 * Component to show all the studies of a user + the option to create a new study
 *
 * @param {object} studies: JSON with all the data available of the study
 *
 * @example
 * const study = {your JSON studies data}
 * <GridStudiesUhda study={study}/>
 */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)

  }
}))

const GridStudiesUhda = ({ studies }) => {

  const history = useHistory()
  //const texts = useTextStyles();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState([false, -1]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentStudy, setCurrentStudy] = useState(null);
  const [currentTitleStudy, setCurrentTitleStudy] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [viewActive, setViewActive] = useState("list");
  const storageManager = new StorageManager()
  const isAdmin = storageManager.getAdmin()
  const roles = storageManager.getRoles()
  const permissions = storageManager.getPermissions()
  // eslint-disable-next-line no-unused-vars
  const [labels, setLabels] = useState();
  const [stateSelect, setStateSelect] = useState(false)
  const [selectedStudies, setSelectedStudies] = React.useState();
  const [showDeleteStudies, setShowDeleteStudies] = React.useState();

  const [study, setStudy] = useState(studies);
  const [searched, setSearched] = useState();

  const dispatch = useDispatch()

  const getStudies = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      const mappedStudies = response.data.data.map(study => {
        return {
          ...study,
          title: study.form_translations[0]?.study_title,
          tags: study.tags.reduce((tags, currentTag, i) => {
            if (i === 0 && currentTag.name) {
              tags = currentTag.name
              return tags
            }
            tags = `${tags} / ${currentTag.name}`
            return tags
          }, "")
        }
      });
      mappedStudies.reverse()
      setStudy(mappedStudies)
      setLoading(false)

    } catch (err) {
      console.error(err)
    }
  }

  const moveToNextSection = () => {
    localStorage.setItem("route", "create")

    const permissions = [
      "Dashboard",
      "Forms",
      "Participants",
      "Dissemination",
      "Team Members",
      "Settings"
    ]
    const newObj = { "detail": null, "value": 5, "permissions": permissions }
    localStorage.setItem("createStudy", JSON.stringify(newObj));

  }

  useEffect(() => {
    moveToNextSection()

  }, []);

  const handleDeleteStudies = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < selectedStudies.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const response = await MyAxiosInstance.delete(`${BASE_PATH}/study/${selectedStudies[i].id}`);
      }
      getStudies()
      dispatch(toast(SnackBarResponses.STUDY_DELETED, "success"))
      setStateSelect(false)
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    setShowDeleteStudies(false);
  };

  const handleDeleteSelect = () => {
    if (isAdmin == 1 || study.permissions.includes("create-survey-folder")) {
      if (selectedStudies !== undefined && selectedStudies.length !== 0) {
        setShowDeleteStudies(true)
      } else {
        dispatch(toast(SnackBarResponses.SELECTED_QUESTION, "warning"))
      }
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }
  }
  //Function to close the snackbar
  // eslint-disable-next-line no-shadow
  const knowPermissions = (study) => {
    const label = [];
    if (isAdmin == 1 || study.permissions.includes("access-data")) {
      label.push(LabelsStudy.DASHBOARD)
      //link.push(`/study/${studyId}/dashboard`)
    }
    if (isAdmin == 1 || study.permissions.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
      // link.push(`/study/${studyId}/forms`)
    }
    if (isAdmin == 1 || study.permissions.includes("participant-create") || study.permissions.includes("participant-edit") || study.permissions.includes("participant-delete")) {
      label.push(LabelsStudy.PARTICIPANTS)
      // link.push(`/study/${studyId}/participants`)
    }
    if (isAdmin == 1 || study.permissions.includes("access-dissemination")) {
      label.push(LabelsStudy.DISSEMINATION)
      //link.push(`/study/${studyId}/dissemination`)
    }
    if (isAdmin == 1 || study.permissions.includes("teammember-create") || study.permissions.includes("teammember-edit") || study.permissions.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
      //link.push(`/study/${studyId}/teamMembers`)
    }
    if (isAdmin == 1 || study.permissions.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
      //link.push(`/study/${studyId}/settings`)
    }
    setLabels(label)
    return label
  };

  // eslint-disable-next-line no-shadow
  const handleOnEdit = study => {
    localStorage.setItem("route", "edit")

    const id = study.id
    var label = knowPermissions(study)
    const newObj = { "detail": id, "value": 5, "permissions": label, "editSection": true }
    localStorage.setItem("sectionDetails", JSON.stringify(newObj));

    history.push({
      pathname: `/study/${id}/settings`, //TODO poner los permissions del study en concreto
      state: { detail: id, value: 5, permissions: label }

    })
  }

  // eslint-disable-next-line no-shadow
  const handleOnDuplicate = study => {
    // TODO: Duplicate Study
    if (isAdmin == 1 || study.permissions.includes("study-duplicate")) {
      setCurrentTitleStudy(study.translations[0]?.study_title)
      setCurrentStudy(study)
      setShowDuplicateModal(!showDuplicateModal)
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }

  }

  const handleOnConfirmDuplicate = async (e) => {
    // TODO: Duplicate Study
    const id = currentStudy.id
    e.preventDefault()
    try {
      setLoading(true)
      await MyAxiosInstance.post(`${BASE_PATH}/study/${id}/duplicate`);

      // eslint-disable-next-line no-empty
    } catch (error) {
      dispatch(toast(SnackBarResponses.NETWORK_ERROR, "error"))
    }
    // TODO: Delete Study
    setShowDuplicateModal(!showDuplicateModal)
    dispatch(toast(SnackBarResponses.STUDY_DUPLICATE, "success"))
    setLoading(false)

    getStudies()

  }
  // eslint-disable-next-line no-shadow
  const handleOnDelete = study => {
    if (isAdmin == 1 || study.permissions.includes("study-delete")) {
      setCurrentTitleStudy(study.translations[0]?.study_title)
      setCurrentStudy(study)
      setShowDeleteModal(!showDeleteModal)
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }

  }

  const handleOnConfirmDelete = async (e) => {
    const id = currentStudy.id
    e.preventDefault()
    try {
      setLoading(true)

      await MyAxiosInstance.delete(`${BASE_PATH}/study/${id}`);

    } catch (error) {

    }
    setShowDeleteModal(!showDeleteModal)
    dispatch(toast(SnackBarResponses.STUDY_DELETED, "success"))
    setLoading(false)

    getStudies()
  }

  const headCells = [
    { id: "", label: "" },
    /*  { id: "Id", label: TableTexts.ID }, */
    { id: "study_title", label: TableTexts.STUDY_TITLE, isSortable: true },
    { id: "studyDescription", label: TableTexts.STUDY_DESCRIPTION, isSortable: false },
    { id: "tags", label: TableTexts.TAGS, isSortable: true },
    { id: "created_by", label: TableTexts.STUDY_CREATED_BY, isSortable: true },
    { id: "created_at", label: TableTexts.STUDY_CREATED, isSortable: true },
  ];

  return (
    <Container className="studies-grid">
      <div className={classes.root}>
        <Grid data-testId={"newStudy"} container justifyContent="space-between" item style={{ paddingTop: "1em", marginBottom: viewActive == "card" ? "2em" : "2.5em", marginLeft: "0.7em" }}>
          <Grid item xs={stateSelect ? "6" : "8"}>
            <ButtonGroup color="primary">
              <ButtonToggle key={"list"} active={viewActive === "list"}
                onClick={() => (setViewActive("list"))}>
                <IconProviderUhda icon={<Icons.LIST_ICON color={AppColors.PRIMARY} size="2rem" />} />
              </ButtonToggle>
              <ButtonToggle key={"card"} data-testId={"cardViewButton"} active={viewActive === "card"}
                onClick={() => (setViewActive("card"))}>
                <IconProviderUhda icon={<Icons.CARD_VIEW color={AppColors.PRIMARY} size="2rem" />} />
              </ButtonToggle>
            </ButtonGroup>
          </Grid>
          {((roles.includes("Super Admin") || permissions.includes("create-survey-folder") || isAdmin == 1) && stateSelect && viewActive == "list") && <Grid item style={{ paddingRight: "2em" }}>
            <ButtonFilledUhda margin={"10px"} type={textType.SUBTITLE_BOLD}
              onClick={handleDeleteSelect}
              text={CommonTexts.DELETE} />
          </Grid>}
          {((roles.includes("Super Admin") || permissions.includes("create-survey-folder") || isAdmin == 1) && viewActive == "list") && <Grid item style={{ paddingRight: "2em" }}>
            <ButtonFilledUhda margin={"10px"} type={textType.SUBTITLE_BOLD} backGroundColor={AppColors.GREY}
              onClick={() => setStateSelect(!stateSelect)}
              text={stateSelect ? CommonTexts.CANCEL : CommonTexts.SELECT} />
          </Grid>}
          {(roles.includes("Super Admin") || permissions.includes("create-survey-folder") || isAdmin == 1) && <Grid item style={{ paddingRight: "2em" }}>
            <ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
              onClick={() => history.push({
                pathname: "/study", state: {
                  detail: null,

                  value: 5, permissions: [
                    "Dashboard",
                    "Forms",
                    "Participants",
                    "Dissemination",
                    "Team Members",
                    "Settings"
                  ]
                }
              })}
              icon={<IconProviderUhda
                icon={<Icons.ADD size={"1.5em"} color={AppColors.WHITE} />} />}
              text={CommonTexts.NEW_STUDY} />
          </Grid>}
        </Grid>

        {viewActive == "card" && <Grid Grid item style={{ marginBottom: "2em" }}>
          {
            <Box sx={{ marginBottom: "2em" }}>
              <SearchBar
                entities={studies}
                setFilteredValues={setStudy}
                placeholder={"Search by title"}
                searchKey={"title"}
              />
            </Box>
          }
        </Grid>}
        <Grid
          data-testId={"surveys-container"}
          align="center"
          container
          spacing={2}
          direction="row"
          alignItems={"center"}
          justifyContent="flex-start">
          {showDuplicateModal && (
            <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DUPLICATE1}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleStudy} `} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DUPLICATE2} `} />
                  {
                    loading &&
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              }
              buttonColor={AppColors.PRIMARY}
              title={LabelsStudy.TITLE_MODAL_DUPLICATE}
              show={showDuplicateModal}
              handleShow={setShowDuplicateModal}
              handleConfirm={handleOnConfirmDuplicate}
              textConfirmButton={LabelsStudy.CONFIRM_MODAL_DUPLICATE}
              disabled={loading} />
          )}
          {showDeleteModal && (
            <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DELETE1} `} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleStudy} `} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DELETE2} `} />

                </Grid>}
              buttonColor={AppColors.RED}
              title={LabelsStudy.TEXT_TITLE_DELETE}
              show={showDeleteModal}
              handleShow={setShowDeleteModal}
              handleConfirm={handleOnConfirmDelete}
              textConfirmButton={LabelsStudy.CONFIRM_MODAL_DELETE} />
          )}
          {viewActive == "card" ?
            <Box mt={2} display="flex" justifyContent="space-evenly" flexWrap="wrap">
              {study.map(elem => (
                <Box p={2}>
                  <GridCard
                    key={elem.id}
                    studyId={elem.id}
                    studyTitle={elem.translations[0]?.study_title}
                    studyDescription={elem.translations[0]?.short_description}
                    handleOnEdit={() => handleOnEdit(elem)}
                    handleOnDuplicate={() => {
                      handleOnDuplicate(elem)
                    }}
                    handleOnDelete={() => {
                      handleOnDelete(elem)
                    }}
                    permissions={elem.permissions}
                  />
                </Box>
              ))}
            </Box>
            :
            <Box sx={{ p: 3 }} style={{ paddingTop: 0, paddingLeft: 0, width: "73.2em" }}>
              {!loading && study && !stateSelect &&
                <StudyListViewUhda handleOnEdit={(id) => handleOnEdit(id)}
                  handleOnDelete={(id) => handleOnDelete(id)}
                  handleOnDuplicate={(id) => handleOnDuplicate(id)}
                  setLoading={setLoading}
                  loading={loading}
                  headCells={headCells}
                  userData={study}
                />}
              {!loading && study && stateSelect && <CheckBoxStudyUhda
                showDeleteStudies={showDeleteStudies}
                setLoading={setLoading}
                loading={loading}
                headCells={headCells}
                userData={study} selectedStudies={selectedStudies}
                setSelectedStudies={setSelectedStudies}
              />}
            </Box>
          }
          {
            showDeleteStudies === true && (
              <DeleteStudiesModal
                setShowDeleteQuestions={setShowDeleteStudies}
                showDeleteQuestions={showDeleteStudies}
                selected={selectedStudies}
                handleDeleteQuestions={handleDeleteStudies}
              />
            )
          }
        </Grid>
      </div>
    </Container >
  );
}

export default GridStudiesUhda;
