import React, { useEffect, useState } from "react";
import { Divider, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { AppColors } from "../../resources/AppColors";
import { LabelsStudy } from "../../locale/en";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx"
import { useTextStyles } from "../../resources/AppTexts";
import { StorageManager } from "../../utils";

const drawerWidth = 150;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: AppColors.BACKGROUND,
    display: "flex",
  },
  content: {
    backgroundColor: AppColors.BACKGROUND,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  drawerHeader: {

    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {

    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  iconRoot: {
    textAlign: "center"
  },
  imageIcon: {
    height: "100%"
  },

  hide: {
    display: "none",
  },
  drawerOpen: {
    marginTop: "64px",
    marginLeft: "240px",

    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,

    }),

    [theme.breakpoints.down('md')]: {
      marginLeft: "70px",
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: "57px",
    },
  },
  drawerClose: {
    marginTop: "64px",
    marginLeft: "70px",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20) + 1,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: "55px",
    },
  },

}));

/**
 * @component
 * Component to create the drawer when you are on a study
 *
 * @param id: string to know what study it is
 * @param drawer: boolean to set the drawer open or not
 * @param select: index to know what item on the drawer is selected
 * @constructor
 *
 * <DrawerUhda id={25} drawer={true} select={0} />
 */
const DrawerUhda = ({ id, drawer, drawerStudy, select, settings, newStudy }) => {
  // eslint-disable-next-line no-unused-vars
  const drawerLinkList = [`/study/${id}/dashboard`, `/study/${id}/forms`, `/study/${id}/participants`, `/study/${id}/dissemination`, `/study/${id}/teamMembers`, `/study/${id}/settings`]
  const texts = useTextStyles();
  const [permissions, setPermissions] = useState()
  const [drawerLink, setDrawerLink] = useState()
  const storageManager = new StorageManager()
  const isAdmin = storageManager.getAdmin()

  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(drawer);//false
  // eslint-disable-next-line no-unused-vars
  const [openStudy, setOpenStudy] = useState(drawerStudy);//false
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState(select);

  //Function to close the snackbar
  const knowPermissions = () => {
    const label = [];
    const link = [];
    if (isAdmin == 1 || settings.includes("access-data")) {

      label.push(LabelsStudy.DASHBOARD)
      link.push(`/study/${id}/dashboard`)
    }
    if (isAdmin == 1 || settings.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
      link.push(`/study/${id}/forms`)

    }
    if (isAdmin == 1 || settings.includes("teammember-create") || settings.includes("teammember-edit") || settings.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
      link.push(`/study/${id}/teamMembers`)
    }
    if (isAdmin == 1 || settings.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
      link.push(`/study/${id}/settings`)
    }
    setPermissions(label)

    setDrawerLink(link)

  };
  useEffect(() => {
    setOpenStudy(drawerStudy)
    setOpen(drawer)
  }, [drawer, drawerStudy])
  useEffect(() => {
    knowPermissions()

  }, [])
  const drawerList = [LabelsStudy.DASHBOARD, LabelsStudy.FORMS, LabelsStudy.TEAM_MEMBERS]
  return (
    <>

      {drawerStudy && <Drawer
        anchor="left"
        open={drawer}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        style={{ marginTop: 0 }}
      >

        <Divider />
        {!newStudy && permissions && drawerLink && <List>
          {permissions.map((text, index) => (
            // eslint-disable-next-line react/jsx-key
            <Link to={{
              pathname: drawerLink[index],
              state: { detail: id, drawer: drawer, permissions: permissions }
            }}
              style={{ color: AppColors.WHITE, textDecoration: "none" }}>
              <ListItem button key={text} selected={selected === index} style={{
                backgroundColor: selected === index && AppColors.CBM_SAND_OPACITY,
              }}>
                <ListItemText data-testId={text} selected={selected === index} classes={{ primary: texts.subtitle_bold }}
                  primary={text}
                  style={{ color: selected === index ? AppColors.PRIMARY : AppColors.BLACK, }} />
              </ListItem>
            </Link>
          ))}
        </List>}

        {newStudy && permissions && drawerLink && <List>
          {drawerList.map((text, index) => (

            <ListItem disabled button key={text} selected={selected === index} style={{
              backgroundColor: selected === index && AppColors.PIRMARY_WITH_OPACITY,
            }}>
              <ListItemText selected={selected === index} classes={{ primary: texts.subtitle_bold }}
                primary={text}
                style={{ color: selected === index ? AppColors.CBM_SAND : AppColors.PRIMARY, }} />
            </ListItem>
          ))}
          <ListItem key={"Settings"} selected={5} style={{
            backgroundColor: 5 && AppColors.PIRMARY_WITH_OPACITY,
          }}>
            <ListItemText selected={5} classes={{ primary: texts.subtitle_bold }} primary={"Settings"}
              style={{ color: AppColors.CBM_SAND }} />
          </ListItem>
        </List>}

      </Drawer>}
    </>
  )
}

DrawerUhda.propTypes = {}

export default DrawerUhda;
