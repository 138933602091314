/* eslint-disable no-unused-vars*/
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */

import React, { useEffect, useState } from "react";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import { patientsMock } from "../mocks/patientsMock"
import { CircularProgress, FormControl, Grid, InputAdornment, List, ListItem, ListItemText } from "@mui/material"
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types"
import DialogUhda from "../components/DialogUhda/DialogUhda"
import moment from "moment"
import { AppColors } from "../resources/AppColors"
import ErrorIcon from "@mui/icons-material/Error"
import { validateEmail } from "../utils/HelperFunctions"
import { CommonTexts, SnackBarResponses, TableTexts } from "../locale/en";
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import ParticipantsService from "../services/ParticipantsService";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { translationsMock } from "../mocks/translationsMock";
import { tagsMock } from "../mocks/tagsMock";
import TagsService from "../services/TagsService";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

function AddModal({
    selectedDate,
    setSelectedDate,
    handleAddParticipant,
    handleAddParticipantContinue,
    initialValues,
    values,
    setValues,
    showAddModal,
    setShowAddModal,
    error
}) {
    const [showError, setShowError] = useState(false);

    const [showNameError, setShowNameError] = useState(false)
    const [showDescriptionError, setShowDescriptionError] = useState(false)

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    /**
     * If the name or description is empty, show an error. If not, add the participant
     */
    const handleClickSubmit = async () => {
        if (values.name == "") {
            setShowNameError(true)
        } else if (values.description == "") {
            setShowDescriptionError(true)
        } else {
            handleAddParticipant()
        }
    }

    /**
     * If the description field is empty, show an error message. Otherwise, add the participant to the
     * database and continue to the next page
     */
    const handleClickSubmitContinue = async () => {
        if (values.description == "") {
            setShowError(true)

        } else {
            setShowError(false)
            handleAddParticipantContinue()
        }
    }

    useEffect(() => {
        setValues(initialValues)
    }, [showAddModal])

    return (
        <DialogUhda
            textCancelButton={CommonTexts.CANCEL}
            textConfirmButton={CommonTexts.SAVE}
            textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
            handleShow={setShowAddModal}
            handleConfirm={handleClickSubmit}
            handleConfirmAndContinue={handleClickSubmitContinue}

            values={values}
            setValues={setValues}
            title={TableTexts.ADD_TAG_TITLE}
            body={
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FormControl

                        margin='normal'
                        style={{ width: "100%" }}
                        data-testId={"tagName"}>
                        <TextfieldUhda
                            name='name'
                            handleChange={handleInputChange}
                            label={TableTexts.TAG_NAME}
                            value={values.name}
                            helperText={showNameError ? SnackBarResponses.NAME_REQUIRED : (error && `${error}`)}
                            inputProps={{
                                endAdornment: showNameError && <InputAdornment position="end"><ErrorIcon
                                    style={{ color: AppColors.RED }} /></InputAdornment>,
                            }}
                        />
                    </FormControl>
                    <FormControl

                        margin='normal'
                        style={{ width: "100%" }}
                        data-testId={"tagDescription"}>
                        <TextfieldUhda
                            name='description'
                            handleChange={handleInputChange}
                            label={TableTexts.TAG_DESCRIPTION}
                            value={values.description}
                            error={(values.description === "" && showDescriptionError)}
                            helperText={showDescriptionError && SnackBarResponses.DESCRIPTION_REQUIRED}
                            inputProps={{
                                endAdornment: showDescriptionError && <InputAdornment position="end"><ErrorIcon
                                    style={{ color: AppColors.RED }} /></InputAdornment>,
                            }}
                        />
                    </FormControl>
                </Grid>
            }
            show={showAddModal}
        />
    );
}

AddModal.propTypes = {
    setSelectedData: PropTypes.func.isRequired,
    handleAddParticipant: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    setShowAddModal: PropTypes.func.isRequired
}

function DeleteModal({ handleDeleteParticipant, setShowDeleteModal, showDeleteModal, selected }) {
    useEffect(() => {

    }, [showDeleteModal])
    return (
        <DialogUhda
            textCancelButton={CommonTexts.CANCEL}
            textConfirmButton={TableTexts.DELETE_CONFIRM}
            handleShow={setShowDeleteModal}
            buttonColor={AppColors.RED}
            handleConfirm={() => {
                handleDeleteParticipant();

            }}
            title={
                showDeleteModal === true ?
                    selected.length > 1 ? TableTexts.DELETE_TAGS : TableTexts.DELETE_TAG
                    : TableTexts.DELETE_TAG
            }
            body={
                <Grid>
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY} text={selected.length > 1
                        ? TableTexts.DELETE_TAGS_TEXTS
                        : showDeleteModal.length > 1 ? TableTexts.DELETE_TAG_TEXT : TableTexts.DELETE_TAGS_TEXT} />
                    {selected.length > 1 ?
                        <List>
                            {showDeleteModal.map((tag) => (
                                <ListItem key={tag.id} disablePadding>
                                    <ListItemText
                                        primary={<TextUhda margin={0} type={textType.BODY} text={tag.name}></TextUhda>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                        : <TextUhda type={textType.BODY} text={showDeleteModal.length > 1 ? showDeleteModal[1].name : showDeleteModal[0].name} />}

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                        text={`${TableTexts.TEXT_MODAL_DELETE_TAG}`} />

                </Grid>}
            show={showDeleteModal.length != 0}
        />
    )
}

DeleteModal.propTypes = {
    setShowDeleteModal: PropTypes.func.isRequired
}

function EditModal({
    selectedDate,
    setSelectedDate,
    handleEditParticipant,
    setShowEditModal,
    showEditModal,
    editValues,
    setEditValues,
    error
}) {
    const handleInputChange = e => {
        const { name, value } = e.target
        setEditValues({ ...editValues, [name]: value })
    }
    const handleClickSubmit = async () => {
        handleEditParticipant()
    }

    return (
        <DialogUhda
            textCancelButton={CommonTexts.CANCEL}
            textConfirmButton={CommonTexts.SAVE}
            handleShow={setShowEditModal}
            handleConfirm={handleClickSubmit}
            values={editValues}
            setValues={setEditValues}
            title={TableTexts.EDIT_TAG}
            body={
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <FormControl

                        margin='normal'
                        style={{ width: "100%" }}
                        data-testId={"tagName"}>
                        <TextfieldUhda
                            name='name'
                            handleChange={handleInputChange}
                            label={TableTexts.TAG_NAME}
                            value={editValues.name}
                            helperText={(error && `${error}`)}

                        />
                    </FormControl>
                    <FormControl

                        margin='normal'
                        style={{ width: "100%" }}
                        data-testId={"tagDescription"}>
                        <TextfieldUhda
                            name='description'
                            handleChange={handleInputChange}
                            label={TableTexts.TAG_DESCRIPTION}
                            value={editValues.description}
                        />
                    </FormControl>
                </Grid>
            }
            show={showEditModal}
        />
    );
}

EditModal.propTypes = {
    setShowEditModal: PropTypes.func.isRequired,
    handleEditParticipant: PropTypes.func.isRequired
}
/**
 * @Page
 * Page that shows the table of participants that a user has
 * @param match
 * @returns {JSX.Element}
 * @constructor
 */
const TagsPage = () => {
    const initialValues = { name: "", description: "" }
    const [values, setValues] = useState(initialValues)
    const [editValues, setEditValues] = useState(initialValues)
    const [selectedDate, setSelectedDate] = useState([])
    const [showAddModal, setShowAddModal] = useState(-999)
    const [showEditModal, setShowEditModal] = useState(-999)
    const [showDeleteModal, setShowDeleteModal] = useState(-999)
    const [response, setResponse] = useState()
    const [error, setError] = useState()
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState();
    const headCells = [
        { id: "name", numeric: false, disablePadding: false, label: TableTexts.TAG_NAME, isSortable: true },
        { id: "description", numeric: false, disablePadding: false, label: TableTexts.TAG_DESCRIPTION, isSortable: false },
        { id: "actions", numeric: false, disablePadding: true, label: TableTexts.ACTIONS, isSortable: false, }
    ];
    const { trackPageView } = useMatomo()

    const dispatch = useDispatch()

    useEffect(() => {
        trackPageView()
    }, [])

    /**
     * It takes the values from the form, sends them to the API, and then closes the modal
     */
    const handleAddParticipant = async () => {
        setLoading(true)
        try {
            await MyAxiosInstance.post(
                `${BASE_PATH}/tag`,
                values
            );
            dispatch(toast("Tag added", "success"))
            setShowAddModal(-999)
        } catch (e) {
            dispatch(toast("Tag names cannot be repeated!", "error"))
            //setError(e.response.data.data.messages.name[0])
        }
        getTags()
    }

    /**
     * It takes the values from the form, sends them to the backend, and then gets the updated list of tags
     */
    const handleAddParticipantContinue = async () => {
        setLoading(true)
        try {
            await MyAxiosInstance.post(
                `${BASE_PATH}/tag`,
                values
            );
            dispatch(toast("Tag added", "success"))
        } catch (e) {
            dispatch(toast("Tag names cannot be repeated!", "error"))
            //setError(e.response.data.data.messages.name[0])
        }
        getTags()
    }

    /**
     * It takes the values from the edit form, and sends a PUT request to the API with the new values
     */
    const handleEditParticipant = async () => {
        setLoading(true)

        try {
            await MyAxiosInstance.put(
                `${BASE_PATH}/tag/${editValues["id"]}`,
                editValues
            );
            dispatch(toast("Tag edited", "success"))
            setShowEditModal(-999)
        } catch (e) {
            //setError(e)
            dispatch(toast("Tag names cannot be repeated!", "error"))
        }
        getTags()

    }

    /**
     * It deletes a tag from the database
     */
    const handleDeleteParticipant = async () => {
        setLoading(true)
        let error = 0
        try {
            if (selectedDate.length != 0) {
                for (let i = 0; i < selectedDate.length; i++) {
                    error = i
                    // eslint-disable-next-line no-await-in-loop
                    await MyAxiosInstance.delete(
                        `${BASE_PATH}/tag/${selectedDate[i].id}`,
                        editValues);
                }
            } else {
                await MyAxiosInstance.delete(
                    `${BASE_PATH}/tag/${showDeleteModal[0]}`,
                    editValues
                );
            }
            dispatch(toast("Tag(s) deleted", "success"))

            setShowDeleteModal(-999)
        } catch (e) {
            if (e.response.data.code == 801) {
                if (selectedDate.length != 0) {
                    setShowDeleteModal(-999)
                    dispatch(toast(`This tag (${selectedDate[error].name}) cannot be deleted because it's in use`, "error"))
                    setSelectedDate([])
                } else {
                    setShowDeleteModal(-999)
                    dispatch(toast("This tag cannot be deleted because it's in use", "error"))
                }
            }
            setError(e)
        }
        getTags()

    }

    /**
     * It gets the tags from the API and sets the response to the state.
     */
    const getTags = async () => {
        try {
            var responseAPI = await TagsService.getTags()
            responseAPI = Object.values(responseAPI.data.data.items)
            setResponse(responseAPI)
            // eslint-disable-next-line no-empty
        } catch {
        }
        setLoading(false)
        setError()

    }

    useEffect(() => {
        getTags()
    }, [])

    return (
        <>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <div style={{ paddingLeft: "48px", paddingRight: "48px" }}>
                        {response && <TableBaseUhda
                            tableTitle={TableTexts.TAGS_TITLE}
                            headCells={headCells}
                            dataToUse={response}
                            addModal={AddModal}
                            deleteModal={DeleteModal}
                            editModal={EditModal}
                            initialValues={initialValues}
                            values={values}
                            setValues={setValues}
                            editValues={editValues}
                            setEditValues={setEditValues}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            handleAddParticipant={handleAddParticipant}
                            handleAddParticipantContinue={handleAddParticipantContinue}
                            handleEditParticipant={handleEditParticipant}
                            handleDeleteParticipant={handleDeleteParticipant}
                            showAddModal={showAddModal}
                            setShowAddModal={setShowAddModal}
                            showEditModal={showEditModal}
                            setShowEditModal={setShowEditModal}
                            showDeleteModal={showDeleteModal}
                            setShowDeleteModal={setShowDeleteModal}
                            interactive={true}
                            canAdd={true}
                            canEdit={true}
                            canDelete={true}
                            error={error}
                            searchable={true}
                            searchValue={"description"}
                            searchText={"Search by name or description"}

                        />}
                    </div>
                </>)}
        </>

    )
}

export default TagsPage;
