/*eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { AppColors } from "../../resources/AppColors";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TableHeadUhda from "./TableHeadUhda";
import TableToolbarUhda from "./TableToolbarUhda";
import { textType } from "../../resources/AppTexts";
import TextUhda from "../TextUdha";
import { menuOptions, SnackBarResponses } from "../../locale/en";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import { StorageManager } from "../../utils";
import { isAfter, isBefore, parse } from "date-fns";
import { useDispatch } from "react-redux";
import { toast } from "../../reducers/notificationsReducer";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 750,
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: AppColors.CBM_SAND,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  caption: {
    color: AppColors.SECONDARY,
    /*
        '&.MuiTablePagination-caption': {
            display: "none"
        }*/
  },
  root: {
    color: AppColors.PRIMARY,
  },
  selectDropdown: {
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BACKGROUND,
  },
  select: { color: AppColors.RED, backgroundColor: AppColors.RED },
}));

export default function TableUhda({
  tableTitle,
  headCells,
  dataToUse,
  setShowAddModal,
  showAddModal,
  setShowEditModal,
  showEditModal,
  editModal: EditModal,
  setShowDeleteModal,
  showDeleteModal,
  deleteModal: DeleteModal,
  addModal: AddModal,
  downloadPdfModal: DownloadPdfModal,
  setShowDownloadPdfModal,
  showDownloadPdfModal,
  handleAddParticipant,
  handleAddParticipantContinue,
  handleEditParticipant,
  handleDeleteParticipant,
  handleDownloadPdf,
  initialValues,
  values,
  setValues,
  editValues,
  setEditValues,
  selectedDate,
  setSelectedDate,
  interactive,
  edit,
  canAdd,
  canEdit,
  canDelete,
  canDownload,
  textDataEmpty,
  searchable,
  error,
  defaultOrder,
  searchValue,
  searchText
}) {
  // eslint-disable-next-line no-param-reassign
  searchValue = searchValue ? searchValue : "name"
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(defaultOrder == undefined ? "name" : defaultOrder);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [addable] = useState(canAdd)
  const [anchorEl, setAnchorEl] = React.useState([]);
  const [searched, setSearched] = useState();
  const storageManager = new StorageManager();

  const isAdmin = storageManager.getAdmin()
  //const open = Boolean(anchorEl);

  const dispatch = useDispatch()

  const handleActionClick = (id, event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget })

  }

  // eslint-disable-next-line no-unused-vars
  const handleActionClose = (id, event) => {
    delete anchorEl[id]
    setAnchorEl({ ...anchorEl, [id]: null })

  }

  // eslint-disable-next-line no-param-reassign
  if (edit == null) edit = true;

  //Function to compare if its descening or ascending
  // eslint-disable-next-line no-shadow
  function descendingComparator(a, b, orderBy) {
    if (orderBy == "dashboardName") {
      if (b.user?.toLowerCase() < a.user?.toLowerCase()) {
        return -1;
      }
      if (b.user?.toLowerCase() > a.user?.toLowerCase()) {
        return 1;
      }
      return 0;
    }
    else if (orderBy == "surname") {
      if (b.surname?.toLowerCase() < a.surname?.toLowerCase()) {
        return -1;
      }
      if (b.surname?.toLowerCase() > a.surname?.toLowerCase()) {
        return 1;
      }
      return 0;
    }
    else if (orderBy == "submitted_at") {
      const dateA = parse(a[orderBy], "dd-MM-yyyy HH:mm", new Date());
      const dateB = parse(b[orderBy], "dd-MM-yyyy HH:mm", new Date());
      if (isAfter(dateB, dateA)) {
        return 1;
      }
      if (isBefore(dateB, dateA)) {
        return -1;
      }
      return 0;
    }
    else {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
      return 0;
    }

  }

  //Function that return true or false depending on the order
  // eslint-disable-next-line no-shadow
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  //Function to sort the table
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      // eslint-disable-next-line no-shadow
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  //Function to close all the modals
  const handleClose = () => {
    setShowDeleteModal(-999);
    setShowAddModal(-999);
    setShowEditModal(-999);
  };

  //Function to handle the edit modal of one id
  const handleEdit = (row) => {
    if (canEdit) {
      setEditValues(dataToUse.find((x) => x.id === row.id));
      setShowEditModal(row.id, row["orderBy"]);
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }

  };

  //Function to handle the delete modal
  // eslint-disable-next-line no-shadow
  const handleDeleteSelected = (selected) => {
    setShowDeleteModal(selected);
    setSelectedDate(selected);
  };

  //Function to handle the delete modal
  const handleDelete = (row) => {
    if (canDelete) {
      setShowDeleteModal([row.id, row]);
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }
  };

  //Function to handle the delete modal
  const handleDownload = (row) => {
    setShowDownloadPdfModal(row.id);
  };
  // eslint-disable-next-line no-unused-vars
  const handleSubmitModalDelete = () => {
    //   BookingService.deleteBooking(selected, selected.length > 1 ? true : false)
    setSelected([]);
    handleClose();
  };

  //Function to handle the new modal
  const handleOpenModalNew = () => {
    if (canAdd) {
      setShowAddModal(1);
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }
  };
  // eslint-disable-next-line no-unused-vars
  const handleSubmitModalNew = () => {
    //   BookingService.postNewBooking(values)
    setValues({ name: "", email: "", checkin: "", checkout: "", code: "" });
    handleClose();
  };
  // eslint-disable-next-line no-unused-vars
  const handleSubmitModalEdit = () => {
    //   BookingService.updateBooking(selected[0], values)
    setSelected([]);
    handleClose();
  };
  //Function that get the data from the parameter and put it on the variable
  const getData = async () => {
    setTableData(dataToUse);
  };

  //Function to select all the items on the table from the button upside
  const handleSelectAllClick = (event) => {
    if (event.target.checked && tableData) {
      const newSelecteds = tableData;
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  //Function to select one item of the table
  const handleSelectClick = (event, row) => {
    const id = row.id
    const selectedIndex = selected.map(function (e) { return e.id }).indexOf(id)
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  //Function to handle to sort ascending or descending
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Function to change the page of the table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //Function to change the rows per page that shows the table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getData();
  }, []);

  const requestSearch = (searchedVal) => {
    const filteredRows = Object(dataToUse).filter((row) => {
      return (row[searchValue ? searchValue : "name"].toLowerCase().includes(searchedVal.toLowerCase()) || row.name.toLowerCase().includes(searchedVal.toLowerCase()))
    });
    setTableData(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };
  const isSelected = (id) => selected.map(function (e) { return e.id }).indexOf(id) !== -1;
  if (tableData === null || tableData.length == 0) {
    return (
      <>
        <React.Fragment>
          {/* {searchable && <SearchBar
            placeholder={searchText ? searchText : "Search by name"}

            style={{ marginRight: "10px" }}
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />} */}
          <TableToolbarUhda
            numSelected={selected.length}
            handleDelete={handleDelete}
            tableTitle={tableTitle}
          />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHeadUhda
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                handleOpenModalNew={handleOpenModalNew}
                headCells={headCells}
                interactive={interactive}
                addable={addable}
              />
            </Table>
            <Grid
              style={{ height: "200px" }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <TextUhda
                type={textType.TITLE}
                color={AppColors.PRIMARY}
                text={textDataEmpty}
              />
            </Grid>
          </TableContainer>
        </React.Fragment>
        {showAddModal >= 0 && (
          <AddModal
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleAddParticipant={handleAddParticipant}
            handleAddParticipantContinue={handleAddParticipantContinue}
            values={values}
            setValues={setValues}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
            initialValues={initialValues}
          />
        )}
      </>
    );
  } else {
    return <>
      <React.Fragment>
        {/* {searchable && <SearchBar
          placeholder={searchText}
          style={{ marginRight: "10px" }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />} */}
        <TableToolbarUhda
          numSelected={selected.length}
          handleDelete={() => {
            handleDeleteSelected(selected);
          }}
          tableTitle={tableTitle}
        />

        <TableContainer style={{ maxHeight: "50em" }}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHeadUhda
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              handleOpenModalNew={handleOpenModalNew}
              headCells={headCells}
              interactive={interactive}
              addable={addable}
              canDelete={canDelete}
            />

            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={false}
                      tabIndex={-1}
                      key={row.id}
                      selected={false}
                    >
                      {canDelete && <TableCell padding="checkbox">
                        {interactive && (
                          <Checkbox
                            style={{ color: AppColors.PRIMARY }}
                            onClick={(event) =>
                              handleSelectClick(event, row)
                            }
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        )}
                      </TableCell>}
                      {headCells.map(
                        (key) =>
                          key["id"] !== "actions" && (
                            <TableCell align="left">
                              {row[key["id"]]}
                            </TableCell>
                          )
                      )}
                      {interactive && (
                        <TableCell align="left">
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            {((edit || canEdit || canDownload || canDelete) && (isAdmin == 1 || row.higherLevel != true)) && <Button data-testId={"menuButton"} aria-controls="fade-menu" aria-haspopup="true"
                              onClick={e => handleActionClick(row.id, e)}>
                              <IconProviderUhda icon={<Icons.OPTIONS_HORIZONTAL
                                color={AppColors.PRIMARY} style={{
                                  verticalAlign: "middle",
                                  display: "inline-flex",
                                  paddingRight: "4px"
                                }} size="2rem" />} />
                            </Button>}
                            <Menu
                              style={{
                                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)"
                              }}
                              color={AppColors.WHITE}
                              id={row.id}
                              anchorEl={anchorEl[row.id]}
                              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                              keepMounted
                              open={Boolean(anchorEl[row.id])}
                              onClose={e => handleActionClose(row.id, e)}
                              TransitionComponent={Fade}>
                              {edit && canEdit && (
                                <MenuItem data-testId={"editOption"} style={{ color: AppColors.PRIMARY }}
                                  onClick={() => {
                                    handleEdit(row);
                                  }}> {menuOptions.EDIT} </MenuItem>)}
                              {canDownload &&
                                <MenuItem style={{ color: AppColors.PRIMARY }}
                                  onClick={() => {
                                    handleDownload(row.id);
                                  }}> {menuOptions.SUMMARY_PDF} </MenuItem>}
                              {canDelete &&
                                <MenuItem data-testId={"deleteOption"} style={{ color: AppColors.PRIMARY }}
                                  onClick={() => {
                                    handleDelete(row);
                                  }}> {menuOptions.DELETE}</MenuItem>}
                            </Menu>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{
            caption: classes.caption,
            root: classes.root,
          }}
          style={{ overflow: "hidden" }}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            MenuProps: { classes: { paper: classes.selectDropdown } },
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          labelRowsPerPage={"View"}
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </React.Fragment>
      {showAddModal >= 0 && (
        <AddModal
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleAddParticipant={handleAddParticipant}
          handleAddParticipantContinue={handleAddParticipantContinue}
          values={values}
          setValues={setValues}
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          initialValues={initialValues}
          error={error}
          data={dataToUse}
        />
      )}
      {showEditModal >= 0 && (
        <EditModal
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleEditParticipant={handleEditParticipant}
          editValues={editValues}
          setEditValues={setEditValues}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          data={dataToUse}
        />
      )}
      {showDeleteModal.length > 0 && (
        <DeleteModal
          selected={selected}
          showDeleteModal={showDeleteModal}
          handleDeleteParticipant={handleDeleteParticipant}
          setShowDeleteModal={setShowDeleteModal}
          data={dataToUse}
        />
      )}
      {showDownloadPdfModal >= 0 && (
        <DownloadPdfModal
          selected={selected}
          showDownloadPdfModal={showDownloadPdfModal}
          handleDownloadPdf={handleDownloadPdf}
          setShowDownloadPdfModal={setShowDownloadPdfModal}
        />
      )}
    </>;
  }
}

TableUhda.propTypes = {
  headCells: PropTypes.array.isRequired,
  showAddModal: PropTypes.number,
  setShowAddModal: PropTypes.func.isRequired,
  showEditModal: PropTypes.number,
  setShowEditModal: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.number,
  setShowDeleteModal: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired,
  handleDeleteParticipant: PropTypes.func.isRequired,
  setEditValues: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func,
  tableTitle: PropTypes.string.isRequired,
};
