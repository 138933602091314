/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Grid, InputAdornment, TextField } from "@mui/material";
import { AppColors } from "../resources/AppColors";
import { StorageManager } from "../utils";
import AuthContext from "../context/authentication/authContext"
import makeStyles from '@mui/styles/makeStyles';
import NavbarUhda from "../components/NavbarUhda";
import CardUhda from "../components/CardUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import { CommonTexts, SnackBarResponses, TableTexts } from "../locale/en";
import IconProviderUhda from "../components/IconProviderUhda";
import Icons from "../resources/Icons";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import ErrorIcon from "@mui/icons-material/Error";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: AppColors.BACKGROUND
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
      "&.Mui-disabled": {
        color: AppColors.GREY,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    "& :-webkit-autofill": {
      transitionDelay: "9999s",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
  textFieldLabelWithText: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE

    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,

  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

/**
 * @Page
 * Page to change the password of the user when is not logged
 */
export default function ChangePass(props) {

  const storageManager = new StorageManager();

  const [checked, setChecked] = useState(false);
  const [username, setUsername] = useState(storageManager.getUsername());
  const [password, setPassword] = useState(storageManager.getPwd());
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const authContext = useContext(AuthContext)
  const { message, authenticated, login, checkAuth, removeFirstTime } = authContext;

  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showNewPwdError, setShowNewPwdError] = React.useState(false);
  const [showRepeatPwdError, setShowRepeatPwdError] = React.useState(false);
  const [showOldPwdError, setShowOldPwdError] = React.useState(false);
  const [isTypingOldP, setIsTypingOldP] = useState(false)
  const [isTypingNewP, setIsTypingNewP] = useState(false)
  const [isTypingRepeatP, setIsTypingRepeatP] = useState(false)
  const classes = useStyles();

  const dispatch = useDispatch()

  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView()
  }, [])

  useEffect(() => {
    checkAuth()
    if (authenticated) {
      props.history.push("/home")
    }
    if (message) {
      setAlertMessage(message)
      setShowValidationAlert(true)
    }
  }, [message, authenticated, props.history])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //Function that will be called when the user wants to go to the forgot Page
  const handleForgot = async (e) => {
    props.history.push("/forgot")
  }

  const oldPasswordChange = (e) => {
    setOldPassword(e.target.value)
    setIsTypingOldP(true)
  }

  const newPasswordChanged = (e) => {
    setNewPassword(e.target.value)
    setIsTypingNewP(true)

  }

  const repeatPasswordChanged = (e) => {
    setRepeatPassword(e.target.value)
    setIsTypingRepeatP(true)

  }

  /**
   * If the new password is less than 8 characters, show an error. 
   * If the repeat password is less than 8 characters, show an error. 
   * If the old password is equal to the password, show an error. 
   * If the new password is equal to the repeat password, show an error. 
   * If the old password is equal to the password, show an error.
   * If the new password is equal to the repeat password
   */
  const changePassword = async () => {
    if (newPassword.length < 8) {
      setShowNewPwdError(true)
    }
    if (repeatPassword.length < 8) {
      setShowRepeatPwdError(true)
    } else {

      setShowNewPwdError(false)
      setShowRepeatPwdError(false)
      if (password === oldPassword) {
        setShowOldPwdError(false)
        if (newPassword === repeatPassword) {
          var body = {
            "current_password": oldPassword,
            "password": newPassword,
            "password_confirmation": repeatPassword
          }
          try {
            await MyAxiosInstance.put(`${BASE_PATH}/auth/password`, body, {
              headers: {
                "Authorization": `Bearer ${storageManager.getTokenTemporal()}`
              }
            });
            dispatch(toast("Changed password successfully!", "success"))

            removeFirstTime()
            setTimeout(() => {
              props.history.push("/login")
            }, 1000)
            // eslint-disable-next-line no-empty
          } catch (error) {
            dispatch(toast(SnackBarResponses.OLD_NEW_PASSWORD_SAME, "error"))
          }
        } else {
          dispatch(toast(SnackBarResponses.NEW_PASSWORD_MATCH, "error"))
        }
      } else {
        setShowOldPwdError(true);
      }
    }
  }

  return (
    <Grid container style={{ minHeight: "40%" }} alignItems={"center"}
      direction={"column"}>
      <Grid item>
        <NavbarUhda open={false} setOpen={false} setSelected={null} logged={false} />
      </Grid>
      <Grid item style={{ marginTop: "15vh", marginBottom: "15vh" }}>
        <CardUhda bg={AppColors.WHITE} children={
          <Grid
            container
            item
            alignItems="center"
            direction="column"
            justifyContent="center"
            className="card"
          >
            <div
              style={{ margin: "5vh", display: "flex", flexDirection: "column", minWidth: 300 }}
            >
              <div style={{ textAlign: "center" }}>
                <IconProviderUhda icon={<Icons.VERIFIED style={{ fontSize: 50 }} />} />
                <TextUhda type={textType.TITLE} text={CommonTexts.WELCOME.toUpperCase() + "!"} />
                <TextUhda type={textType.SUBTITLE} text={CommonTexts.PLEASE_CHANGE_PASS} />
              </div>

              <TextField
                onChange={(e) => oldPasswordChange(e)}
                type="password"
                label="Old Password"
                margin="normal"
                variant="outlined"
                //className={classes.textFieldLabel}
                className={classes.textFieldLabel}

                error={showOldPwdError}
                helperText={(showOldPwdError === true) && SnackBarResponses.OLD_PASSWORD_ERROR}
                inputProps={{
                  endAdornment: (showOldPwdError === true) &&
                    <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                }}
              />

              <TextField
                onChange={(e) => newPasswordChanged(e)}
                type="password"
                label="New password"
                margin="normal"
                variant="outlined"
                //className={classes.textFieldLabel}
                className={classes.textFieldLabel}

                error={showNewPwdError}
                helperText={(showNewPwdError === true) && TableTexts.PASSWORD_ERROR}
                inputProps={{
                  endAdornment: (showNewPwdError === true) &&
                    <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                }}

              />
              <TextField
                onChange={(e) => repeatPasswordChanged(e)}
                type="password"
                label="Repeat new password"
                margin="normal"
                variant="outlined"
                //className={classes.textFieldLabel}
                className={classes.textFieldLabel}

                error={showRepeatPwdError}
                helperText={(showRepeatPwdError === true) && TableTexts.PASSWORD_ERROR}
                inputProps={{
                  endAdornment: (showRepeatPwdError === true) &&
                    <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                }}
              />

              <Grid container style={{ paddingTop: "20px" }} spacing-xs-1 wrap="nowrap" spacing={3}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Grid container item alignItems="center" justifyContent="center">
                  <ButtonFilledUhda text={CommonTexts.CHANGE_PASS} onClick={changePassword} margin={"0.7em"} />
                </Grid>
              </Grid>
            </div>
          </Grid>} />

      </Grid>
      <AppBar position="fixed" className={classes.appBar}
        style={{
          top: "auto",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY
        }}>
        <Box display="flex" justifyContent="center" m={3}>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href="https://docs.google.com/document/u/1/d/e/2PACX-1vQ3NIwP7OMOI-3DeN-KBumIStXruEyp85ACtno3Qv5xLUMMyvN4XC_p72aQCGK1p0lFRzQ00xNZk0DA/pub"
              target="_blank" rel="noreferrer">{CommonTexts.ABOUT}</a>

          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQkQFg4O7erLNHlJTlXpthfriz1iWRWOQjWAgxGgux2grq1MNOw0zp1dGlZRUvUyy6C2t8-m4sgYtrW/pub'
              target="_blank" rel="noreferrer">{CommonTexts.USER_GUIDE}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vS6H9drn7R70m0Ftgjow7aejc8QGNuObZfvVAFzFqphoftVxA5qlDZz4664WURVoVbTSH-H-93YBgN0/pub'
              target="_blank" rel="noreferrer">{CommonTexts.TERMS}</a>
          </Box>
          <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vQ6Sh5WmhnQjMMo28czb6kJbb00JVZnrAR1yB6Lngdg8d_lz_H1R2VxLDM1fMBmWzCAbL6cJVvc4Puc/pub'
              target="_blank" rel="noreferrer">{CommonTexts.PRIVACY}</a>
          </Box>
        </Box>
      </AppBar>
    </Grid>
  );
}
