import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {IconButton, Toolbar, Tooltip} from "@mui/material"
import clsx from "clsx"
import PropTypes from "prop-types"
import DeleteIcon from "@mui/icons-material/Delete";
import {AppColors} from "../../resources/AppColors"
import {TableTexts} from "../../locale/en"
import TextUhda from "../TextUdha";
import {textType} from "../../resources/AppTexts";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
        theme.palette.mode === "light"
          ? {
            color: AppColors.PRIMARY,
            backgroundColor: AppColors.PRIMARY_MAX_OPACITY,
          }
          : {
            color: theme.palette.text.primary,
            backgroundColor: AppColors.PRIMARY,
          },
  title: {
    flex: "1 1 100%",
  },
}));

/**
 * @component
 * Component that displays the toolbar of the table. It changes depending on whether or not an item has been selected.
 */
export default function TableToolbarUhda(props) {
  const classes = useStyles()
  const {numSelected, handleDelete, tableTitle} = props
  
  return (
    <Toolbar className={ clsx(classes.root, {
      [classes.highlight]: numSelected > 0
    }) }>
      {numSelected > 0 ? (
        <TextUhda type={ textType.BODY } text={ numSelected + " " + TableTexts.SELECTED }/>

      ) : (
        <TextUhda type={ textType.SUBTITLE_BOLD } text={ tableTitle }/>

      )}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton arial-label='delete' size="large">
            <DeleteIcon onClick={ handleDelete }/>
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}
TableToolbarUhda.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  tableTitle: PropTypes.string.isRequired
}
