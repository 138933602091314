import React from "react";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AppColors } from "../../resources/AppColors";
import PropTypes from "prop-types";
import { textType, useTextStyles } from "../../resources/AppTexts";
import TextUhda from "../TextUdha"
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
/**
 * @component
 * Component to create custom Breadcrumbs depending on the routes
 *
 * @param routes: array of routes to show
 *
 * @example
 * const routes=[{}]
 * <BreadcrumbsUhda routes={routes} />
 *
 */
const BreadcrumbsUhda = ({ routes }) => {
  const history = useHistory()
  const texts = useTextStyles();
  const truncate = (str, n) => {
    return str.length > n ? str.substring(0, n - 3) + "..." : str;
  };

  return (
    <div>

      <Breadcrumbs className={texts.subtitle} style={{ color: AppColors.PRIMARY, marginLeft: 0 }}

        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        {routes.map((value, index) => {
          const newLabel = truncate(value.label, 20);
          if (index === routes.length - 1) {
            return (
              <div style={{ marginTop: "15px" }}>
                <Row>
                  <Col md={12} xs={12} sm={12}>
                    <TextUhda type={textType.TITLE} color={AppColors.PRIMARY} text={value.label} />
                  </Col>
                </Row>
              </div>
              //<p style={{color:AppColors.ACCENT, fontWeight:"bold"}}>{value.label}</p>

            )
          } else {
            if (value.disabled) {
              return (
                <div style={{ marginTop: "15px" }}>
                  <Row>
                    <Col md={12} xs={12} sm={12}>
                      <TextUhda type={textType.TITLE} color={AppColors.BLACK} text={value.label} />
                    </Col>
                  </Row>
                </div>
                //    <p style={{color:AppColors.PRIMARY,fontWeight:"bold"}}>{value.label}</p>

              )
            } else {

              return (
                <Row>
                  <Col md={12} xs={12} sm={12}>
                    <Link
                      onClick={() => history.push({
                        pathname: value.url,
                        state: { detail: value.studyId, permissions: value.permissions }
                      })}
                      style={{ cursor: "pointer", fontSize: "18px", color: AppColors.BLACK, fontWeight: "bold", marginTop: "15px" }}
                      underline="hover">
                      {newLabel}
                    </Link>
                  </Col>
                </Row>
              );
            }
          }
        })}
      </Breadcrumbs>

    </div>
  );

}

BreadcrumbsUhda.propTypes = {
  routes: PropTypes.array.isRequired,
}

export default BreadcrumbsUhda
