/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars*/
import React, { useEffect, useState, useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { AppColors } from "../resources/AppColors";
import { CommonTexts, LabelsDrawer, LabelsStudy, SnackBarResponses, TableTexts, } from "../locale/en";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import DialogUhda from "../components/DialogUhda";
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import TeamMembersService from "../services/TeamMembersService";
import DrawerUhda from "../components/DrawerUhda";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { orderArrayAlphabetical, validateEmail } from "../utils/HelperFunctions";
import TagsService from "../services/TagsService";
import { StorageManager } from "../utils";
import { textType } from "../resources/AppTexts";
import TextUhda from "../components/TextUdha";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  // indeterminateColor: {
  //   color: "#f50057",
  // },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  option: {
    backgroundColor: "#0000000a"
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      //marginTop: '-0.3em',
      "&.Mui-focused": {
        color: AppColors.BLACK,
      },
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"
    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  }
}));

function AddModal({
  handleAddParticipant,
  handleAddParticipantContinue,
  initialValues,
  values,
  setValues,
  showAddModal,
  setShowAddModal,
  data
}) {
  const storageManager = new StorageManager();
  const [showEmailError, setShowEmailError] = useState(false);
  const [showErrorText, setShowErrorText] = useState();
  // eslint-disable-next-line no-unused-vars
  const [selectedRole, setSelectedRole] = useState(0);
  const [emailsAvailable, setEmailsAvailable] = useState(["invite this email"]);
  const [isNewEmail, setIsNewEmail] = useState(true);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const studyId = useRef();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [optionsByName, setOptionsByName] = useState({});
  const [flag, setFlag] = useState(false)
  const [options, setOptions] = useState([]);
  const isAllSelected = options.length > 0 && selectedRoles.length === options.length;
  const classes = useStyles();
  const [researcherId, setResearcherId] = useState();
  const [emailData, setEmailData] = useState();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const userRoles = JSON.parse(storageManager.getRoles());

  /**
   * If the last value in the array is "all", then set the selected roles to either all of the options
   * or an empty array. Otherwise, set the selected roles to the value
   * @param event - The event that triggered the change.
   * @returns The value of the selected roles.
   */
  const handleChangeRole = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      // ! This is buggy, if select all is checked, the string "all" is added to the array
      // ! When select all is unchecked, the value is allRoles (options)!!!
      // ! That's why if selectedRoles.length === options.length [] is returned
      setSelectedRoles(selectedRoles.length === options.length ? [] : options);
      return;
    }
    setSelectedRoles(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  /**
   * It gets the roles from the database and sets the options for the dropdown menu
   */
  const getRoles = async () => {
    let optionsFinal = {}
    try {
      const responseRoles = await TeamMembersService.getRoles();
      const email = storageManager.getEmail()
      var userRoles;
      const user = data.find(user => (user.email || user.username) === email)
      if (user == undefined) {
        userRoles = responseRoles.data.map(a => a.name).join("/");
      } else {
        userRoles = user.userrole.split("/").map(str => str.replace(/\s/g, ""));
      }
      const roles = responseRoles.data.filter((role) =>
        userRoles.includes(role.name)
      );
      const actualRoles = roles;
      var roleList = [];
      let roleLevel = 999
      for (const element of actualRoles) {
        if (element.level < roleLevel) {
          roleLevel = element.level
        }
      }
      responseRoles.data.forEach((role) => {
        if (role.group == 1 && storageManager.getAdmin() == 1) {
          optionsFinal = { ...optionsFinal, [role["name"]]: role.id };
          roleList.push(role.name);
        }
        else {
          if (role.group == 1 && role.level >= roleLevel) {
            optionsFinal = { ...optionsFinal, [role["name"]]: role.id };
            roleList.push(role.name);
          }
        }
      });
      orderArrayAlphabetical(roleList)
      setOptionsByName(optionsFinal);
      setOptions(roleList);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  //This function retrives the stored permissions and id from local storage
  useEffect(() => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    studyId.current = newItem.id;
  }, [])

  useEffect(() => {
    setValues(initialValues);
    getRoles();
  }, [showAddModal]);

  /**
   * The function `handleClickSubmit` is called when the user clicks on the submit button. It checks if
   * the email is valid and if the user is new or not. 
   * If the user is new, it checks if the name, surname and roles are filled. 
   * If the user is not new, it checks if the roles are filled. 
   * If the user is not new and the roles are not filled, it shows an error. 
   * If the user is new and the name, surname or roles are not filled, it shows an error.
   * If the user is not new and the roles are filled, it creates a new array with the roles and sends a POST request to the server. 
   * If the user is new and the name, surname and roles are filled, it creates a new array with the roles and sends a POST request to the server
   */
  const handleClickSubmit = async () => {
    if (!email) {
      return setShowError(true);
    }
    if (
      isNewEmail &&
      (values.name === "" ||
        values.surname === "" ||
        selectedRoles.length === 0)
    ) {
      return setShowError(true);
    } else if (selectedRoles.length === 0) {
      return setShowError(true);
    } else {
      setShowError(false);
    }
    var newEmail = [];

    if (showEmailError) {
      return;
    }
    newEmail = email.split(": ");
    if (validateEmail(newEmail[1])) {
      setShowEmailError(false);
      var rolesForBody = [];
      selectedRoles.forEach((role) => {
        rolesForBody.push(optionsByName[role]);
      });
      const postBody = {
        name: isNewEmail ? values.name : emailData.name,
        surname: isNewEmail ? values.surname : emailData.surname,
        roles: rolesForBody,
        email: newEmail[1],
        "id-researcher": isNewEmail ? null : researcherId,
      };
      try {
        setLoading(true)
        // eslint-disable-next-line no-unused-vars
        const responsePost = await MyAxiosInstance.post(
          `${BASE_PATH}/study/${studyId.current}/team-member`,
          postBody
        );
        handleAddParticipant();
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    } else {
      setShowEmailError(true);
      setShowErrorText("This email doesn't have the correct format.");
    }
  };

  /**
   * The function `handleClickSubmitContinue` is called when the user clicks on the "Continue" button.
   * It checks if the email is valid and if the user has selected at least one role. If the email is
   * valid, it sends a POST request to the server with the email, name, surname, and roles of the user
   */
  const handleClickSubmitContinue = async () => {
    if (!email) {
      return setShowError(true);
    }
    if (
      isNewEmail &&
      (values.name === "" ||
        values.surname === "" ||
        selectedRoles.length === 0)
    ) {
      return setShowError(true);
    } else if (selectedRoles.length === 0) {
      return setShowError(true);
    } else {
      setShowError(false);
    }
    var newEmail = [];

    if (showEmailError) {
      return;
    }
    newEmail = email.split(": ");

    if (validateEmail(newEmail[1])) {
      setShowEmailError(false);
      var rolesForBody = [];
      selectedRoles.forEach((role) => {
        rolesForBody.push(optionsByName[role]);
      });
      const postBody = {
        name: isNewEmail ? values.name : emailData.name,
        surname: isNewEmail ? values.surname : emailData.surname,
        roles: rolesForBody,
        email: newEmail[1],
        "id-researcher": isNewEmail ? null : researcherId,
      };

      try {
        setLoading(true)
        // eslint-disable-next-line no-unused-vars
        const responsePost = await MyAxiosInstance.post(
          `${BASE_PATH}/study/${studyId.current}/team-member`,
          postBody
        );
        handleAddParticipantContinue();
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    } else {
      setShowEmailError(true);
      setShowErrorText("This email doesn't have the correct format.");
    }
  };

  /**
   * It takes an email address as an argument, and then checks if the email address is already in use
   * by a researcher or team member. If it is, it sets an error message. If it isn't, it checks if the
   * email address is already registered to a researcher. If it is, it sets the researcher's name and
   * surname. If it isn't, it sets a flag to indicate that the email address is new
   * @param emailValue - The email that the user has entered
   */
  const getResearcher = async (emailValue) => {
    setShowEmailError(false);
    setFlag(false)
    var emailFieldList = emailValue.split(": ");
    var finalEmailValue = "";
    if (emailFieldList.length === 1) {
      finalEmailValue = emailFieldList[0];
    } else {
      finalEmailValue = emailFieldList[1];
    }
    try {
      const response = await MyAxiosInstance.get(
        `${BASE_PATH}/researcher-by-email/${studyId.current}/${finalEmailValue}`
      );
      if (response.status === 200) {
        var newList = [];

        if (response.data.data.team_member_id) {
          setShowEmailError(true);
          setShowErrorText("This email is already in use");
          newList.push(response.data.data.email);
          //setEmailsAvailable(newList);
        } else {
          setShowEmailError(false);
          if (response.data.data.researcher_id !== null) {
            setResearcherId(response.data.data.researcher_id);
            newList = ["Add existing: " + response.data.data.email];
            setEmailsAvailable(newList);
            setEmailData({
              name: response.data.data.name,
              surname: response.data.data.surname,
            });
            setIsNewEmail(false);
          } else {
            setEmailData(null);
            var newEmail = ["invite this email: " + finalEmailValue];
            setEmailsAvailable(newEmail);
            setIsNewEmail(true);
          }
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  };

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
      handleShow={setShowAddModal}
      handleConfirm={handleClickSubmit}
      handleConfirmAndContinue={handleClickSubmitContinue}
      values={values}
      setValues={setValues}
      title={TableTexts.ADD_TEAM_MEMBER_TITLE}
      buttonColor={AppColors.PRIMARY}
      loading={loading}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl

            className={classes.textFieldLabel}
            margin="normal"
            style={{ width: "100%" }}>
            <Autocomplete
              data-testId={"email"}
              freeSolo
              open={open}
              onOpen={() => {
                if (validateEmail(email)) {
                  setOpen(true);
                }
              }}
              //onClose={ () => setOpen(false) }
              inputValue={email}
              // eslint-disable-next-line no-unused-vars
              onInputChange={(e, value, reason) => {
                if (flag) {
                  // eslint-disable-next-line no-param-reassign
                  value = email
                } else {
                  setEmail(value);
                }
                setFlag(false)
                if (!validateEmail(email)) {
                  setOpen(false);
                } else {
                  getResearcher(value);
                }
              }}
              // eslint-disable-next-line no-unused-vars
              onPaste={(e, value, reason) => {
                var text = (e.clipboardData.getData("Text"))
                text = text.trim()
                text = text.replace(/\s+/g, " ").trim();
                setEmail(text)
                //setEmail(e.clipboardData.getData('Text'));
                setFlag(true)
              }}
              options={validateEmail(email) ? emailsAvailable : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={showEmailError}
                  helperText={
                    showEmailError &&
                    showErrorText
                  }
                  label={TableTexts.EMAIL}
                  variant="outlined"
                />
              )}
            />
          </FormControl>

          {emailData && !isNewEmail && (
            <>
              <FormControl

                data-testId={"teamMemberName"}
                margin="normal"
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="name"
                  isDisabled={true}
                  handleChange={handleInputChange}
                  label={TableTexts.NAME}
                  value={emailData.name}
                />
              </FormControl>
              <FormControl

                data-testId={"teamMemberSurname"}
                margin="normal"
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="surname"
                  isDisabled={true}
                  handleChange={handleInputChange}
                  label={TableTexts.SURNAME}
                  value={emailData.surname}
                />
              </FormControl>
              <FormControl
                margin="normal"
                style={{ width: "100%" }}
                variant="outlined"
                className={classes.select}
              >
                <InputLabel
                  id="multiple-select-label">Role</InputLabel>
                <Select

                  data-testId={"role"}
                  label="Role"
                  labelId="multiple-select-label"
                  multiple
                  value={selectedRoles}
                  onChange={handleChangeRole}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{ root: isAllSelected ? classes.selectedAll : "" }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        // classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedRoles.length > 0 &&
                          selectedRoles.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem data-testId={option} key={option} value={option}>
                      <ListItemIcon data-testId={option}>
                        <Checkbox
                          checked={selectedRoles.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {isNewEmail && (
            <>
              <FormControl

                data-testId={"teamMemberName"}
                margin="normal"
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="name"
                  handleChange={handleInputChange}
                  label={TableTexts.NAME}
                  value={values.name}
                />
              </FormControl>
              <FormControl

                data-testId={"teamMemberSurname"}
                margin="normal"
                style={{ width: "100%" }}>
                <TextfieldUhda
                  name="surname"
                  handleChange={handleInputChange}
                  label={TableTexts.SURNAME}
                  value={values.surname}
                />
              </FormControl>
              <FormControl
                className={classes.select}
                margin="normal"
                style={{ width: "100%" }}
                variant="outlined"
              >
                <InputLabel
                  className={classes.select}
                  id="multiple-select-label">Role</InputLabel>
                <Select

                  className={classes.select}
                  data-testId={"role"}
                  label="Role"
                  labelId="multiple-select-label"
                  multiple
                  value={selectedRoles}
                  onChange={handleChangeRole}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{ root: isAllSelected ? classes.selectedAll : "" }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        // classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedRoles.length > 0 &&
                          selectedRoles.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem data-testId={option} key={option} value={option}>
                      <ListItemIcon data-testId={option}>
                        <Checkbox
                          checked={selectedRoles.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {showError && (
            <FormControl margin="normal" style={{ width: "100%" }}>
              <FormHelperText
                style={{ paddingLeft: "15px", color: "red" }}
                id="my-helper-text"
              >
                {showEmailError
                  && "Make sure to fill all the fields"}
              </FormHelperText>
            </FormControl>
          )}

        </Grid>
      }

      show={showAddModal}
    />
  );
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired,
};

function DeleteModal({
  setShowDeleteModal,
  showDeleteModal,
  selected,
  handleDeleteParticipant,
  data
}) {
  const storageManager = new StorageManager();
  const [lowRolUser, setLowRolUser] = useState(false)
  const [lowRolDeleteUser, setLowRolDeleteUser] = useState(false)

  const dispatch = useDispatch()

  /**
   * It gets the roles of the user and the user to be deleted, and compares them to see if the user has
   * a higher role than the user to be deleted
   */
  const getRoles = async () => {
    try {
      const responseRoles = await TeamMembersService.getRoles();
      const email = storageManager.getEmail()
      var userRoles;
      const myUser = data.find(user => (user.email || user.username) === email)
      if (myUser == undefined) {
        userRoles = responseRoles.data.map(a => a.name).join("/");
      } else {
        userRoles = myUser.userrole.split("/").map(str => str.replace(/\s/g, ""));
      }
      const deleteUser = data.find(user => (user.id) === showDeleteModal[0]).userrole.split(" / ")

      const myRoles = responseRoles.data.filter(role => userRoles.includes(role.name))
      const deleteRoles = responseRoles.data.filter(role => deleteUser.includes(role.name))
      const lowestRole = myRoles.reduce((acc, curr) => {
        if (acc.level > curr.level) {
          return curr
        } else {
          return acc
        }
      })
      setLowRolUser(lowestRole.level)
      const lowestDeleteRole = deleteRoles.reduce((acc, curr) => {
        if (acc.level > curr.level) {
          return curr
        } else {
          return acc
        }
      })
      setLowRolDeleteUser(lowestDeleteRole.level)
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };
  /**
   * If the user's role is lower than the role of the user they're trying to delete, then show a
   * snackbar. Otherwise, delete the user
   */
  const handleDeleteTeamMember = () => {
    if (lowRolUser > lowRolDeleteUser) {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    } else {
      handleDeleteParticipant()
    }
  }

  useEffect(() => {
    getRoles()
  }, [showDeleteModal]);
  return (
    <>
      <DialogUhda
        textCancelButton={CommonTexts.CANCEL}
        textConfirmButton={TableTexts.DELETE_CONFIRM}
        handleShow={setShowDeleteModal}
        handleConfirm={() => {
          handleDeleteTeamMember();
        }}
        title={

          selected.length >= 1
            ? selected.length > 1
              ? TableTexts.DELETE_TEAM_MEMBER_IDS
              : TableTexts.DELETE_TEAM_MEMBERS_ID
            : TableTexts.DELETE_TEAM_MEMBER_ID + showDeleteModal[1].name
        }
        body={
          <Grid>
            <TextUhda color={AppColors.PRIMARY} type={textType.BODY} text={selected.length >= 1
              ? selected.length > 1
                ? TableTexts.DELETE_TEAM_MEMBER_TEXT_MULTIPLE
                : TableTexts.DELETE_TEAM_MEMBER_TEXT
              : TableTexts.DELETE_TEAM_MEMBER_TEXT} />
            {selected.length > 1 ?
              <List>
                {showDeleteModal.map((user) => (
                  <ListItem key={user.id} disablePadding>
                    <ListItemText
                      primary={<TextUhda margin={0} type={textType.BODY} text={user.name}></TextUhda>}
                    />
                  </ListItem>
                ))}
              </List>
              : <TextUhda type={textType.BODY} text={showDeleteModal.length > 1 ? showDeleteModal[1].name : showDeleteModal[0].name} />}

          </Grid>
        }
        show={showDeleteModal.length != 0 || selected.length > 0}
      />
    </>
  );
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

function EditModal({
  handleEditParticipant,
  setShowEditModal,
  showEditModal,
  editValues,
  setEditValues,
  data
}) {
  const storageManager = new StorageManager();
  // eslint-disable-next-line no-unused-vars
  const [showEmailError, setShowEmailError] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [optionsByName, setOptionsByName] = useState({});
  const isAllSelected = options.length > 0 && selectedRoles.length === options.length;
  const classes = useStyles();
  let userRoles = JSON.parse(storageManager.getRoles());

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  /**
   * It gets the roles from the database, and then filters them based on the user's role
   */
  const getRoles = async () => {
    let optionsFinal = {}
    try {
      const responseRoles = await TeamMembersService.getRoles();
      const email = storageManager.getEmail()
      const user = data.find(user => (user.email || user.username) === email)
      if (user == undefined) {
        userRoles = responseRoles.data.map(a => a.name).join("/");
      } else {
        if (user.userrole.includes("/")) {
          userRoles = user.userrole.split("/").map(str => str.replace(/\s/g, ""));
        } else {
          userRoles = user.userrole
        }
      }
      const roles = responseRoles.data.filter((role) =>
        userRoles.includes(role.name)
      );
      const actualRoles = roles;
      var roleList = [];
      let roleLevel = 999
      for (const element of actualRoles) {
        if (element.level < roleLevel) {
          roleLevel = element.level
        }
      }
      responseRoles.data.forEach((role) => {
        if (role.group == 1 && storageManager.getAdmin() == 1) {
          optionsFinal = { ...optionsFinal, [role["name"]]: role.id };
          roleList.push(role.name);
        }
        else {
          if (role.group == 1 && role.level >= roleLevel) {
            optionsFinal = { ...optionsFinal, [role["name"]]: role.id };
            roleList.push(role.name);
          }
        }
      });
      orderArrayAlphabetical(roleList)
      setOptionsByName(optionsFinal);
      setOptions(roleList);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  };

  /**
   * If the last value in the array is "all", then set the selected roles to either all of the options
   * or an empty array. Otherwise, set the selected roles to the value
   * @param event - The event that triggered the change.
   * @returns The value of the selected roles.
   */
  const handleChangeRole = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      // ! This is buggy, if select all is checked, the string "all" is added to the array
      // ! When select all is unchecked, the value is allRoles (options)!!!
      // ! That's why if selectedRoles.length === options.length [] is returned
      setSelectedRoles(selectedRoles.length === options.length ? [] : options);
      return;
    }
    setSelectedRoles(value);
  };
  /**
   * It takes the selected roles from the dropdown menu and puts them into an array. Then it filters
   * out any empty values. If there are any selected roles, it sends a PUT request to the server with
   * the selected roles
   */
  const handleClickSubmit = async () => {
    var rolesForBody = [];
    selectedRoles.forEach((role) => {
      rolesForBody.push(optionsByName[role]);
    });
    rolesForBody.filter(item => item);
    if (selectedRoles.length > 0) {
      const putData = {
        roles: rolesForBody,
      };
      try {
        await MyAxiosInstance.put(
          `${BASE_PATH}/team-member/${editValues.researcher_id}`,
          putData
        );
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      handleEditParticipant();
    }
  };

  useEffect(() => {
    getRoles();
    setSelectedRoles(editValues["userrole"].split(" / "));
  }, [showEditModal]);

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      handleShow={setShowEditModal}
      handleConfirm={handleClickSubmit}
      values={editValues}
      setValues={setEditValues}
      title={TableTexts.EDIT_TEAM_MEMBER_TITLE + showEditModal}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin="normal" style={{ width: "100%" }}>
            <TextfieldUhda
              isDisabled={true}
              name="email"
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={editValues.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && (
                  <InputAdornment position="end">
                    <ErrorIcon style={{ color: AppColors.RED }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl margin="normal" style={{ width: "100%" }}>
            <TextfieldUhda
              isDisabled={true}
              name="name"
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={editValues.name}
            />
          </FormControl>
          <FormControl margin="normal" style={{ width: "100%" }}>
            <TextfieldUhda
              isDisabled={true}
              name="surname"
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={editValues.surname}
            />
          </FormControl>
          <FormControl
            margin="normal"
            style={{ width: "100%" }}
            variant="outlined"
          >
            <InputLabel id="multiple-select-label">Role</InputLabel>
            <Select

              data-testId={"role"}
              label="Role"
              labelId="multiple-select-label"
              multiple
              value={selectedRoles}
              onChange={handleChangeRole}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}>
              <MenuItem
                value="all"
                classes={{ root: isAllSelected ? classes.selectedAll : "" }}
              >
                <ListItemIcon>
                  <Checkbox
                    // classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={
                      selectedRoles.length > 0 &&
                      selectedRoles.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {options.map((option) => (
                <MenuItem data-testId={option} key={option} value={option}>
                  <ListItemIcon data-testId={option}>
                    <Checkbox checked={selectedRoles.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }
      show={showEditModal}
    />
  );
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired,
};

/**
 * @Page
 * Page that shows the table of the user's members
 */
const TeamMembersPage = ({ drawer, drawerStudy }) => {
  const [loading, setLoading] = useState(true);
  const [study, setStudy] = useState();
  const id = useRef();
  const permissions = useRef()
  const initialValues = { name: "", surname: "", userrole: "0", email: "" };
  const [values, setValues] = useState(initialValues);
  const [editValues, setEditValues] = useState(initialValues);
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedDates, setSelectedDates] = useState([])
  const [showAddModal, setShowAddModal] = useState(-999);
  const [showEditModal, setShowEditModal] = useState(-999);
  const [showDeleteModal, setShowDeleteModal] = useState(-999);
  const [researcherId, setResearcherId] = useState();
  const [response, setResponse] = useState();
  const studyId = useRef();
  const [open, setOpen] = useState(drawer); //false
  const [openStudy, setOpenStudy] = useState(drawerStudy); //false
  const [devicesSize, setDevicesSize] = useState("200px")
  // const [cardWidth, setCardWidth] = useState("100%")
  const [breadcrumbMargin, setBreadcrumbMargin] = useState("275px");
  const storageManager = new StorageManager()
  const isAdmin = storageManager.getAdmin()
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: TableTexts.NAME,
      isSortable: true,
    },
    {
      id: "surname",
      numeric: false,
      disablePadding: false,
      label: TableTexts.SURNAME,
      isSortable: false,
    },
    {
      id: "userrole",
      numeric: false,
      disablePadding: false,
      label: TableTexts.USER_ROLE,
      isSortable: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: TableTexts.EMAIL,
      isSortable: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: TableTexts.ACTIONS,
      isSortable: false,
    },
  ];
  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView()
  }, [])

  /**
   * It takes in an array of objects, an email, and a user id. It then finds the user with the email
   * and the user with the id. It then compares the lowest role level of the user with the email to the
   * lowest role level of the user with the id. If the user with the email has a higher role level, it
   * returns true. If not, it returns undefined
   * @param data - the array of objects that contains the user's information
   * @param email - the email of the user who is trying to delete the user
   * @param userDelete - the user id of the user you want to delete
   * @returns A boolean value
   */
  const higherLevelRoles = async (data, email, userDelete) => {
    const myUser = data.find(user => (user.researcher.user.email || user.researcher.user.username) === email)
    const deleteUser = data.find(user => (user.researcher.user.id) === userDelete)
    try {
      const lowestRole = myUser.roles.reduce((acc, curr) => {
        if (acc.level > curr.level) {
          return curr
        } else {
          return acc
        }
      })

      const lowestDeleteRole = deleteUser.roles.reduce((acc, curr) => {
        if (acc.level > curr.level) {
          return curr
        } else {
          return acc
        }
      })
      if (lowestRole.level > lowestDeleteRole.level) {
        return true
      } else {
        return undefined
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }

  /**
   * It gets the team members of a project and stores them in a variable
   */
  const getTeamMembers = async () => {
    try {
      var responseAPI = await TeamMembersService.getTeamMembers(id.current);
      responseAPI = Object.values(responseAPI.data.data.items);
      var data = [];
      var rolesList = "";
      responseAPI.forEach((res) => {
        res.roles.forEach((role, index) => {
          if (index === 0) {
            rolesList = role.name;
          } else {
            rolesList = rolesList + " / " + role.name;
          }
        });
        /* eslint-disable no-return-assign */
        higherLevelRoles(responseAPI, storageManager.getEmail(), res.researcher.user.id).then(respuesta => res["researcher"]["user"]["higherLevel"] = respuesta)
        res["researcher"]["user"]["userrole"] = rolesList;
        res["researcher"]["user"]["researcher_id"] = res["id"];
        data.push(res["researcher"]["user"]);
      });
      if (data.length === 0) {
        data = [
          {
            id: -999,
            email: "not data",
            name: "not data",
            surname: "not data",
            username: "not data",
          },
        ];
      }
      setResponse(data);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setLoading(false);
  };

  /**
   * It sets the loading state to true, resets the values to the initial values, gets the team members,
   * sets the snackbar to open with the message "Team member added", and closes the modal
   */
  const handleAddParticipant = () => {
    setLoading(true);
    setValues(initialValues);
    getTeamMembers();
    dispatch(toast("Team member added", "success"))
    setShowAddModal(-999);
  };

  /**
   * It sets the loading state to true, resets the form values to the initial values, gets the team
   * members, and opens a snackbar
   */
  const handleAddParticipantContinue = () => {
    setLoading(true);
    setValues(initialValues);
    getTeamMembers();
    dispatch(toast("Team member added", "success"))
  };

  /**
   * It takes the selectedDates array, which is an array of the ids of the team members to be deleted,
   * and loops through it, deleting each team member one by one
   */
  const handleDelete = async () => {
    setLoading(true);
    try {
      if (selectedDates.length > 0) {
        for (let i = 0; i < selectedDates.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          await MyAxiosInstance.delete(
            `${BASE_PATH}/team-member/${selectedDates[i]}`,
            //deleteBody
          );
        }
      } else {
        // eslint-disable-next-line no-await-in-loop
        const deleteResp = await MyAxiosInstance.delete(
          `${BASE_PATH}/team-member/${researcherId}`,
          //deleteBody
        );
      }
      // eslint-disable-next-line no-unused-vars

      dispatch(toast("Team member(s) deleted", "success"))

      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    setShowDeleteModal(-999);
    getTeamMembers();
  };

  /**
   * `handleEditParticipant` is a function that sets the loading state to true, sets the edit values to
   * the initial values, gets the team members, sets the snackbar open to "Team member edited", and sets
   * the show edit modal to -999
   */
  const handleEditParticipant = async () => {
    setLoading(true);
    setEditValues(initialValues);
    getTeamMembers();
    dispatch(toast("Team member edited", "success"))
    // try {
    //   const response = await TeamMembersService.updateTeamMember(id, showEditModal, editValues)
    //   console.log('update data response: ', response)
    //   getTeamMembers()
    // } catch (e) {
    //   console.log('Error: ', e)
    // }
    setShowEditModal(-999);
  };

  /**
   * `getStudy` is an async function that uses the `id` state to make a GET request to the `/study/:id`
   * endpoint
   */
  const getStudy = async () => {
    try {
      const res = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(res.data.data);
    } catch (err) {
      setStudy(err.message);
    }
  };

  /* The above code is using the useEffect hook to set the permissions, id, and studyId state variables
  to the values stored in localStorage. */
  useEffect(() => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;
    studyId.current = newItem.id;

  }, [])

  useEffect(() => {
    getStudy().then(() => {
      getTeamMembers();
    });
  }, []);

  useEffect(() => {
    if (showDeleteModal === true || showDeleteModal.length > 0) {
      setSelectedDates([])
      if (selectedDate == null || selectedDate.length == 0) {
        setResearcherId(
          response.find((x) => x.id === showDeleteModal[0])["researcher_id"]
        );
      } else {
        const selected = []
        for (const element of selectedDate) {
          selected.push(response.find((x) => x.id === element.id)["researcher_id"])
        }
        setSelectedDates(selected)
      }

    }
  }, [showDeleteModal]);

  useEffect(() => {
    if (showEditModal !== -999) {
      setEditValues(response.find((x) => x.id === showEditModal));
    }
  }, [showEditModal]);

  return (
    <>
      {study && (
        <DrawerUhda
          id={studyId.current}
          drawer={drawer}
          drawerStudy={drawerStudy}
          select={2}
          settings={study.permissions}
        />
      )}
      <Grid
        container
        direction={"column"}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item>
          {study && (
            <div style={{ paddingLeft: drawerStudy ? "190px" : "28px" }}>
              <BreadcrumbsUhda
                routes={[
                  { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
                  {
                    id: 1,
                    label: `${study.translations[0].study_title}`,
                    disabled: true,
                    studyId: id.current,
                    permissions: permissions.current
                  },
                  { id: 2, label: LabelsStudy.TEAM_MEMBERS },
                ]}
              />
            </div>
          )}
        </Grid>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {response && (
              <div
                style={{
                  paddingLeft: drawerStudy ? "190px" : "28px",
                  maxWidth: "100%",
                  paddingRight: "24px"
                }}>
                <TableBaseUhda
                  title=""
                  headCells={headCells}
                  dataToUse={response}
                  addModal={AddModal}
                  deleteModal={DeleteModal}
                  editModal={EditModal}
                  initialValues={initialValues}
                  values={values}
                  setValues={setValues}
                  editValues={editValues}
                  setEditValues={setEditValues}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  handleAddParticipant={handleAddParticipant}
                  handleAddParticipantContinue={handleAddParticipantContinue}
                  handleEditParticipant={handleEditParticipant}
                  handleDeleteParticipant={handleDelete}
                  showAddModal={showAddModal}
                  setShowAddModal={setShowAddModal}
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  interactive={true}
                  searchable={true}
                  canAdd={isAdmin == 1 || study.permissions.includes("teammember-create")}
                  canEdit={isAdmin == 1 || study.permissions.includes("teammember-edit")}
                  canDelete={isAdmin == 1 || study.permissions.includes("teammember-delete")}
                  orderByName={"name"}
                  searchValue={"email"}
                  searchText={"Search by name or email"}
                />
              </div>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default TeamMembersPage;
