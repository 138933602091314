import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import GridStudiesUhda from "../components/GridStudiesUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { useMatomo } from "@jonkoops/matomo-tracker-react"

/**
 * @Page
 * Page that shows the grid of studies that a user has
 * @returns {JSX.Element}
 * @constructor
 */
const StudiesPage = () => {
  const [study, setStudy] = useState();
  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView()
  }, [])

  const getStudies = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      const mappedStudies = response.data.data.map(study => {
        return {
          ...study,
          title: study.translations[0]?.study_title,
          tags: study.tags.reduce((tags, currentTag, i) => {
            if (i === 0 && currentTag.name) {
              tags = currentTag.name
              return tags
            }
            tags = `${tags} / ${currentTag.name}`
            return tags
          }, "")
        }
      });
      mappedStudies.reverse()
      setStudy(mappedStudies)

    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getStudies()
  }, []);

  return (
    <Grid container alignItems="center" direction={"column"}>

      {study && <GridStudiesUhda studies={study} />}
    </Grid>

  )
}

export default StudiesPage;
