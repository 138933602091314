import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import QuestionsTable from "./QuestionsTable/QuestionsTable";
// import Sections from "./Sections/Sections";
import Loader from "components/Loader/Loader"
import Options from "./Options/Options";
import CustomDialog from 'components/DialogMonitoring'
import { toast } from "reducers/notificationsReducer";
import { useHistory, useLocation } from "react-router-dom";
// import { includeQuestionsInSummary } from "reducers/sectionsReducer";
// import { NewSectionModal } from "components/Modals/SectionModals/NewSectionModal";
// import { EditOrDeleteSectionModal } from "components/Modals/SectionModals/EditOrDeleteSectionModal";
import SummaryService from 'services/SummaryService'
// import { deleteSectionAction } from "reducers/sectionsReducer";
// import { setSections } from "reducers/sectionsReducer";
import { FormCreateOrEdit } from "./FormCreateOrEdit";
import DrawerUhda from "components/DrawerUhda";
import BreadcrumbsUhda from "components/BreadcrumbsUhda";
import { LabelsDrawer, LabelsStudy } from "locale/en";
import ButtonUnfilledUhda from "components/ButtonUnfilledUhda";
import Icons from "resources/Icons";
import { AppColors } from "resources/AppColors";
import IconProviderUhda from "components/IconProviderUhda/IconProviderUhda";
import { QuestionsForms } from "./QuestionsForms/QuestionsForms";
import { IncludeQuestionsModal } from "components/Modals/QuestionModals/IncludeQuestionsModal";
import { MoveQuestionsModal } from "components/Modals/QuestionModals/MoveQuestionsModal";
import { DeleteQuestionModal } from "components/Modals/QuestionModals/DeleteQuestionModal";
import { deleteQuestionsBatchAction, initializeForm } from "reducers/Form/formActions";
import { initializeLocales } from "reducers/localesReducer";
import { TranslateQuestionForm } from "./QuestionsForms/TranslateQuestionForm/TranslateQuestionForm";

export function FormPage2({ drawer, drawerStudy, isSummaryPage }) {
  const [checkboxSelection, setCheckboxSelection] = useState(false)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)
  const [selectedQuestion, setSelectedQuestion] = useState(null)

  const [questionOpenModal, setQuestionOpenModal] = useState(false)
  const [includeInSummaryOpenModal, setIncludeInSummaryOpenModal] = useState(false)
  // const [showNewSectionModal, setShowNewSectionModal] = useState(false)
  // const [showEditSectionModal, setShowEditSectionModal] = useState(false)
  const [showMoveQuestionsModal, setShowMoveQuestionsModal] = useState(false)
  const [showBulkDeleteQuestionsModal, setShowBulkDeleteQuestionsModal] = useState(false)
  const [showTranslateQuestionModal, setShowTranslateQuestionModal] = useState(false)

  const tableRef = useRef()

  const dispatch = useDispatch()
  const history = useHistory()

  const { data: formData, loading: formLoading, error: formError } = useSelector(({ form }) => form)

  const { data: localesData, loading: localesLoading, error: localesError } = useSelector(({ locales }) => locales)

  const { studyId, formId, permissions, studyPermissions } = useLocation().state

  useEffect(() => {
    if (!localesData && !localesLoading) {
      dispatch(initializeLocales())
    }
  }, [])

  useEffect(() => {
    if (!formData && localesData?.length > 0) {
      dispatch(initializeForm(studyId, formId, isSummaryPage))
    }
  }, [localesData])

  function handleOpenQuestionModal(bool) {
    setQuestionOpenModal(bool)
    if (!bool && !!selectedQuestion) {
      setSelectedQuestion(null)
    }
  }

  function handleSelectQuestion(question) {
    setSelectedQuestion(question)
  }

  function handleDeleteQuestionsBatch() {
    dispatch(deleteQuestionsBatchAction(formData[0]?.id, tableRef.current.selectedItems, isSummaryPage))
    tableRef.current.setSelectedItems([])
    handleShowBulkDeleteQuestionsModal()
  }

  // async function handleCreateSummary() {
  //   try {
  //     const summaryBody = {
  //       internalCode: formData[0]?.title,
  //       // summaryTranslation: [
  //       //   {
  //       //     locale: "en",
  //       //     title: "Summary",
  //       //   },
  //       //   {
  //       //     locale: "es",
  //       //     title: "Summary",
  //       //   }
  //       // ],
  //     };

  //     await SummaryService.post(studyId, formId, summaryBody);

  //     setTimeout(() => {
  //       handleGoSummary()
  //     }, 1000)
  //   } catch (err) {
  //     dispatch(toast("Unable to create summary. Please try again", "error"))
  //   }
  // }

  // function handleDeleteSummary() {
  //   if (formData[0]?.questions.length === 0) {
  //     dispatch(deleteSectionAction(studyId, formId, formData[0]?.id, isSummaryPage))
  //       .then(() => {
  //         // * If the thunk action is successful, navigate to FormPage
  //         dispatch(setSections({ data: undefined, loading: false, error: null })) // ! Temporary fix, need to handle resetting the state in the middleware 
  //         history.push({
  //           pathname: `/study/${studyId}/form/${formId}`,
  //           state: { studyId, formId, value: 0, permissions, studyPermissions }
  //         })
  //       })
  //   }
  //   else {
  //     dispatch(toast("You need to delete all the questions first to be able to delete the summary section", "error"))
  //   }
  // }

  /**
 * It takes the current studyId, formId, and permissions and stores them in a new object. Then it
 * takes the studyId and formId and pushes them to the summary page.
 */
  //   function handleGoSummary() {
  //     dispatch(setSections({ data: undefined, loading: false, error: null })) // ! Temporary fix, need to handle resetting the state in the middleware 

  //     history.push({
  //       pathname: `/study/${studyId}/form/${formId}/summary`,
  //       state: {
  //         studyId,
  //         formId,
  //         value: 0,
  //         permissions,
  //         studyPermissions,
  //       }
  //     })
  //   }

  //   /**
  //  * It takes the study id and form id from the state and pushes the user to the form page
  //  */
  //   function handleGoForm() {
  //     dispatch(setSections({ data: undefined, loading: false, error: null })) // ! Temporary fix, need to handle resetting the state in the middleware 

  //     history.push({
  //       pathname: `/study/${studyId}/form/${formId}`,
  //       state: {
  //         studyId,
  //         formId,
  //         value: 0,
  //         permissions,
  //         studyPermissions,
  //       }
  //     })
  //   }

  function handleShowIncludeInSummary() {
    if (tableRef.current.selectedItems.length > 0) {
      setIncludeInSummaryOpenModal(!includeInSummaryOpenModal)
      return
    }
    dispatch(toast("You need to select at least one question to include it in the summary", "warning"))
  }

  // function handleIncludeQuestions() {
  //   dispatch(includeQuestionsInSummary(tableRef.current.selectedItems, 0))
  //   setIncludeInSummaryOpenModal(false)
  //   tableRef.current.setSelectedItems([])
  // };

  // function handleShowNewSectionModal() {
  //   setShowNewSectionModal(!showNewSectionModal)
  // }

  // function handleShowEditSectionModal() {
  //   setShowEditSectionModal(!showEditSectionModal)
  // }

  function handleShowMoveQuestionsModal() {
    if (tableRef.current.selectedItems.length > 0) {
      setShowMoveQuestionsModal(!showMoveQuestionsModal);
      return
    }
    dispatch(toast("You need to select at least one question to be able to move them", "warning"))
  }

  function handleShowBulkDeleteQuestionsModal() {
    if (tableRef.current.selectedItems.length > 0) {
      setShowBulkDeleteQuestionsModal(!showBulkDeleteQuestionsModal);
      return
    }
    dispatch(toast("You need to select at least one question to be able to delete them", "warning"))
  }

  function handleShowTranslateQuestionModal() {
    setShowTranslateQuestionModal(!showTranslateQuestionModal)
  }

  if (!formData || formLoading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "500px" }}>
        <Loader message="Loading form..." pt={1} />
      </Box>
    )
  }

  return (
    <>
      <DrawerUhda id={studyId} drawer={drawer} drawerStudy={drawerStudy} select={1} settings={studyPermissions} />
      <Box sx={{ paddingLeft: drawerStudy ? "190px" : "28px", paddingRight: "24px", marginTop: drawerStudy ? 0 : "20px", mt: 0 }}>
        <BreadcrumbsUhda
          routes={[
            { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
            {
              id: 1,
              label: formData[0]?.study.title,
              disabled: true,
              studyId: studyId,
              permissions: permissions
            },
            {
              id: 2,
              label: LabelsStudy.FORMS,
              url: `/study/${studyId}/forms`,
              studyId: studyId,
              permissions: permissions
            },
            {
              id: 3,
              label: isSummaryPage ? "Summary" : formData[0]?.title,
              url: "/study",
            },
          ]}
        />
        <Box p={1} >
          {!isSummaryPage &&
            <FormCreateOrEdit />
          }
          {isSummaryPage &&
            <Box>
              <ButtonUnfilledUhda backgroundColor={AppColors.BACKGROUND} onClick={() => handleGoForm()}>
                <IconProviderUhda icon={<Icons.ARROW_LEFT style={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  color: AppColors.ACCENT,
                  fontSize: "2em"
                }} size="100px" />} />
                <Typography
                  style={{
                    fontSize: "20px",
                    color: AppColors.ACCENT,
                  }}>{"Back to the form"}</Typography>
              </ButtonUnfilledUhda>
            </Box>
          }
          <Options
            checkboxSelection={checkboxSelection}
            setCheckboxSelection={setCheckboxSelection}
            handleOpenQuestionModal={handleOpenQuestionModal}
            handleShowBulkDeleteQuestionsModal={handleShowBulkDeleteQuestionsModal}
            summary={formData[0]?.summary}
            // handleCreateSummary={handleCreateSummary}
            // handleGoSummary={handleGoSummary}
            handleIncludeInSummary={handleShowIncludeInSummary}
            // handleShowNewSectionModal={handleShowNewSectionModal}
            handleShowMoveQuestions={handleShowMoveQuestionsModal}
            isSummaryPage={isSummaryPage}
            // handleDeleteSummary={handleDeleteSummary}
            studyPermissions={studyPermissions}
          />
          {/* <Sections
            sections={formData}
            selectedSectionIndex={selectedSectionIndex}
            setSelectedSectionIndex={setSelectedSectionIndex}
            // handleShowEditSectionModal={handleShowEditSectionModal}
            formId={formId}
          > */}
          <QuestionsTable
            ref={tableRef}
            questions={formData[0]?.questions}
            sectionId={formData[0]?.id}
            isCheckboxSelection={checkboxSelection}
            selectedSectionIndex={0}
            handleOpenQuestionModal={handleOpenQuestionModal}
            handleSelectQuestion={handleSelectQuestion}
            isSummaryPage={isSummaryPage}
            formId={formId}
            studyPermissions={studyPermissions}
            handleShowTranslateQuestionModal={handleShowTranslateQuestionModal}
          />
          {/* </Sections> */}
        </Box>
      </Box>
      <CustomDialog
        title={!selectedQuestion ? 'Add question' : "Edit question"}
        show={questionOpenModal}
        handleShow={handleOpenQuestionModal}
        maxWidth={"lg"}
      >
        <Divider />
        <QuestionsForms
          selectedSectionId={formData[0]?.id}
          sections={formData}
          questions={formData[0]?.questions}
          handleShow={handleOpenQuestionModal}
          selectedQuestion={selectedQuestion}
          isSummaryPage={isSummaryPage}
        />
      </CustomDialog>
      <CustomDialog
        title={"Translate Question"}
        show={showTranslateQuestionModal}
        handleShow={handleShowTranslateQuestionModal}
        maxWidth={"lg"}
      >
        <Divider />
        <TranslateQuestionForm
          selectedQuestion={selectedQuestion}
          handleShow={handleShowTranslateQuestionModal}
        />
      </CustomDialog>
      {/* // ! This modals use the deprecated DialogUhda which is always mounted and if not handled like this are buggy when passing data like in the edit modal */}
      {includeInSummaryOpenModal &&
        <IncludeQuestionsModal
          show={includeInSummaryOpenModal}
          setShow={handleShowIncludeInSummary}
          handleIncludeQuestions={handleIncludeQuestions}
        />
      }
      {/* {showNewSectionModal &&
        <NewSectionModal
          show={showNewSectionModal}
          handleShow={handleShowNewSectionModal}
          studyId={studyId}
          formId={formId}
          sectionsLength={formData.length}
        />
      } */}
      {/* {showEditSectionModal &&
        <EditOrDeleteSectionModal
          show={showEditSectionModal}
          handleShow={handleShowEditSectionModal}
          studyId={studyId}
          formId={formId}
          section={formData[selectedSectionIndex]}
          setSelectedSectionIndex={setSelectedSectionIndex}
        />
      } */}
      {showMoveQuestionsModal &&
        <MoveQuestionsModal
          show={showMoveQuestionsModal}
          handleShow={handleShowMoveQuestionsModal}
          initialStudyId={studyId}
          initialFormId={formId}
          initialSections={formData}
          originSection={formData[0]}
          selectedQuestions={tableRef.current.selectedItems}
          setSelectedQuestions={tableRef.current.setSelectedItems}
        />
      }
      {showBulkDeleteQuestionsModal &&
        <DeleteQuestionModal
          isBulkDelete
          show={showBulkDeleteQuestionsModal}
          handleShow={handleShowBulkDeleteQuestionsModal}
          handleDeleteQuestion={handleDeleteQuestionsBatch}
          isSummaryPage={isSummaryPage}
        />
      }
    </>
  )
}
