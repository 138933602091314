import React from "react";
import {
  CommonTexts,
  LabelsForm,
  TableTexts,
  LinksInfo
} from "locale/en";
import { AppColors } from "resources/AppColors";
import PropTypes from "prop-types";
import DialogUhda from "components/DialogUhda";
import { Typography, } from "@mui/material";

export function DeleteQuestionModal({
  handleShow,
  show,
  handleDeleteQuestion,
  isSummaryPage,
  isBulkDelete
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={handleShow}
      handleConfirm={handleDeleteQuestion}
      title={!isBulkDelete ? "Delete question" : "Delete questions"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {!isSummaryPage ? !isBulkDelete ? LabelsForm.DELETE_INFO_SECTIONS : LabelsForm.DELETE_INFO_QUESTIONS_SECTIONS : LabelsForm.DELETE_INFO_SUMMARY}
          </Typography>
          {/* <a style={{ fontSize: "12px", color: AppColors.ACCENT }}
            href={LinksInfo.SUMMARIES}
            target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a> */}
        </>
      }
      show={show}
    />
  );
}

DeleteQuestionModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};