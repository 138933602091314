import { useState } from "react";

function useSelection(data) {
  const [selectedItems, setSelectedItems] = useState([]);

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelectedItems(newSelected);
      return;
    }
    setSelectedItems([]);
  };

  function handleSelectOneClick(event, id) {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1),
      );
    }

    setSelectedItems(newSelected);
  };

  const isSelected = (id) => selectedItems.indexOf(id) !== -1;

  return { selectedItems, setSelectedItems, handleSelectAllClick, handleSelectOneClick, isSelected };
}

export default useSelection