import { Box, Typography, FormControl, } from "@mui/material";
import RichTextEditorLexical from "components/RichTextEditor";

function RightSection({
  helperTextJSON,
  helperTextHTML,
  setHelperTextJSON,
  setHelperTextHTML,
  setHelperTextPlain,
  questionTitleJSON,
  questionTitleHTML,
  setQuestionTitleJSON,
  setQuestionTitleHTML,
  setQuestionTitlePlain
}) {
  return <>
    <Box id="right-section">
      <Typography variant="subtitle_medium">Question title</Typography>
      <FormControl
        // 
        id="question-title"
        data-testId={"questionTitle"}
        margin="normal"
        style={{ width: "100%", marginTop: -1 }}>
        <RichTextEditorLexical
          jsonTextEditor={questionTitleJSON}
          rawHtmlTextEditor={questionTitleHTML}
          setJsonTextEditor={setQuestionTitleJSON}
          setRawHtmlTextEditor={setQuestionTitleHTML}
          setPlainTextEditor={setQuestionTitlePlain}
        />
      </FormControl>
      <Box id="question-helper-text" mt={1}>
        <Typography variant="subtitle_medium">Helper text</Typography>
        <FormControl

          data-testId={"questionHelper"}
          margin="normal"
          style={{ width: "100%", marginTop: -1 }}>
          <RichTextEditorLexical
            jsonTextEditor={helperTextJSON}
            rawHtmlTextEditor={helperTextHTML}
            setJsonTextEditor={setHelperTextJSON}
            setRawHtmlTextEditor={setHelperTextHTML}
            setPlainTextEditor={setHelperTextPlain}
          />
        </FormControl>
      </Box>
    </Box>
  </>;
}

export default RightSection