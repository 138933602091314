import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react"
import { Provider } from "react-redux";
import store from "./store";

const instance = createInstance({
  urlBase: "https://matomo.uhda.health/",
  siteId: 2,
})
ReactDOM.render(
  <MatomoProvider value={instance}>
    <Provider store={store} >
      <App />
    </Provider>
  </MatomoProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
