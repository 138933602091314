import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { $getRoot } from "lexical";
import { $generateHtmlFromNodes } from "@lexical/html";
import SerializeHtmlPlugin from "./plugins/SerializeHtmlPlugin";
import { TextareaNode, TextareaPlugin } from "./plugins/TextBoxPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";

function Placeholder() {
  return <div className="editor-placeholder">Enter some text...</div>;
}

export default function Editor({
  jsonTextEditor,
  setJsonTextEditor,
  rawHtmlTextEditor,
  setRawHtmlTextEditor,
  setPlainTextEditor,
  isEditable = true,
  resetEditorState,
  setResetEditorState
}) {

  const editorConfig = {
    editorState: jsonTextEditor,
    // The editor theme
    theme: ExampleTheme,
    editable: isEditable,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      // TextareaNode
    ]
  };

  // When the editor changes, you can get notified via the
  // OnChangePlugin!
  function onChange(editorState, editor) {
    editor.update(() => {
      const rawHTML = $generateHtmlFromNodes(editor, null)
      const editorStateTextString = editorState.read(() => $getRoot().getTextContent());

      setRawHtmlTextEditor(rawHTML)
      setJsonTextEditor(editorState)
      setPlainTextEditor(editorStateTextString)
    });
  }

  // TODO: TextArea is buggy, the recommended solution is to use a nested editor. But how would this play with the html import and export?
  // TODO: Add markdown func for checkbox []

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={"editor-container"}>
        <ToolbarPlugin appToolbar={"cbm-toolbar"} isEditable={isEditable} />
        <div className={isEditable ? "editor-inner cbm-input" : "editor-inner cbm-input-disabled"}>
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {/* <TreeViewPlugin /> // * DEBUGGER */}
          <AutoFocusPlugin />
          {/* <CodeHighlightPlugin /> */}
          <ListPlugin />
          <CheckListPlugin />
          {/* <TextareaPlugin /> */}
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <OnChangePlugin onChange={onChange} />
          <SerializeHtmlPlugin
            rawHtml={rawHtmlTextEditor}
            setJsonTextEditor={setJsonTextEditor}
            resetEditorState={resetEditorState}
            setResetEditorState={setResetEditorState}
          />
          <ClearEditorPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
}
