import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, CardHeader, Link, MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";
import { useHistory } from "react-router-dom";
import { LabelsStudy, menuOptions } from "../../locale/en";
import { makeStyles } from "@mui/styles";
import RowMenuListActions from "../TableMonitoring/Body/RowMenuListActions/RowMenuListActions";
import { useDispatch } from "react-redux";
import { initializeForm } from "reducers/Form/formActions";

const useStyles = makeStyles({
  link: {
    overflowX: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: `underline ${AppColors.WHITE}`
    }
  }
});

/**
 * @component
 * Component to create the card of the study
 *
 * @param {number} studyId: id of the study
 * @param {string} studyTitle: title of the study
 * @param {string} studyDescription: description of the study
 * @param {function} handleOnEdit: handle to history push to a edit page of the study
 * @param {function} handleOnDuplicate: handle to open the modal to duplicate the study
 * @param {function} handleOnDelete: handle to open the modal to delete the study
 *
 * @constructor
 * <GridCard studyId={'12'} studyTitle={'TITLE'} studyDescription={'DESCRIPTION'} 
 *  handleOnEdit={handleOnEdit} handleOnDuplicate={handleOnDuplicate}
 * handleOnDelete={handleOnDelete} />
 *
*/

export function GridCard({
  studyId,
  studyTitle,
  studyDescription,
  formId,
  formTitle,
  formDescription,
  handleOnEdit,
  handleOnDuplicate,
  handleOnDelete,
  permissions,
  labels,
}) {
  const [permisos, setPermissions] = useState()

  const history = useHistory()
  const classes = useStyles();

  const dispatch = useDispatch()

  useEffect(() => {
    if (!formId) {
      const newObj = { "id": studyId, "permissions": permisos }
      localStorage.setItem("dashBoardSection", JSON.stringify(newObj));

      const newObj2 = { "detail": studyId, "permissions": permisos }
      localStorage.setItem("sectionDetails", JSON.stringify(newObj2));
    }
  }, [])

  useEffect(() => {
    if (!formId) {
      knowPermissions()
    }
  }, [])

  /**
   * It takes the permissions array and checks if it includes any of the permissions listed in the if
   * statements. If it does, it pushes the label and link to the label and link arrays
   */
  const knowPermissions = () => {
    const label = [];
    const link = [];
    if (permissions.includes("access-data")) {
      label.push(LabelsStudy.DASHBOARD)
      link.push(`/study/${studyId}/dashboard`)
    }
    if (permissions.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
      link.push(`/study/${studyId}/forms`)
    }
    if (permissions.includes("participant-create") || permissions.includes("participant-edit") || permissions.includes("participant-delete")) {
      label.push(LabelsStudy.PARTICIPANTS)
      link.push(`/study/${studyId}/participants`)
    }
    if (permissions.includes("access-dissemination")) {
      label.push(LabelsStudy.DISSEMINATION)
      link.push(`/study/${studyId}/dissemination`)
    }
    if (permissions.includes("teammember-create") || permissions.includes("teammember-edit") || permissions.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
      link.push(`/study/${studyId}/teamMembers`)
    }
    if (permissions.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
      link.push(`/study/${studyId}/settings`)
    }
    setPermissions(label)
  };

  /**
   * It sets the route to edit, sets the studyId and permissions in local storage, and then redirects
   * the user to the appropriate page based on their permissions
   */
  const onClickHandlerStudy = () => {
    localStorage.setItem("route", "edit");
    const newObj = { "id": studyId, "permissions": permisos }
    const newObj2 = { "detail": studyId, "permissions": permisos }
    localStorage.setItem("dashBoardSection", JSON.stringify(newObj));
    localStorage.setItem("sectionDetails", JSON.stringify(newObj2));

    if (permissions.includes("access-data")) {
      history.push({
        pathname: `/study/${studyId}/dashboard`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("forms-view")) {
      history.push({
        pathname: `/study/${studyId}/forms`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("participant-create") || permissions.includes("participant-edit") || permissions.includes("participant-delete")) {
      history.push({
        pathname: `/study/${studyId}/participants`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("access-dissemination")) {
      history.push({
        pathname: `/study/${studyId}/dissemination`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("teammember-create") || permissions.includes("teammember-edit") || permissions.includes("teammember-delete")) {
      history.push({
        pathname: `/study/${studyId}/teamMembers`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("study-edit-settings")) {
      history.push({
        pathname: `/study/${studyId}/settings`,
        state: { detail: studyId, permissions: permisos }
      })
    }
  }

  /**
 * It takes the formId and studyId from the props, and stores them in localStorage. Then, it pushes
 * the user to the form page
 */
  const onClickHandlerForm = () => {
    localStorage.setItem("formRoute", "edit")
    const newObj = { "detail": studyId, "formId": formId, "permissions": labels }
    dispatch(initializeForm(studyId, formId))
    localStorage.setItem("formDetails", JSON.stringify(newObj));
    history.push({
      pathname: `/study/${studyId}/form/${formId}`, //TODO cambiar porque es de pruebas para el study
      state: {
        studyId,
        formId,
        value: 6,
        permissions: labels,
        formTitle,
        studyPermissions: permissions,
        studyTitle
      }
    })
  }

  return (
    <Card
      data-testId={!formId ? "studyCard" : "formCard"}
      sx={{
        height: "280px",
        width: "320px",
        position: "relative",
        borderRadius: 2,
        boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
      }}
    >
      <Link underline="none" className={classes.link}>
        <CardHeader
          sx={{ color: AppColors.PRIMARY, paddingTop: 6, textAlign: "center" }}
          onClick={!formId ? onClickHandlerStudy : onClickHandlerForm}
          title={
            <Typography variant="title_bold" sx={{ overflowWrap: "break-word", textDecoration: "none" }}>
              {!formId ? studyTitle : formTitle}
            </Typography>
          }
        />
      </Link>
      <CardContent sx={{ paddingTop: 2, textAlign: "center" }}>
        <Typography variant="body_card" sx={{ overflowWrap: "break-word", textDecoration: "none" }}>
          {!formId ? studyDescription : formDescription}
        </Typography>
      </CardContent>
      <CardActions sx={{ position: "absolute", right: "4px", bottom: "4px" }} >
        <RowMenuListActions>
          {(!formId ? permissions.includes("study-edit-settings") : permissions.includes("form-edit")) &&
            <MenuItem
              data-testId={"editOption"}
              sx={{ color: AppColors.PRIMARY, width: "100%" }}
              onClick={() => { handleOnEdit(!formId ? studyId : formId) }}
            >
              {menuOptions.EDIT}
            </MenuItem>
          }
          {(!formId ? permissions.includes("study-duplicate") : permissions.includes("form-duplicate")) &&
            <MenuItem
              data-testId={"duplicateOption"}
              sx={{ color: AppColors.PRIMARY, width: "100%" }}
              onClick={() => { handleOnDuplicate(!formId ? studyId : formId) }}
            >
              {menuOptions.DUPLICATE}
            </MenuItem>
          }
          {(!formId ? permissions.includes("study-delete") : permissions.includes("form-delete")) &&
            <MenuItem
              data-testId={"deleteOption"}
              sx={{ color: AppColors.PRIMARY, width: "100%" }}
              onClick={() => { handleOnDelete(!formId ? studyId : formId) }}
            >
              {menuOptions.DELETE}
            </MenuItem>
          }
        </RowMenuListActions>
      </CardActions>
    </Card>
  )
}

GridCard.propTypes = {
  studyId: PropTypes.number,
  studyTitle: PropTypes.string,
  handleOnEdit: PropTypes.func,
  handleOnDuplicate: PropTypes.func,
  handleOnDelete: PropTypes.func,
}
