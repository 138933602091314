import React, { useEffect, useState } from "react";
import { CommonTexts } from "locale/en";
import {
  QUESTION_CALCULATION,
  QUESTION_CALENDAR,
  QUESTION_INFO,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_NUMERIC,
  QUESTION_RANGE,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEXT,
  questionTypes,
} from "resources/QuestionConstants";
// import { fileToFormData } from "utils/HelperFunctions";
import { Box, Card, Divider, Typography } from "@mui/material";
import FirstPageAddQuestionForm from "./FirstPage/FirstPageAddQuestionForm";
import { createEmptyStateLexical, findTranslation } from "utils/HelperFunctions";
import { useDispatch } from "react-redux";
import { toast } from "reducers/notificationsReducer";
import LeftSection from "./FirstPage/LeftSection/LeftSection";
import RightSection from "./FirstPage/RightSection/RightSection";
import SecondPageAddQuestionForm from "./SecondPage/SecondPageAddQuestionForm";
import { Button } from "@mui/material";
import { AppColors } from "resources/AppColors";
import { createQuestion, updateQuestionAction } from "reducers/Form/formActions";
import { useSelector } from "react-redux";

export function QuestionsForms({
  selectedSectionId,
  sections,
  questions,
  handleShow,
  selectedQuestion,
  isSummaryPage
}) {
  const form = useSelector(({ form }) => form.data[0])

  const [currentFormPage, setCurrentFormPage] = useState(1)
  const [hasSecondPage, setHasSecondPage] = useState(false)
  // * First page states
  // * LeftSection states
  const [sectionId, setSectionId] = useState(selectedSectionId)
  const [image, setImage] = useState(selectedQuestion ? selectedQuestion.imageURL : null)
  const [variableName, setVariableName] = useState(selectedQuestion ? selectedQuestion.variableName : "")
  const [questionType, setQuestionType] = useState(selectedQuestion ? selectedQuestion.type : "INFO")
  const [selectedPresentationType, setSelectedPresentationType] = useState(selectedQuestion ? selectedQuestion.presentationType : "")
  const [switches, setSwitches] = useState({
    mandatory: selectedQuestion ? selectedQuestion.mandatory : false,
    longText: selectedQuestion ? selectedQuestion.longText : false,
    hasConditional: selectedQuestion ? selectedQuestion.conditional : false,
    summary: selectedQuestion ? selectedQuestion.summary : false,
  })

  // * RightSection states
  const [helperTextJSON, setHelperTextJSON] = useState(createEmptyStateLexical())
  const [helperTextHTML, setHelperTextHTML] = useState(selectedQuestion ? findTranslation(selectedQuestion.questionTranslations, form.defaultLocale.value)?.info : "")
  const [helperTextPlain, setHelperTextPlain] = useState("")
  const [questionTitleJSON, setQuestionTitleJSON] = useState(createEmptyStateLexical())
  const [questionTitleHTML, setQuestionTitleHTML] = useState(selectedQuestion ? findTranslation(selectedQuestion.questionTranslations, form.defaultLocale.value)?.question : "")
  const [questionTitlePlain, setQuestionTitlePlain] = useState("")

  // * SecondPage page states
  const [conditionalOperator, setConditonalOperator] = useState(selectedQuestion ? selectedQuestion.operator : 1)
  // ! The problem with the edit may be the value of selectedQuestion.conditionalQuestions when is null
  const [questionConditionals, setQuestionConditionals] = useState(!!selectedQuestion &&
    !!selectedQuestion.conditionalQuestions
    ? selectedQuestion.conditionalQuestions
    : [{ variable: "", answer: "", type: "" }])
  // TODO: Delete this state when all type of questions can be conditional
  const [possibleConditionalQuestions] = useState(questions.filter(question =>
    question.type === questionTypes[QUESTION_SINGLE_CHOICE].name || question.type === questionTypes[QUESTION_MULTIPLE_CHOICE].name));
  const [choiceQuestions, setChoiceQuestions] = useState(selectedQuestion ? selectedQuestion.choiceQuestions : [{ value: "", translations: [] }])
  const [numericalQuestionsValues, setNumericalQuestionsValues] = useState({
    minValue: selectedQuestion ? selectedQuestion.minValue : "",
    maxValue: selectedQuestion ? selectedQuestion.maxValue : "",
    units: selectedQuestion ? selectedQuestion.units : "",
    interval: selectedQuestion ? selectedQuestion.interval : "",
    calculation: selectedQuestion ? selectedQuestion.calculation : ""
  })

  const dispatch = useDispatch()

  useEffect(() => {
    checkFormHasSecondPage();
  }, [questionType, switches.hasConditional]);

  // TODO: Extract error handling and logic for different actions into their own functions
  function handleClickSubmit(e) {
    e.preventDefault()

    const variableNameExists = sections.some(section => section.questions.some(question => selectedQuestion ?
      question.id !== selectedQuestion.id && question.variableName === variableName :
      question.variableName === variableName))

    if (!questionTitlePlain.trim().length) {
      dispatch(toast("Fill the question title before proceeding.", "error"))
      return
    }

    if (variableNameExists) {
      dispatch(toast("The selected variable name already exists in this form.", "error"))
      return
    }

    let singleOrMultipleChoiceAnswers = []

    if (questionType === questionTypes[QUESTION_RANGE].name) {
      if (parseInt(numericalQuestionsValues.minValue) > parseInt(numericalQuestionsValues.maxValue)) {
        return dispatch(toast("Minimum value can't be higher than maximum value", "error"))
      }
      else if (
        parseInt(numericalQuestionsValues.interval) <= 0 ||
        numericalQuestionsValues.interval > numericalQuestionsValues.maxValue - numericalQuestionsValues.minValue
      ) {
        return dispatch(toast("Make sure the interval value is correct", "error"))
      }
    }
    else if (questionType === questionTypes[QUESTION_NUMERIC].name) {
      if (parseInt(numericalQuestionsValues.minValue) > parseInt(numericalQuestionsValues.maxValue)) {
        return dispatch(toast("Minimum value can't be higher than maximum value", "error"))
      }
    }
    else if (questionType === questionTypes[QUESTION_CALCULATION].name) {
      // TODO: Validation only checks for the first two values, if three or more are added anything is accepted
      if (!validateCalculation(numericalQuestionsValues.calculation)) {
        return dispatch(toast("The formula seems to have a bad format, please review the formula and try again", "error"))
      }
    }

    let conditionalString = ""
    if (hasSecondPage) {
      singleOrMultipleChoiceAnswers = !!choiceQuestions[0]?.value
        ? choiceQuestions.map(answer => {
          return {
            answerTitle: findTranslation(answer?.translations, form.defaultLocale.value)?.text, // * Use default answer translation
            answerValue: answer.value,
            answerTranslations: answer.translations.map(translation => { // * Send all answer translations
              return {
                locale: translation.locale,
                text: translation.text
              }
            })
          }
        })
        : []

      const operator = conditionalOperator ? " AND " : " OR "

      // ! Conditional question snippet
      /**
       * Finds the question and its value from the possibleConditionalQuestions array and passes all selected questions to string together with the operators
       */
      if (switches.hasConditional) {
        if (!questionConditionals.some(question =>
          question.type !== questionTypes[QUESTION_SINGLE_CHOICE].name || question.type !== questionTypes[QUESTION_MULTIPLE_CHOICE].name)) {
          return dispatch(toast("Conditional questions type can only be single choice or multiple choice", "error"))
        }

        conditionalString = questionConditionals
          .map(question => `${question.variable}=${question.answer}`)
          .join(operator);
      }
    }

    const questionBody = {
      order: selectedQuestion ? selectedQuestion.order : questions.length + 1,
      formId: form.id,
      sectionId: isSummaryPage ? null : sectionId,
      summary: isSummaryPage ? true : switches.summary,
      variableName: variableName,
      standarName: "",
      conditional: switches.hasConditional ? conditionalString : "",
      mandatory: switches.mandatory,
      // calculation: questionType === questionTypes[QUESTION_CALCULATION].name ? numericalQuestionsValues.calculation : null,
      type: questionType,
      // widget: selectedPresentationType || 'Default',
      longText: switches.longText,
      minValue:
        questionType === questionTypes[QUESTION_NUMERIC].name ||
          questionType === questionTypes[QUESTION_RANGE].name
          ? numericalQuestionsValues.minValue
          : null,
      maxValue:
        questionType === questionTypes[QUESTION_NUMERIC].name ||
          questionType === questionTypes[QUESTION_RANGE].name
          ? numericalQuestionsValues.maxValue
          : null,
      units:
        questionType === questionTypes[QUESTION_NUMERIC].name
          ? numericalQuestionsValues.units
          : "",
      interval:
        questionType === questionTypes[QUESTION_RANGE].name
          ? numericalQuestionsValues.interval
          : null,
      answerOptions: singleOrMultipleChoiceAnswers,
      questionTranslation: [
        {
          locale: form.defaultLocale.value,
          question: questionTitleHTML,
          subtitle: "",
          info: helperTextPlain.trim().length > 0 ? helperTextHTML : ""
        },
      ],
    };

    if (selectedQuestion) {
      dispatch(updateQuestionAction(selectedQuestion.id, questionBody, image, isSummaryPage))
    }
    else {
      dispatch(createQuestion(questionBody, image, isSummaryPage))
    }

    handleShow(false)
  };

  /**
 * If the question type is info, text, calendar, numeric, range, single choice, multiple choice, or
 * calculation, then set the hasSecondPage state to true. Otherwise, set it to false
 */
  function checkFormHasSecondPage() {
    switch (true) {
      case questionType === questionTypes[QUESTION_INFO].name &&
        switches.hasConditional === true:
        setHasSecondPage(true);
        break;
      case questionType === questionTypes[QUESTION_TEXT].name &&
        switches.hasConditional === true:
        setHasSecondPage(true);
        break;
      case questionType === questionTypes[QUESTION_CALENDAR].name &&
        switches.hasConditional === true:
        setHasSecondPage(true);
        break;
      case questionType === questionTypes[QUESTION_NUMERIC].name:
        setHasSecondPage(true);
        break;
      case questionType === questionTypes[QUESTION_RANGE].name:
        setHasSecondPage(true);
        break;
      case questionType ===
        questionTypes[QUESTION_SINGLE_CHOICE].name ||
        questionType === questionTypes[QUESTION_MULTIPLE_CHOICE].name:
        setHasSecondPage(true);
        break;
      case questionType === questionTypes[QUESTION_CALCULATION].name:
        setHasSecondPage(true);
        break;
      default:
        setHasSecondPage(false);
        break;
    }
  };

  /**
 * It takes a string and returns true if the string is a valid calculation, and false if it is not
 * @param calculation - The calculation to be validated.
 * @returns A boolean value.
 */
  function validateCalculation(calculation) {
    // ! This regex has loads of issues
    // eslint-disable-next-line no-useless-escape
    const re = /([-+]?[\w]*?[\w]+[\s]+[\/\+\-\*])+([\s]+[-+]?[\w]*?[\w]+)/g;
    return re.test(String(calculation).toLowerCase());
  }

  // * First page handlers
  // * LeftSection handlers
  function handleChangeSectionId(e) {
    setSectionId(e.target.value)
  };

  function handleImage(e) {
    if (!e) {
      setImage(null)
      return
    }

    // fileToFormData(e.target.value, (result) => {
    //   setImage(result)
    // })
  }

  function changeMandatory() {
    setSwitches({ ...switches, mandatory: !switches.mandatory });
  };

  // * SecondPage page handlers
  function handleChangeConditionalOperator(e) {
    setConditonalOperator(e.target.value)
  };

  // * Handles the second page numerical, range and calculation values
  function handleNumericalQuestionsValues(e) {
    const { name, value } = e.target;
    setNumericalQuestionsValues({ ...numericalQuestionsValues, [name]: value });
  };

  function handleNextPage() {
    const variableNameExists = sections.some(section => section.questions.some(question => selectedQuestion ?
      question.id !== selectedQuestion.id && question.variableName === variableName :
      question.variableName === variableName))

    if (!variableName) {
      dispatch(toast("Fill the variable name before proceeding.", "error"))
      return
    }

    if (variableNameExists) {
      dispatch(toast("The selected variable name already exists in this form.", "error"))
      return
    }

    if (!questionTitlePlain.trim().length) {
      dispatch(toast("Fill the question title before proceeding.", "error"))
      return
    }

    setCurrentFormPage(2);
  };

  function handlePreviousPage() {
    setCurrentFormPage(1);
  };

  return (
    <>
      <Card
        className="mb-1"
        sx={{
          backgroundColor: AppColors.WHITE,
          color: AppColors.PRIMARY,
          paddingBottom: 3,
        }}
      >
        <Box
          component='form'
          id="form-wrapper"
          onSubmit={handleClickSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: "auto",
            maxWidth: "95%",
            mt: 2
          }}
        >
          {currentFormPage === 2 && hasSecondPage
            ? (
              <SecondPageAddQuestionForm
                variableName={variableName}
                questionType={questionType}
                choiceQuestions={choiceQuestions}
                setChoiceQuestions={setChoiceQuestions}
                hasConditional={switches.hasConditional}
                questionConditionals={questionConditionals}
                setQuestionConditionals={setQuestionConditionals}
                possibleConditionalQuestions={possibleConditionalQuestions}
                formTitle={form.title}
                conditionalOperator={conditionalOperator}
                handleChangeConditionalOperator={handleChangeConditionalOperator}
                numericalQuestionsValues={numericalQuestionsValues}
                handleNumericalQuestionsValues={handleNumericalQuestionsValues}
              />
            )
            : (
              <FirstPageAddQuestionForm>
                <LeftSection
                  formTitle={form.title}
                  questionsLength={questions.length}
                  sectionId={sectionId}
                  handleChangeSectionId={handleChangeSectionId}
                  sections={sections}
                  variableName={variableName}
                  setVariableName={setVariableName}
                  questionType={questionType}
                  setQuestionType={setQuestionType}
                  switches={switches}
                  setSwitches={setSwitches}
                  selectedPresentationType={selectedPresentationType}
                  setSelectedPresentationType={setSelectedPresentationType}
                  changeMandatory={changeMandatory}
                  formWithSummary={form.summary}
                  image={image}
                  handleImage={handleImage}
                  questions={questions}
                  selectedQuestion={selectedQuestion}
                  isSummaryPage={isSummaryPage}
                />
                <RightSection
                  helperTextJSON={helperTextJSON}
                  helperTextHTML={helperTextHTML}
                  setHelperTextJSON={setHelperTextJSON}
                  setHelperTextHTML={setHelperTextHTML}
                  setHelperTextPlain={setHelperTextPlain}
                  questionTitleJSON={questionTitleJSON}
                  questionTitleHTML={questionTitleHTML}
                  setQuestionTitleJSON={setQuestionTitleJSON}
                  setQuestionTitleHTML={setQuestionTitleHTML}
                  setQuestionTitlePlain={setQuestionTitlePlain}
                />
              </FirstPageAddQuestionForm>
            )}
          <Divider />
          <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }} id="form-buttons-container">
            {hasSecondPage &&
              <Button
                onClick={currentFormPage === 1 && hasSecondPage ? handleNextPage : handlePreviousPage}
                variant='outlined'
                sx={{ width: { xs: 100, sm: 125 }, }}
                style={{
                  marginRight: '16px',
                  backgroundColor: currentFormPage === 1 && hasSecondPage ? AppColors.PRIMARY : AppColors.WHITE,
                  color: currentFormPage === 1 && hasSecondPage ? "white" : "black",
                  border: "1px solid black"
                }}
              >
                <Typography variant='button_text'>
                  {currentFormPage === 1 && hasSecondPage ? "Next" : "Back"}
                </Typography>
              </Button>
            }
            {((currentFormPage === 1 && !hasSecondPage) || (currentFormPage === 2 && hasSecondPage)) &&
              <Button
                variant='contained'
                type='submit'
                sx={{ width: { xs: "100px", sm: "125px" }, marginLeft: "8px" }}
                style={{ backgroundColor: AppColors.PRIMARY }}
                data-testId="accept"
              >
                <Typography variant='button_text' sx={{ color: "white" }}>
                  {CommonTexts.SAVE}
                </Typography>
              </Button>
            }
          </Box>
        </Box>
      </Card>
    </>
  )
}
