/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase*/
import React, { useEffect, useState } from "react";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import { patientsMock } from "../mocks/patientsMock"
import {
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types"
import DialogUhda from "../components/DialogUhda/DialogUhda"
import moment from "moment"
import { AppColors } from "../resources/AppColors"
import ErrorIcon from "@mui/icons-material/Error"
import { orderArrayAlphabetical, validateEmail } from "../utils/HelperFunctions"
import { CommonTexts, SnackBarResponses, TableTexts } from "../locale/en";
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import UsersService from "../services/UsersService";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { usersMock } from "../mocks/usersMock";
import TeamMembersService from "../services/TeamMembersService";
import FormSwitchUhda from "../components/FormSwitchUhda";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { StorageManager } from "../utils";
import { useMatomo } from "@jonkoops/matomo-tracker-react"
import { useDispatch } from "react-redux";
import { toast } from "../reducers/notificationsReducer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};
const useStyles = makeStyles({

  button: {
    "&:hover": {
      backgroundColor: "transparent",
    }, "& .MuiTouchRipple-root span": {
      backgroundColor: "transparent",
      opacity: .3,
    },
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.CBM_SAND,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: AppColors.CBM_SAND,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.BLACK,
      backgroundColor: "transparent"

    },
    "&:before": {
      color: AppColors.WHITE,
    },
    "&:after": {
      borderBottomColor: AppColors.WHITE,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.WHITE,
    backgroundColor: AppColors.CBM_SAND_OPACITY,
    borderRadius: 10,
  }
})

function AddModal({
  selectedDate,
  setSelectedDate,
  handleAddParticipant,
  handleAddParticipantContinue,
  initialValues,
  values,
  setValues,
  showAddModal,
  setShowAddModal,
  error
}) {
  const [apiError, setApiError] = useState(error)
  const storageManager = new StorageManager();
  const [showEmailError, setShowEmailError] = useState(false)
  const [showRoleError, setShowRoleError] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const classes = useStyles()
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsByName, setOptionsByName] = useState({});
  const userRoles = JSON.parse(storageManager.getRoles());
  const isAllSelected = options.length > 0 && selectedRoles.length === options.length;

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value, ["username"]: `${values.email}` })
  }

  /**
   * If the user selects the "Super Admin" role, then the "is_superadmin" value is set to 1, otherwise
   * it is set to 0
   * @param event - The event that triggered the change.
   * @returns The value of the selected roles.
   */
  const handleChangeRole = (event) => {
    setShowRoleError(false)
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedRoles(selectedRoles.length === options.length ? [] : options);
      return;
    }
    setSelectedRoles(value);
    if (value.includes("Super Admin")) {
      setValues({ ...values, ["roles"]: value, ["is_superadmin"]: 1 })
    } else {
      setValues({ ...values, ["roles"]: value, ["is_superadmin"]: 0 })
    }
    //setValues({ ...values, ["roles"]: value })

  };

  /**
   * It gets the roles from the server and sets the options for the dropdown menu
   */
  const getRoles = async () => {
    try {
      const responseRoles = await TeamMembersService.getRoles();
      var roleList = [];
      var options = {};
      let roleLevel = 0
      for (const element of userRoles) {
        if (element.level > roleLevel) {
          roleLevel = element.level
        }
      }
      responseRoles.data.forEach((role) => {
        if (role.group == 0 && storageManager.getAdmin() == 1) {
          options = { ...options, [role["name"]]: role.id };
          roleList.push(role.name);
        }
        else {
          if (role.group == 0 && role.level >= roleLevel) {
            options = { ...options, [role["name"]]: role.id };
            roleList.push(role.name);
          }
        }

      });
      orderArrayAlphabetical(roleList)
      setOptionsByName(options);
      setOptions(roleList);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };

  /**
   * If the user has not selected a role, show an error. If the user's password and password
   * confirmation do not match, show an error. If the user's email is not valid, show an error.
   * Otherwise, add the participant
   */
  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(values["email"])
    if (selectedRoles.length == 0) {
      setShowRoleError(true)
    }
    else if (values.password != values.password_confirmation) {
      setShowPasswordError(true)
    }
    else if (!isEmail) {
      setShowEmailError(true)
    } else {
      setApiError(false)
      setShowEmailError(false)
      handleAddParticipant()
    }
  }
  /**
   * If the user has not selected a role, show an error. If the user's password and password
   * confirmation do not match, show an error. If the user's email is not valid, show an error.
   * Otherwise, add the participant to the database
   */
  const handleClickSubmitContinue = async () => {
    const isEmail = await validateEmail(values["email"])
    if (selectedRoles.length == 0) {
      setShowRoleError(true)
    }
    else if (values.password != values.password_confirmation) {
      setShowPasswordError(true)
    }
    else if (!isEmail) {
      setShowEmailError(true)

    } else {
      setShowEmailError(false)
      handleAddParticipantContinue();
    }
  }

  /**
   * It changes the value of is_superadmin from 0 to 1 or vice versa.
   */
  const changeAdmin = () => {
    setValues({ ...values, ["is_superadmin"]: values.is_superadmin = 1 - values.is_superadmin })

  };

  useEffect(() => {
    setValues(initialValues)
    getRoles()
  }, [showAddModal])

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
      handleShow={setShowAddModal}
      handleConfirm={handleClickSubmit}
      handleConfirmAndContinue={handleClickSubmitContinue}
      values={values}
      setValues={setValues}
      title={TableTexts.ADD_USER_TITLE}
      body={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >

          <Grid
            container
            xs={12}
            direction="column"
            justifyContent="flex"
            alignItems="stretch"
          >
            <Grid item >
              <Typography style={{ fontSize: "20px", color: AppColors.BLACK }} variant="subtitle1" gutterBottom>
                {"Information about the user"}
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >

              <FormControl margin='normal' style={{ width: "45%" }}>
                <TextfieldUhda
                  name='name'
                  handleChange={handleInputChange}
                  label={TableTexts.NAME}
                  value={values.name}
                />
              </FormControl>
              <FormControl margin='normal' style={{ width: "45%" }}>
                <TextfieldUhda
                  name='surname'
                  handleChange={handleInputChange}
                  label={TableTexts.SURNAME}
                  value={values.surname}
                />
              </FormControl>
              <FormControl margin='normal' style={{ width: "45%" }}>
                <TextfieldUhda
                  name='email'
                  handleChange={handleInputChange}
                  label={TableTexts.EMAIL}
                  value={values.email}
                  error={showEmailError || apiError}
                  helperText={showEmailError ? TableTexts.EMAIL_ERROR : error}
                  inputProps={{
                    endAdornment: (showEmailError || apiError) && <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl margin='normal' style={{ width: "45%" }}>
                <TextfieldUhda
                  name='password'
                  isPassword="password"
                  handleChange={handleInputChange}
                  label={TableTexts.PASSWORD}
                  value={values.password}
                  error={showPasswordError}
                  helperText={showPasswordError && SnackBarResponses.PASSWORD_NOT_MATCH}
                  inputProps={{
                    endAdornment: showPasswordError && <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl margin='normal' style={{ width: "45%" }}>
                <TextfieldUhda
                  name='password_confirmation'
                  isPassword="password"
                  handleChange={handleInputChange}
                  label={TableTexts.REPEAT_PASSWORD}
                  value={values.password_confirmation}
                  error={showPasswordError}
                  helperText={showPasswordError && SnackBarResponses.PASSWORD_NOT_MATCH}
                  inputProps={{
                    endAdornment: showPasswordError && <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            direction="column"
            justifyContent="flex"
            alignItems="stretch"
          >
            <Grid item >
              <Typography style={{ fontSize: "20px", color: AppColors.BLACK }} variant="subtitle1" gutterBottom>
                {"User role"}
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >

              <FormControl
                className={classes.select}

                margin="normal"
                style={{ width: "100%" }}
                variant="outlined"
              >
                <InputLabel
                  className={classes.select}
                  id="multiple-select-label">Role</InputLabel>
                <Select

                  className={classes.select}
                  label="Role"
                  labelId="multiple-select-label"
                  multiple
                  value={selectedRoles}
                  onChange={handleChangeRole}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}>
                  <MenuItem
                    value="all"
                    classes={{ root: isAllSelected ? classes.selectedAll : "" }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedRoles.length > 0 &&
                          selectedRoles.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedRoles.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {showRoleError && <Typography style={{ fontSize: "14px", color: AppColors.RED }} variant="subtitle1" gutterBottom>Please select a role before saving</Typography>}
              {/* <FormControl
                margin="normal"
                style={{ width: "100%", alignItems: "flex-start" }}
              >
                <FormSwitchUhda
                  label="Super Admin"
                  value={values.is_superadmin}
                  handleChange={changeAdmin}
                />
              </FormControl> */}

            </Grid>
          </Grid>
        </Grid>
      }
      show={showAddModal}
    />
  );
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired
}

function DeleteModal({ setShowDeleteModal, showDeleteModal, selected, handleDeleteParticipant }) {
  useEffect(() => {

  }, [showDeleteModal])
  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModal}
      buttonColor={AppColors.RED}
      handleConfirm={() => {
        handleDeleteParticipant();
      }}
      title={
        showDeleteModal === true ?
          selected.length > 1 ? TableTexts.DELETE_TAGS : TableTexts.DELETE_TAG
          : TableTexts.DELETE_USER
      }
      body={
        <Grid>
          <TextUhda color={AppColors.PRIMARY} type={textType.BODY} text={selected.length > 1
            ? TableTexts.DELETE_USERS_TEXT_MULTIPLE
            : showDeleteModal.length > 1 ? TableTexts.DELETE_USER_TEXT : TableTexts.DELETE_USER_TEXTS} />
          {selected.length > 1 ?
            <List>
              {showDeleteModal.map((user) => (
                <ListItem key={user.id} disablePadding>
                  <ListItemText
                    primary={<TextUhda margin={0} type={textType.BODY} text={user.email}></TextUhda>}
                  />
                </ListItem>
              ))}
            </List>
            : <TextUhda type={textType.BODY} text={showDeleteModal.length > 1 ? showDeleteModal[1].email : showDeleteModal[0].email} />}

          <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
            text={selected.length > 1 ? TableTexts.TEXT_MODAL_DELETE_USERS : TableTexts.TEXT_MODAL_DELETE_USER} />

        </Grid >}
      show={showDeleteModal.length != 0}
    />
  )
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired
}

function EditModal({
  selectedDate,
  setSelectedDate,
  handleEditParticipant,
  setShowEditModal,
  showEditModal,
  editValues,
  setEditValues
}) {
  const storageManager = new StorageManager();
  const [showEmailError, setShowEmailError] = useState(false)
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [options, setOptions] = useState([]);
  const classes = useStyles()
  const [optionsByName, setOptionsByName] = useState({});
  const isAllSelected = options.length > 0 && selectedRoles.length === options.length;
  const userRoles = JSON.parse(storageManager.getRoles());

  const handleInputChange = e => {
    const { name, value } = e.target
    setEditValues({ ...editValues, [name]: value })
  }

  /**
   * This function gets the roles from the database and sets the options for the dropdown menu
   */
  const getRoles = async () => {
    try {
      const responseRoles = await TeamMembersService.getRoles();
      var roleList = [];
      let roleLevel = 0
      for (const element of userRoles) {
        if (element.level > roleLevel) {
          roleLevel = element.level
        }
      }
      responseRoles.data.forEach((role) => {
        if (role.group == 0 && storageManager.getAdmin() == 1) {
          roleList.push(role.name);
        }
        else {
          if (role.group == 0 && role.level >= roleLevel) {
            roleList.push(role.name);
          }
        }
      });
      orderArrayAlphabetical(roleList)
      setOptions(roleList);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  };

  /**
   * If the user selects the "Super Admin" role, then the "is_superadmin" field in the editValues
   * object is set to 1. Otherwise, it's set to 0
   * @param event - The event that triggered the change.
   * @returns The value of the selected roles.
   */
  const handleChangeRole = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedRoles(selectedRoles.length === options.length ? [] : options);
      return;
    }
    setSelectedRoles(value);
    if (value.includes("Super Admin")) {
      setEditValues({ ...editValues, ["roles"]: value, ["is_superadmin"]: 1 })
    } else {
      setEditValues({ ...editValues, ["roles"]: value, ["is_superadmin"]: 0 })
    }

  };

  /**
   * If the email is valid, set the error message to false and call the function to edit the
   * participant. If the email is not valid, set the error message to true
   */
  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(editValues["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleEditParticipant()
    } else {
      setShowEmailError(true)
    }
  }
  /**
   * It sets the value of the is_superadmin key in the values object to the opposite of what it currently
   * is
   */
  const changeAdmin = () => {
    setEditValues({ ...editValues, ["is_superadmin"]: editValues.is_superadmin = 1 - editValues.is_superadmin })
  };

  useEffect(() => {
    getRoles();
    if (editValues["roles_string"] != undefined) {
      const roles = []
      for (let i = 0; i < editValues["roles"].length; i++) {
        roles.push(editValues["roles"][i].name)
      }
      setEditValues({ ...editValues, ["roles"]: roles })

      setSelectedRoles(editValues["roles_string"].split(" / "));
    }
  }, [showEditModal]);

  return (
    <DialogUhda
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={CommonTexts.SAVE}
      handleShow={setShowEditModal}
      handleConfirm={handleClickSubmit}
      values={editValues}
      setValues={setEditValues}
      title={TableTexts.EDIT_TITLE_USER}
      body={<Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >

        <Grid
          container
          xs={12}
          direction="column"
          justifyContent="flex"
          alignItems="stretch"
        >
          <Grid item >
            <Typography style={{ fontSize: "20px", color: AppColors.BLACK }} variant="subtitle1" gutterBottom>
              {"Information about the user"}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >

            <FormControl margin='normal' style={{ width: "45%" }}>
              <TextfieldUhda
                name='name'
                handleChange={handleInputChange}
                label={TableTexts.NAME}
                value={editValues.name}
              />
            </FormControl>
            <FormControl margin='normal' style={{ width: "45%" }}>
              <TextfieldUhda
                name='surname'
                handleChange={handleInputChange}
                label={TableTexts.SURNAME}
                value={editValues.surname}
              />
            </FormControl>
            <FormControl margin='normal' style={{ width: "45%" }}>
              <TextfieldUhda
                name='email'
                handleChange={handleInputChange}
                label={TableTexts.EMAIL}
                value={editValues.email}
                error={showEmailError}
                helperText={showEmailError && TableTexts.EMAIL_ERROR}
                inputProps={{
                  endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                    style={{ color: AppColors.RED }} /></InputAdornment>,
                }}
              />
            </FormControl>
            {/* <FormControl margin='normal' style={{ width: "45%" }}>
              <TextfieldUhda
                isDisabled
                name='surname'
                handleChange={handleInputChange}
                label={TableTexts.USERNAME}
                value={editValues.username}
              />
            </FormControl> */}
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          direction="column"
          justifyContent="flex"
          alignItems="stretch"
        >
          <Grid item >
            <Typography style={{ fontSize: "20px", color: AppColors.BLACK }} variant="subtitle1" gutterBottom>
              {"User role"}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >

            <FormControl
              className={classes.select}

              margin="normal"
              style={{ width: "100%" }}
              variant="outlined"
            >
              <InputLabel
                className={classes.select}
                id="multiple-select-label">Role</InputLabel>
              <Select

                className={classes.select}
                label="Role"
                labelId="multiple-select-label"
                multiple
                value={selectedRoles}
                onChange={handleChangeRole}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}>
                <MenuItem
                  value="all"
                  classes={{ root: isAllSelected ? classes.selectedAll : "" }}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selectedRoles.length > 0 &&
                        selectedRoles.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedRoles.indexOf(option) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl
              margin="normal"
              style={{ width: "100%", alignItems: "flex-start" }}
            >
              <FormSwitchUhda
                label="Super Admin"
                value={editValues.is_superadmin}
                handleChange={changeAdmin}
              />
            </FormControl> */}

          </Grid>
        </Grid>
      </Grid>
      }
      show={showEditModal}
    />
  );
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired
}
/**
 * @Page
 * Page that shows the table of participants that a user has
 * @param match
 * @returns {JSX.Element}
      * @constructor
      */
const UsersPage = () => {
  const storageManager = new StorageManager();
  // eslint-disable-next-line camelcase
  const initialValues = { name: "", surname: "", email: "", roles: [], password: "", password_confirmation: "", change_password_required: 1, is_superadmin: 0 }
  const [values, setValues] = useState(initialValues)
  const [editValues, setEditValues] = useState(initialValues)
  const [selectedDate, setSelectedDate] = useState(null)
  const [showAddModal, setShowAddModal] = useState(-999)
  const [showEditModal, setShowEditModal] = useState(-999)
  const [showDeleteModal, setShowDeleteModal] = useState(-999)
  const [response, setResponse] = useState()
  const [loading, setLoading] = useState();
  const [error, setError] = useState("")
  const classes = useStyles()
  const headCells = [
    { id: "name", numeric: false, disablePadding: false, label: TableTexts.NAME, isSortable: false },
    { id: "surname", numeric: false, disablePadding: false, label: TableTexts.SURNAME, isSortable: true },
    { id: "email", numeric: false, disablePadding: false, label: TableTexts.EMAIL, isSortable: false },
    { id: "roles_string", numeric: false, disablePadding: false, label: TableTexts.ROLES, isSortable: false },
    { id: "actions", numeric: false, disablePadding: true, label: TableTexts.ACTIONS, isSortable: false, }
  ];
  const { trackPageView } = useMatomo()

  const dispatch = useDispatch()

  useEffect(() => {
    trackPageView()
  }, [])
  /**
   * It takes the values from the form, sends them to the backend, and then closes the modal
   */
  const handleAddParticipant = async () => {
    try {
      await MyAxiosInstance.post(
        `${BASE_PATH}/user`,
        values
      );
      dispatch(toast("User added", "success"))

      setShowAddModal(-999)
      getUsers()
      setLoading(true)
    } catch (e) {
      setError("This email is already in use")
    }
  }
  /**
   * It takes the values from the form and sends them to the backend
   */
  const handleAddParticipantContinue = async () => {
    try {
      await MyAxiosInstance.post(
        `${BASE_PATH}/user`,
        values
      );
      dispatch(toast("User added", "success"))
      setLoading(true)
    } catch (e) {
      setError("This email is already in use")
    }
    getUsers()

  }

  /**
   * It takes the values from the edit form, and sends a PUT request to the API with the new values
   */
  const handleEditParticipant = async () => {
    setLoading(true)
    try {
      await MyAxiosInstance.put(
        `${BASE_PATH}/user/${editValues["id"]}`,
        editValues
      );
      dispatch(toast("User edited", "success"))
      setShowEditModal(-999)
    } catch (e) {
      setError(e.response.data.data.messages.locale)
    }
    getUsers()
  }

  /**
   * It deletes the user(s) selected in the table
   */
  const handleDeleteParticipant = async () => {
    setLoading(true)
    try {
      if (selectedDate) {
        for (let i = 0; i < selectedDate.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          await MyAxiosInstance.delete(
            `${BASE_PATH}/user/${selectedDate[i].id}`,
            editValues);
        }
      } else {
        // eslint-disable-next-line no-await-in-loop
        await MyAxiosInstance.delete(
          `${BASE_PATH}/user/${showDeleteModal[0]}`,
          editValues
        );
      }
      dispatch(toast("User(s) deleted", "success"))

      setShowDeleteModal(-999)
      getUsers()
    } catch (e) {
      setError(e.response.data)
    }
  }

  /**
   * It takes in an array of users, the email of the user who is trying to delete another user, and the
   * user who is being deleted. It then returns true if the user who is trying to delete another user
   * has a higher level role than the user who is being deleted
   * @param data - the array of users
   * @param email - The email of the user who is trying to delete the user
   * @param userDelete - The user that is being deleted
   * @returns A boolean value
   */
  const higherLevelRoles = async (data, email, userDelete) => {
    const myUser = data.find(user => (user.email || user.username) === email)
    const deleteUser = data.find(user => user.id === userDelete.id)
    try {
      const lowestRole = myUser.roles.reduce((acc, curr) => {
        if (acc.level > curr.level) {
          return curr
        } else {
          return acc
        }
      })

      const lowestDeleteRole = deleteUser.roles.reduce((acc, curr) => {
        if (acc.level > curr.level) {
          return curr
        } else {
          return acc
        }
      })
      if (lowestRole.level > lowestDeleteRole.level) {
        return true
      } else {
        return undefined
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  }

  /**
   * It gets the users from the API, and then it creates a new array with the data that we need
   */
  const getUsers = async () => {
    try {
      var responseAPI = await UsersService.getUsers()
      responseAPI = Object.values(responseAPI.data.data.values)
      var data = [];
      var rolesList = "";
      responseAPI.forEach((res) => {
        res.roles.forEach((role, index) => {
          if (index === 0) {
            rolesList = role.name;
          } else {
            rolesList = rolesList + " / " + role.name;
          }
          /* eslint-disable no-return-assign */
          higherLevelRoles(responseAPI, storageManager.getEmail(), res).then(respuesta => res["higherLevel"] = respuesta)

          res["roles_string"] = rolesList

        });
        data.push(res);
      });
      setResponse(data)

      // eslint-disable-next-line no-empty
    } catch {

    }
    setLoading(false)

  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div style={{ paddingLeft: "48px", paddingRight: "48px" }}>
            {response && <TableBaseUhda
              tableTitle={TableTexts.USERS_TITLE}
              headCells={headCells}
              dataToUse={response}
              addModal={AddModal}
              deleteModal={DeleteModal}
              editModal={EditModal}
              initialValues={initialValues}
              values={values}
              setValues={setValues}
              editValues={editValues}
              setEditValues={setEditValues}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              handleAddParticipant={handleAddParticipant}
              handleAddParticipantContinue={handleAddParticipantContinue}
              handleEditParticipant={handleEditParticipant}
              handleDeleteParticipant={handleDeleteParticipant}
              showAddModal={showAddModal}
              setShowAddModal={setShowAddModal}
              showEditModal={showEditModal}
              setShowEditModal={setShowEditModal}
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
              interactive={true}
              canAdd={true}
              canEdit={true}
              canDelete={true}
              searchable={true}
              searchValue={"email"}
              orderByName={"surname"}
              error={error}
              searchText={"Search by name or email"}

            />}
          </div>
        </>)}
    </>
  )
}

export default UsersPage;
