import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
  },
  tableCell: {
    height: "auto",
    paddingLeft: 0,
  },
}));

function TableMonitoring({ children, tableHeader, droppable }) {
  const classes = useStyles()

  return (
    <TableContainer>
      <Table
        aria-label="simple table"
        {...droppable?.droppableProps}
        ref={droppable?.innerRef}
        className={classes.table}
      >
        {tableHeader}
        <TableBody
          className={classes.tableCell}
        >
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableMonitoring