import TranslateIcon from '@mui/icons-material/Translate';
import { Box, IconButton, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { AppColors } from 'resources/AppColors';

function TranslateMenuOption({ question, showText, handleShowTranslateModal, handleSelectQuestion }) {
  // const navigate = useNavigate()

  function handleTranslateContent() {
    // navigate(`${entity.id}/translate`, { state: { entity } })
    handleShowTranslateModal()
    handleSelectQuestion(question)
  }

  return (
    <Box onClick={handleTranslateContent} sx={{ width: "100%" }} data-testId='translateOption' >
      <IconButton size="large">
        <TranslateIcon sx={{ fill: AppColors.PRIMARY }} />
        {showText &&
          <Typography variant="menu_list_text" >
            Translate
          </Typography>
        }
      </IconButton>
    </Box>
  );
}

export default TranslateMenuOption