/* eslint-disable react/jsx-key */
import {React, useEffect, useState} from "react"
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx"

import {LabelsStudy} from "../locale/en";
import {Divider, Drawer, List, ListItem, ListItemText} from "@mui/material"
import {AppColors} from "../resources/AppColors"
import {Link, useLocation} from "react-router-dom"
import {useTextStyles} from "../resources/AppTexts";
import { useMatomo } from "@jonkoops/matomo-tracker-react"

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: AppColors.BACKGROUND,
    display: "flex",
  },
  content: {
    backgroundColor: AppColors.BACKGROUND,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  drawerHeader: {

    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {

    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  iconRoot: {
    textAlign: "center"
  },
  imageIcon: {
    height: "100%"
  },

  hide: {
    display: "none",
  },
  drawerOpen: {
    marginTop: "64px",
    marginLeft: "240px",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,

    }),

  },
  drawerClose: {
    marginTop: "64px",
    marginLeft: "70px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20) + 1,
    },
  },

}));

/**
 * @Page
 * Page that shows the specific study page together with all the subpages
 */
const StudyPage = ({drawer, valor}) => {

  const classes = useStyles();
  const [open, setOpen] = useState(drawer);//false
  const [selected, setSelected] = useState(null);
  const location = useLocation();
  const id = location.state.detail
  const drawerLinkList = [`/study/${id}/dashboard`, `/study/${id}/forms`, `/study/${id}/participants`, `/study/${id}/dissemination`, `/study/${id}/teamMembers`, `/study/${id}/settings`]
  const texts = useTextStyles();
  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView()
  }, [])
  useEffect(() => {
    setOpen(drawer)

  }, [drawer])
  return (
    <div className={ classes.root }>
      <Drawer
        anchor="left"
        open={ open }
        variant="permanent"
        className={ clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }) }
        classes={ {
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        } }
      >
        <Divider/>
        <List>
          {[LabelsStudy.DASHBOARD, LabelsStudy.FORMS, LabelsStudy.PARTICIPANTS, LabelsStudy.DISSEMINATION, LabelsStudy.TEAM_MEMBERS, LabelsStudy.SETTINGS].map((text, index) => (
            <Link to={ {pathname: drawerLinkList[index], state: {id: id, drawer: open}} } onClick={ () => {
              setSelected(index);
              window.abierto = false
            } } style={ {color: AppColors.WHITE, textDecoration: "none"} }>
              <ListItem button key={ text } selected={ selected === index } style={ {
                backgroundColor: selected === index && AppColors.PIRMARY_WITH_OPACITY,
                borderLeft: selected === index && "5px solid " + AppColors.PRIMARY,
                paddingLeft: selected === index && "11px"
              } }>
                <ListItemText classes={ {primary: texts.subtitle_bold} } primary={ text }
                  style={ {color: AppColors.PRIMARY} }/>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </div>
  )
}

export default StudyPage;
