import { createSlice } from '@reduxjs/toolkit'

const selectedFormSlice = createSlice({
  name: 'selectedForm',
  initialState: "",
  reducers: {
    setSelectedForm(state, action) {
      return action.payload
    },
  },
})

export const { setSelectedForm } =
  selectedFormSlice.actions

export const selectForm = (selectedFormObj) => {
  return async (dispatch) => {
    dispatch(setSelectedForm(selectedFormObj))
  }
}

export default selectedFormSlice.reducer