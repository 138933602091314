import { Box, CircularProgress, Typography } from "@mui/material"

function Loader({ message }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
        mt: 4
      }}>
      <CircularProgress />
      <Typography paragraph id="text" variant="title" textAlign="center">
        {message}
      </Typography>
    </Box>
  )
}

export default Loader