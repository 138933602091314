/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ButtonGroup, Container, Grid, Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { CommonTexts, LabelsDrawer, LabelsForms, LabelsStudy, SnackBarResponses, TableTexts } from "../../locale/en";
import { textType, useTextStyles } from "../../resources/AppTexts";
import { AppColors } from "../../resources/AppColors";
import DialogUhda from "../DialogUhda";
import TextUhda from "../TextUdha";
import BreadcrumbsUhda from "../BreadcrumbsUhda";
import ButtonFilledUhda from "../ButtonFilledUhda";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH, DELETE_FORM, DUPLICATE_FORM, EXPORT_FORM, FORMS_STUDY } from "../../resources/ApiUrls";
import FormListViewUhda from "../ListViewUhda/FormListViewUhda";
import { StorageManager } from "../../utils";
import CheckBoxFormUhda from "../CheckBoxUhda/CheckBoxFormUhda";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import { GridCard } from "../GridCard/GridCard";
import { SearchBar } from "../SearchBar/SearchBar";
import { useDispatch } from "react-redux";
import { toast } from "../../reducers/notificationsReducer";
import { ButtonToggle } from "components/ButtonToggle/ButtonToggle";
import { initializeForm } from "reducers/Form/formActions";
import { setForm } from "reducers/Form/formReducer";

function DeleteFormsModal({
  setShowDeleteQuestions,
  showDeleteQuestions,
  selected,
  handleDeleteQuestions,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteQuestions}
      handleConfirm={() => {
        handleDeleteQuestions();
      }}
      title={"Delete survey(s)"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {selected.length > 1 ? LabelsForms.TEXT_MODALS_DELETE1 : LabelsForms.TEXT_MODAL_DELETE1}
          </Typography>
          {selected.length > 1 ?
            <List>
              {selected.map((form) => (
                <ListItem key={form.id} disablePadding>
                  <ListItemText
                    primary={<TextUhda margin={0} type={textType.BODY} text={form.form_translations[0].title}></TextUhda>}
                  />
                </ListItem>
              ))}
            </List> :
            <TextUhda type={textType.BODY} text={selected[0].form_translations[0].title} />}
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {selected.length > 1 ? LabelsForms.TEXT_MODALS_DELETE2 : LabelsForms.TEXT_MODAL_DELETE2}
          </Typography>
        </>

      }
      show={showDeleteQuestions}
    />
  );
}

DeleteFormsModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};
/**
 *@component
 * Component to show all the forms of a study + the option to create a new form
 *
 * @param {object} forms: JSON with all forms of one study
 * @param {number} id: value to identify the study
 *
 * @example
 * const forms = {your JSON form data}
 * <GridFormsUhda forms={forms}/>
 */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
  }
}))

const GridFormsUhda = ({ studies, forms, id, permissions, drawer }) => {
  const history = useHistory()
  const texts = useTextStyles();
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState([false, -1]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [openExport, setOpenExport] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [currentTitleForm, setCurrentTitleForm] = useState(null);
  const studyId = id
  const [study, setStudy] = useState();
  const [form, setForms] = useState(forms);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [openSnackDuplicate, setOpenSnackDuplicate] = React.useState(false);
  const [open, setOpen] = useState(drawer);//false
  const [searched, setSearched] = useState();
  const [viewActive, setViewActive] = useState("list");
  const [loading, setLoading] = React.useState(false);
  const storageManager = new StorageManager()
  const isAdmin = storageManager.getAdmin()
  const [stateSelect, setStateSelect] = useState(false)
  const [selectedForms, setSelectedForms] = React.useState();
  const [showDeleteForms, setShowDeleteForms] = React.useState();
  const [openSnackSelected, setOpenSnackSelected] = React.useState(false);
  const [openSnackAdd, setOpenSnackAdd] = React.useState(false);

  const dispatch = useDispatch()

  useEffect(() => {
    setOpen(drawer)
  }, [drawer])

  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(FORMS_STUDY(studyId));

      const mappedForms = response.data.data?.items.map(form => {
        return {
          ...form,
          title: form.form_translations[0]?.title,
          tags: form.tags.reduce((tags, currentTag, i) => {
            if (i === 0 && currentTag.name) {
              tags = currentTag.name
              return tags
            }
            tags = `${tags} / ${currentTag.name}`
            return tags
          }, "")
        }
      });
      mappedForms.reverse()
      setForms(mappedForms)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  const handleOnEdit = (formId) => {
    localStorage.setItem("formRoute", "edit")
    const newObj = { "detail": studyId, "formId": formId, "permissions": permissions }
    localStorage.setItem("formDetails", JSON.stringify(newObj));

    dispatch(initializeForm(studyId, formId))

    const formTitle = forms.find(form => form.id === formId).title

    history.push({
      pathname: `/study/${studyId}/form/${formId}`, //TODO cambiar porque es de pruebas para el study
      state: {
        studyId,
        formId,
        permissions,
        formTitle,
        studyPermissions: studies.permissions,
        studyTitle: studies.translations[0]?.study_title
      }
    })
  }

  const moveToCreateForm = () => {

    localStorage.setItem("formRoute", "create")
    const newObj = { "detail": studyId, "formId": null, "permissions": permissions }
    localStorage.setItem("createFormDetails", JSON.stringify(newObj));

  }
  useEffect(() => {
    moveToCreateForm()
  }, [])


  const handleClosePermissionsDuplicate = () => {
    setOpenSnackDuplicate(false);
  };


  const handleOnDuplicate = form => {
    // TODO: Duplicate form
    if (isAdmin == 1 || studies.permissions.includes("form-duplicate")) {
      setCurrentTitleForm(form.form_translations[0].title)
      setCurrentForm(form)
      setShowDuplicateModal(!showDuplicateModal)
    } else {
      setOpenSnackDuplicate(true)
    }
  }

  const handleOnConfirmDuplicate = async (e) => {
    // TODO: Duplicate form
    try {
      const formId = currentForm.id
      e.preventDefault()
      try {
        setLoading(true)
        await MyAxiosInstance.post(DUPLICATE_FORM(studyId, formId));
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
      setShowDuplicateModal(!showDuplicateModal)
      setOpenDuplicate(true)
      getForms()

      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    setShowDuplicateModal(!showDuplicateModal)
    getForms()
  }
  const handleOnExport = form => {
    // TODO: Duplicate form
    if (isAdmin == 1 || studies.permissions.includes("form-duplicate")) {
      setCurrentTitleForm(form.form_translations[0].title)
      setCurrentForm(form)
      setShowExportModal(!showExportModal)
    } else {
      setOpenSnackDuplicate(true)
    }
  }
  const handleOnConfirmExport = async (e) => {
    // TODO: Duplicate form
    try {
      const formId = currentForm.id
      e.preventDefault()
      try {
        const response = await MyAxiosInstance.get(EXPORT_FORM(studyId, formId));
        const fileToSave = new Blob([JSON.stringify(response.data.data.item)], {
          type: "application/json"
        });
        saveAs(fileToSave, currentTitleForm);

        // eslint-disable-next-line no-empty
      } catch (error) {

      }
      setShowExportModal(!showExportModal)
      setOpenExport(true)
      // getForms()

      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    setShowExportModal(!showExportModal)
    //getForms()
  }

  const handleOnDelete = form => {
    // TODO: Delete form
    if (isAdmin == 1 || studies.permissions.includes("form-delete")) {
      setCurrentTitleForm(form.form_translations[0].title)
      setCurrentForm(form)
      setShowDeleteModal(!showDeleteModal)
    } else {
      dispatch(toast(SnackBarResponses.NOT_PERMISSION, "error"))
    }
  }

  const handleOnConfirmDelete = async (e) => {
    try {
      const formId = currentForm.id
      e.preventDefault()
      try {
        setLoading(true)
        await MyAxiosInstance.delete(DELETE_FORM(formId));
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.error(error)
      }
      // TODO: Delete Study
      setShowDeleteModal(!showDeleteModal)
      dispatch(toast(SnackBarResponses.FORM_DELETED, "success"))
      getForms()

      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    // TODO: Delete Study
    setShowDeleteModal(!showDeleteModal)
    getForms()

  }

  const handleDeleteSelect = () => {
    if (isAdmin == 1 || study.permissions.includes("create-survey-folder")) {
      if (selectedForms !== undefined && selectedForms.length !== 0) {
        setShowDeleteForms(true)
      } else {
        setOpenSnackSelected(true)
      }
    } else {
      setOpenSnackAdd(true);
    }
  }

  const handleDeleteForms = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < selectedForms.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const response = await MyAxiosInstance.delete(DELETE_FORM(selectedForms[i].id));

      }
      getForms();
      dispatch(toast(SnackBarResponses.FORM_DELETED, "success"))
      setStateSelect(false)
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    setShowDeleteForms(false);
  };


  const headCells = [
    { id: "", label: "" },
    { id: "title", label: TableTexts.FORM_TITLE, isSortable: true },
    { id: "formDescription", label: TableTexts.FORM_DESCRIPTION, isSortable: false },
    { id: "tags", label: TableTexts.TAGS, isSortable: true },
    { id: "created_by", label: TableTexts.STUDY_CREATED_BY, isSortable: true },
    { id: "created_at", label: TableTexts.STUDY_CREATED, isSortable: true },
  ];

  return (
    <Container fluid style={{ maxWidth: "100%", padding: 0, paddingRight: "24px" }}>
      <div className={classes.root}>

        {studies && <Grid container item
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
        >
          {studies && <div>
            <BreadcrumbsUhda routes={
              [
                { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
                {
                  id: 1,
                  label: `${studies.translations[0].study_title}`,
                  disabled: true,
                  studyId: id,
                  permissions: permissions

                },
                { id: 2, label: LabelsStudy.FORMS },
              ]
            } /></div>}

        </Grid>}

        <Grid container justifyContent="space-between" item style={{ marginBottom: viewActive == "card" ? "2.2em" : "0em" }}>
          <Grid item xs={6}>
            <ButtonGroup color="primary">
              <ButtonToggle key={"list"} active={viewActive === "list"}
                onClick={() => (setViewActive("list"))}>
                <IconProviderUhda icon={<Icons.LIST_ICON color={AppColors.PRIMARY} size="2rem" />} />
              </ButtonToggle>
              <ButtonToggle data-testId={"cardViewButton"} key={"card"} active={viewActive === "card"}
                onClick={() => (setViewActive("card"))}>
                <IconProviderUhda icon={<Icons.CARD_VIEW color={AppColors.PRIMARY} size="2rem" />} />
              </ButtonToggle>
            </ButtonGroup>
          </Grid>
          {(studies && (studies.permissions.includes("form-create") || isAdmin == 1) && stateSelect && viewActive == "list") && <Grid item style={{ paddingRight: "2em" }}>
            <ButtonFilledUhda margin={"10px"} type={textType.SUBTITLE_BOLD}
              onClick={handleDeleteSelect}
              text={CommonTexts.DELETE} />
          </Grid>}
          {(studies && (studies.permissions.includes("form-create") || isAdmin == 1) && viewActive == "list") && <Grid item style={{ paddingRight: "2em" }}>
            <ButtonFilledUhda margin={"10px"} type={textType.SUBTITLE_BOLD} backGroundColor={AppColors.GREY}
              onClick={() => setStateSelect(!stateSelect)}
              text={stateSelect ? CommonTexts.CANCEL : CommonTexts.SELECT} />
          </Grid>}
          <Grid data-testId={"newForm"} item >
            {studies && permissions && (isAdmin || studies.permissions.includes("form-create")) &&
              <ButtonFilledUhda margin={"0.7em"} marginLeft={"0.5em"}
                onClick={() => {
                  dispatch(setForm({ data: undefined, loading: false, error: null })) // ! Reset form state

                  return history.push({
                    pathname: `/study/${studyId}/form`,
                    state: {
                      studyId,
                      formId: null,
                      value: 6,
                      permissions: permissions,
                      studyPermissions: studies.permissions,
                      studyTitle: studies.translations[0]?.study_title
                    }
                  });
                }}
                options={isAdmin && viewActive == "card"}
                getForms={getForms}
                studyId={studyId}
                icon={<IconProviderUhda
                  icon={<Icons.ADD size={"1.5em"}
                    color={AppColors.WHITE} />} />}
                text={CommonTexts.NEW_FORM} />
            }
          </Grid>
        </Grid>
        {viewActive == "card" && <Grid item>
          <SearchBar
            entities={forms}
            setFilteredValues={setForms}
            placeholder={"Search by title"}
            searchKey={"title"}
          />
        </Grid>}
        <br />

        <Grid
          align='center'
          container item
          // spacing={2}
          direction="row"
          alignItems={"center"}
          justifyContent="flex-start"
        //style={{ marginLeft: cardMargin }}

        >

          {forms.length === 0 && (
            <div style={{ marginTop: "25vh", marginBottom: "15vh", width: "100%" }}>
              <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                text={CommonTexts.CREATE_FORM_INFO} align='center' />

            </div>
          )}

          {showDuplicateModal && (
            <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
              body={
                <>
                  <Grid>
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsForms.TEXT_MODAL_DUPLICATE1}`} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                      text={`${currentTitleForm}`} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsForms.TEXT_MODAL_DUPLICATE2}`} />

                  </Grid>
                </>}
              buttonColor={AppColors.PRIMARY}
              title={LabelsForms.TITLE_MODAL_DUPLICATE}
              show={showDuplicateModal}
              handleShow={setShowDuplicateModal}
              handleConfirm={handleOnConfirmDuplicate}
              textConfirmButton={LabelsForms.CONFIRM_MODAL_DUPLICATE}
              disabled={loading} />
          )}

          {showExportModal && (
            <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsForms.TEXT_MODAL_EXPORT1}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleForm}`} />

                </Grid>}
              buttonColor={AppColors.PRIMARY}
              title={LabelsForms.TITLE_MODAL_EXPORT}
              show={showExportModal}
              handleShow={setShowExportModal}
              handleConfirm={handleOnConfirmExport}
              textConfirmButton={LabelsForms.CONFIRM_MODAL_EXPORT} />
          )}
          {showDeleteModal && (
            <DialogUhda textCancelButton={LabelsForms.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsForms.TEXT_MODAL_DELETE1}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleForm}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsForms.TEXT_MODAL_DELETE2}`} />

                </Grid>}
              buttonColor={AppColors.RED}
              title={LabelsForms.TITLE_MODAL_DELETE}
              show={showDeleteModal}
              handleShow={setShowDeleteModal}
              handleConfirm={handleOnConfirmDelete}
              textConfirmButton={LabelsForms.CONFIRM_MODAL_DELETE} />
          )}
          {viewActive == "card" ?
            studies && form.map(elem => (
              <Box p={2}>
                <GridCard
                  key={form.indexOf(elem)}
                  studyId={studyId}
                  formId={elem.id}
                  formTitle={elem.form_translations[0].title}
                  formDescription={elem.form_translations[0].description}
                  handleOnEdit={handleOnEdit}
                  handleOnDuplicate={() => {
                    handleOnDuplicate(elem);
                  }}
                  handleOnDelete={() => {
                    handleOnDelete(elem);
                  }}
                  permissions={studies.permissions}
                />
              </Box>

            )) :
            <Box style={{ width: "100%" }}>
              {!loading && form.length > 0 && studies && !stateSelect &&
                <FormListViewUhda handleOnEdit={(id) => handleOnEdit(id)}
                  studyId={studyId}
                  studyTitle={studies.translations[0]?.study_title}
                  handleOnDelete={(id) => handleOnDelete(id)}
                  handleOnDuplicate={(id) => handleOnDuplicate(id)}
                  setLoading={setLoading}
                  loading={loading}
                  headCells={headCells}
                  userData={form}
                  permissions={studies.permissions}
                  labels={permissions}

                />}
              {!loading && form && stateSelect && <CheckBoxFormUhda
                showDeleteStudies={showDeleteForms}
                setLoading={setLoading}
                loading={loading}
                headCells={headCells}
                userData={form} selectedStudies={selectedForms}
                setSelectedStudies={setSelectedForms}
              />}

            </Box>
          }
          {
            showDeleteForms === true && (
              <DeleteFormsModal
                setShowDeleteQuestions={setShowDeleteForms}
                showDeleteQuestions={showDeleteForms}
                selected={selectedForms}
                handleDeleteQuestions={handleDeleteForms}
              />
            )
          }
        </Grid>
      </div>

    </Container>
  );
}

export default GridFormsUhda;
