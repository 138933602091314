import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import DialogUhda from "components/DialogUhda";
import { CommonTexts, LabelsForm, TableTexts } from "locale/en";
import { AppColors } from "resources/AppColors";

export function DuplicateQuestionModal({
  show,
  setShow,
  handleDuplicateQuestion,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.PRIMARY}
      textCancelButton={CommonTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShow}
      handleConfirm={() => {
        handleDuplicateQuestion();
      }}
      title={"Duplicate question"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.DUPLICATE__INFO_QUESTION}
          </Typography>

        </>
      }
      show={show}
    />
  );
}

DuplicateQuestionModal.propTypes = {
  setShow: PropTypes.func.isRequired,
};