import { createTheme } from '@mui/material/styles';
import { AppTextsFontSize, AppTextsFontWeight } from '../resources/AppTexts';
import { AppColors } from '../resources/AppColors';

// TODO: WIP Migrate all text to this implementation
// TODO: Use different createTheme functions and merge them before exporting
const customTheme = createTheme({
  typography: {
    number: {
      fontSize: AppTextsFontSize.SIZE_NUMBER,
      fontWeight: AppTextsFontWeight.WEIGHT_BOLD,
    },
    title: {
      fontSize: AppTextsFontSize.SIZE_TITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_BOLD,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY
    },
    title_medium: {
      fontSize: AppTextsFontSize.SIZE_TITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_MEDIUM,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY
    },
    title_bold: {
      fontSize: AppTextsFontSize.SIZE_TITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_BOLD,
      fontFamily: "Comfortaa",
    },
    subtitle: {
      fontSize: AppTextsFontSize.SIZE_SUBTITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_LIGHT,
      fontFamily: "Comfortaa",
    },
    subtitle_medium: {
      fontSize: AppTextsFontSize.SIZE_SUBTITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_MEDIUM,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY,
    },
    subtitle_bold: {
      fontSize: AppTextsFontSize.SIZE_SUBTITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_BOLD,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY,
    },
    subtitle_error: {
      fontSize: AppTextsFontSize.SIZE_SUBTITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_LIGHT,
      fontFamily: "Comfortaa",
      color: AppColors.RED,
    },
    body: {
      fontSize: AppTextsFontSize.SIZE_BODY,
      fontWeight: AppTextsFontWeight.WEIGHT_LIGHT,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY,
    },
    body_card: {
      fontSize: AppTextsFontSize.SIZE_BODY,
      fontWeight: AppTextsFontWeight.WEIGHT_LIGHT,
      color: AppColors.PRIMARY,
    },
    body_drawer: {
      fontSize: AppTextsFontSize.SIZE_SUBTITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_BOLD,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY,
    },
    body_breadcrumbs: {
      fontSize: AppTextsFontSize.SIZE_SUBTITLE,
      fontWeight: AppTextsFontWeight.WEIGHT_LIGHT,
      fontFamily: "Comfortaa",
    },
    body_medium: {
      fontSize: AppTextsFontSize.SIZE_BODY,
      fontWeight: AppTextsFontWeight.WEIGHT_MEDIUM,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY,
    },
    body_bold: {
      fontSize: AppTextsFontSize.SIZE_BODY,
      fontWeight: AppTextsFontWeight.WEIGHT_BOLD,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY,
    },
    body_subtext: {
      fontSize: AppTextsFontSize.SIZE_SUBTEXT,
    },
    button_text: {
      fontSize: AppTextsFontSize.SIZE_BODY,
      fontFamily: "Comfortaa",
    },
    menu_list_text: {
      fontSize: AppTextsFontSize.SIZE_BODY,
      fontWeight: AppTextsFontWeight.WEIGHT_BOLD,
      fontFamily: "Comfortaa",
      color: AppColors.PRIMARY,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: "1px solid #C6D2E3",
          width: 62,
          height: 62,
          backgroundColor: AppColors.BOX_FILL,
          alignSelf: "center"
        },
        img: {
          width: 25,
          height: 25,
        }
      }
    },
  }
});

export default customTheme