import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { AppColors } from 'resources/AppColors';
import { menuOptions } from "locale/en";

function EditMenuOption({ showText, question, handleOpenQuestionModal, handleSelectQuestion }) {
  // const navigate = useNavigate()

  function handleEditForm() {
    // navigate(`${entity.id}/edit`, { state: { selectedEntity: entity } })
    handleOpenQuestionModal(true)
    handleSelectQuestion(question)
  }

  return (
    <Box onClick={handleEditForm} sx={{ width: "100%" }} data-testId='editOption' >
      <IconButton data-cy="table-edit-button" size="large">
        <EditIcon sx={{ fill: AppColors.PRIMARY }} />
        {showText &&
          <Typography variant="menu_list_text" >
            {menuOptions.EDIT}
          </Typography>
        }
      </IconButton>
    </Box>
  );
}

export default EditMenuOption