import { TableCell, Typography } from "@mui/material"
import parse from "html-react-parser"
import { questionTypesByName } from "resources/QuestionConstants"
import { MandatorySwitch } from "./MandatorySwitch"
import { makeStyles } from "@mui/styles";
import { AppColors } from "resources/AppColors";
import { useSelector } from "react-redux";
import { findTranslation } from "utils/HelperFunctions";

const useStyles = makeStyles(() => ({
  tableCellDraggable: {
    padding: 0,
    paddingRight: 16,
    wordBreak: "break-word",
    borderRadius: `2px solid ${AppColors.CBM_SAND}`,
    borderTop: `2px solid ${AppColors.CBM_SAND}`,
    borderBottom: `2px solid ${AppColors.CBM_SAND}`,
    backgroundColor: AppColors.WHITE,
  },
}));

function QuestionsTableRows({ question, selectedSectionIndex, isSummaryPage }) {
  const classes = useStyles()

  const form = useSelector(({ form }) => form.data[0])

  const selectedQuestionTitle = question.questionTranslations.find(translation => translation.locale === form.selectedLocale)?.question

  const formattedQuestionTranslations = question.questionTranslations.filter(translation => translation.locale !== form.defaultLocale.value)
    .map(translation => translation.locale).join(", ")

  return (
    <>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
      >
        <Typography variant='body' sx={{ color: "black" }}>{question.order}</Typography>
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
        sx={{ minWidth: "75px" }}
      >
        <Typography variant='body' sx={{ color: "black" }}>{question.variableName}</Typography>
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
      >
        <Typography variant='body' sx={{ color: "black" }}>{questionTypesByName[question.type]?.label}</Typography>
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
        sx={{ minWidth: "150px" }}
      >
        {selectedQuestionTitle
          ? <Typography variant='body' sx={{ color: "black" }}>{parse(selectedQuestionTitle)}</Typography>
          : <Typography variant='body' sx={{ color: AppColors.GREY, fontStyle: "italic" }}>{parse(findTranslation(question.questionTranslations, form.defaultLocale.value)?.question)}</Typography>
        }
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
      >
        <MandatorySwitch
          isMandatory={question.mandatory}
          questionId={question.id}
          questionType={question.type}
          selectedSectionIndex={selectedSectionIndex}
          isSummaryPage={isSummaryPage}
        />
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
      >
        <Typography variant='body' sx={{ color: "black" }}>{question.conditional ? 'Yes' : 'No'}</Typography>
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
      >
        <Typography variant='body' sx={{ color: "black" }}>{form.defaultLocale.value}</Typography>
      </TableCell>
      <TableCell
        align="left"
        className={classes.tableCellDraggable}
      >
        <Typography variant='body' sx={{ color: "black" }}>{formattedQuestionTranslations}</Typography>
      </TableCell>
      {/* {!isSummaryPage &&
        <TableCell
          align="left"
          className={classes.tableCellDraggable}
        >
          <Typography variant='body'>{question.summary ? 'Yes' : 'No'}</Typography>
        </TableCell>
      } */}
    </>
  )
}

export default QuestionsTableRows