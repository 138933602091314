import React from "react";
import { Card } from "@mui/material";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";

/**
 * Component to create custom cards.
 *
 * @component
 *
 * @param {object} props.children: content of the card
 * @param {string} props.bg: color of the card
 * @param {object} props.style: style of the card
 *
 * @example
 * const children = <Card.Body> ... </Card.Body>;
 * const bg = 'light';
 * const style = {height: '18rem', width: '18rem'};
 *
 * <CardUhda bg={bg} style={style}> {children} </CardUhda>
 */
//TODO maybe is interensting pass by params xs, sm, md... this way can customize cards and use for studies, forms...
const CardUhda = props => {
  const { children, bg, height, width } = props;
  return (
    <Card
      className="w-100 mb-3"
      data-testId={"card-uhda"}
      style={{
        boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)",
        borderRadius: 10,
        height: height,
        width: width,
        backgroundColor: bg,
        color: AppColors.PRIMARY,
      }
      }>
      {children}
    </Card>
  )
}

CardUhda.propTypes = {
  children: PropTypes.array.isRequired,
  bg: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

CardUhda.defaultProps = {
  bg: AppColors.WHITE
};

export default CardUhda;
